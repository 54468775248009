/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/prop-types */

import { Form, Input, Radio, Space } from "antd";
import { setFile, setFileName, setFilesLoader, setVideo, setVideoLoader } from "Store/addUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";

import TextArea from "antd/es/input/TextArea";
import Trash from "../../assets/svg/trash";
import { UploadButton } from "../UploadButton/UploadButton";
import axios from "axios";
import { backendUrl } from "Api/api";
import { domain } from "../../api/api";
import plusSvg from "Assets/svg/plus.svg";
import s from "./AddUser.module.less";
import style from "../../pages/FS8-changeCompanyProfile/ChangeCompanyProfile.module.less";
import { useTranslation } from "react-i18next";
import { useSelectCropImage } from "../../hooks/useSelectCropImage";
import AddAndCropLogo from "../AddAndCropLogo/AddAndCropLogo";

const AdditionalStuff = ({ form, filesArray, setFilesArray }) => {
  const { t } = useTranslation(["FA2.1-AddUser", "FS8-changeCompanyProfile"]);
  const [value, setValue] = useState("youtubeLink");
  const filePicker = useRef(null);
  const [fileNameImage, setFileNameImage] = useState("");
  const [fileImage, setFileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const { video, files, filesLoader, fileName, videoLoader } = useSelector(
    (state) => state.addUser
  );
  const dispatch = useDispatch();

  const { visibleImage, setVisibleImage, img, setImg, onSelectFile } = useSelectCropImage();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handlePick = () => {
    filePicker.current.click();
  };

  const filesHandler = (payload) => {
    dispatch(setFile([{ filename: payload.filename, description: payload.description }]));
  };

  const handleDeleteFile = (filename) => {
    setFilesArray(filesArray.filter((image) => filename !== image));
    filePicker.current.value = '';
  };

  const handleVideoSelect = async (e) => {
    if (!video) {
      dispatch(setVideo(e.target.files[0].name));
      dispatch(setVideoLoader(t("loading", { ns: "FS8-changeCompanyProfile" })));
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("originalname", e.target.files[0].name);
      try {
        const res = await axios({
          method: "post",
          url: `${domain}api/upload/video`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }
        });
        if (res.status === 200) {
          dispatch(setVideo(res.data.filename));
          dispatch(setVideoLoader(t("loaded", { ns: "FS8-changeCompanyProfile" })));
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(setVideo(""));
          dispatch(setVideoLoader(""));
        }
      }
    }
  };

  const onAddFile = (e, type) => {
    if (type === "image") {
      setFileNameImage(e.name);
      setLoading(true);
    } else {
      dispatch(setFilesLoader(t("loading", { ns: "FS8-changeCompanyProfile" })));
      dispatch(setFileName(e.target.files[0].name));
    }
    const formData = new FormData();
    formData.append("file", type !== "image" ? e.target.files[0] : e);
    formData.append("originalname", type !== "image" ? e.target.files[0].name : e.name);
    axios({
      method: "post",
      url: `${backendUrl}/upload/file`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    }).then((res) => {
      if (res.status === 200) {
        if (type === "image") {
          setFileImage(res.data?.filename);
          setFilesArray([...filesArray, res.data?.filename]);
          setLoading(false);
        } else {
          dispatch(setFilesLoader(t("loaded", { ns: "FS8-changeCompanyProfile" })));
          filesHandler({ filename: res.data?.filename });
        }
      }
    });
  };

  const handleChangeCrop = () => {};

  const inptRef = useRef(null);
  const fileRef = useRef(null);
  return (
    <section>
      <h2 className={s.title}>{t("additionalStuff")}</h2>
      <div className={s.additionalStuffContainer}>
        <div className={s.additionalStuffUploadContainer}>
          <div className={s.stuffContainer}>
            <h3 className={s.subtitle} style={{ fontSize: 18, marginBottom: 10 }}>
              {t("video")}
            </h3>
            <p style={{ fontWeight: 300, marginBottom: 14 }}>{t("videoDesc")}</p>
            <Radio.Group onChange={onChange} value={value} style={{ marginBottom: 15 }}>
              <Space direction="vertical">
                <Radio value="youtubeLink">{t("youtubeLink")}</Radio>
                <Radio value="upload">{t("upload")}</Radio>
              </Space>
            </Radio.Group>

            {value === "youtubeLink" && (
              <Form.Item
                name={["company", "video"]}
                rules={[
                  {
                    pattern: new RegExp(
                      // eslint-disable-next-line no-useless-escape
                      /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
                    ),
                    message: t("errors.youtube")
                  }
                ]}
              >
                <Input
                  placeholder={t("youtubeLink")}
                  size="large"
                  style={{ width: 290, backgroundColor: "#F5F7F8" }}
                />
              </Form.Item>
            )}

            {value === "upload" && (
              <div>
                <div style={{ display: "flex" }}>
                  <label
                    htmlFor="video"
                    className="mt-12 btn btn_v5"
                    style={{ width: "max-content", cursor: "pointer" }}
                  >
                    <img src={plusSvg} className="mr-20" />
                    {video
                      ? video.slice(0, 12) + "..." + video.slice(video.length - 4)
                      : t("videoAdd", { ns: "FS8-changeCompanyProfile" })}
                  </label>
                  <div className={style.trash}>
                    {video && (
                      <Trash
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(setVideo(""));
                          dispatch(setVideoLoader(""));
                          inptRef.current.value = "";
                        }}
                      />
                    )}
                  </div>
                </div>
                <input
                  accept=".mp4"
                  id="video"
                  type="file"
                  className="d-none"
                  ref={inptRef}
                  onChange={handleVideoSelect}
                />
                <div
                  style={
                    videoLoader === t("loading", { ns: "FS8-changeCompanyProfile" })
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  {videoLoader}
                </div>
              </div>
            )}
          </div>

          <div className={s.additionalStuffUploadButtonContainer}>
            <h5> {t("images", { ns: "FS8-changeCompanyProfile" })}</h5>
            <ul className={s.additionalStuffAcceptFilesList}>
              {filesArray.map((image) => (
                <li style={{ position: "relative" }} key={image}>
                  <UploadButton text={image} image={image} />
                  <div
                    style={{ position: "absolute", top: "10px", right: "20px" }}
                    onClick={() => handleDeleteFile(image)}
                  >
                    <Trash />
                  </div>
                  {image && (
                    <span
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "20px",
                        color: "green"
                      }}
                    >
                      {t("loaded", { ns: "FS8-changeCompanyProfile" })}
                    </span>
                  )}
                </li>
              ))}
            </ul>
            {filesArray.length >= 4 ? (
              ""
            ) : (
              <div style={{ position: "relative" }} className={s.additionalStuffUploadButton}>
                <UploadButton
                  onClick={handlePick}
                  title={t("images", { ns: "FS8-changeCompanyProfile" })}
                  text={t("upload", { ns: "FS8-changeCompanyProfile" })}
                  uploadInfo={t("format", { ns: "FS8-changeCompanyProfile" })}
                />
                <div className={s.additionalStuffRecommendedImageSize}>
                  <div>{t("recommendedSize")}</div>
                  <div>{t("tipImages", { ns: "FS8-changeCompanyProfile" })}</div>
                </div>
                <input
                  ref={filePicker}
                  className={s.file}
                  type="file"
                  onChange={onSelectFile}
                  accept=".png, .jpg, .jpeg"
                />
                {loading && (
                  <span
                    style={{ color: "red", position: "absolute", bottom: "10px", right: "20px" }}
                  >
                    {t("loading", { ns: "FS8-changeCompanyProfile" })}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={s.stuffContainer}>
          <h5>{t("additionalFiles", { ns: "FS8-changeCompanyProfile" })}</h5>
          <p className="fw-300 mb-12">
            {t("additionalDescription", { ns: "FS8-changeCompanyProfile" })}
          </p>
          <div>
            <div style={{ display: "flex" }}>
              <label
                htmlFor={`fileInput`}
                className="mt-12 btn btn_v5"
                style={{ width: "max-content", cursor: "pointer" }}
              >
                <img src={plusSvg} className="mr-20" />
                {fileName
                  ? fileName.slice(0, 12) + "..." + fileName.slice(fileName.length - 4)
                  : t("addFile", { ns: "FS8-changeCompanyProfile" })}
                <input
                  type="file"
                  id={`fileInput`}
                  className="d-none"
                  onChange={onAddFile}
                  ref={fileRef}
                />
              </label>
              {files[0].filename !== "" && (
                <div className={style.trash}>
                  <Trash
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setFilesLoader(null));
                      dispatch(setFileName(""));
                      dispatch(setFile([{ filename: "", description: "" }]));
                      fileRef.current.value = "";
                      form.resetFields(["descriptionFile"]);
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={
                filesLoader === t("loading", { ns: "FS8-changeCompanyProfile" })
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              {filesLoader}
            </div>

            <div className={style.descriptionFile}>
              {t("descriptionFile", { ns: "FS8-changeCompanyProfile" })}
            </div>
            <Form.Item
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              name={`descriptionFile`}
              className={style.textArea}
              onChange={(e) =>
                filesHandler({ filename: files[0].filename, description: e.target.value })
              }
              rules={[
                {
                  validator: (_, value) => {
                    if (!value && files[0].filename && !files[0].description) {
                      return Promise.reject(t("fileError", { ns: "FS8-changeCompanyProfile" }));
                    } else {
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <TextArea
                row={4}
                disabled={files[0].filename === ""}
                showCount
                autoSize
                maxLength={255}
                size="large"
                style={{ maxWidth: "360px" }}
              />
            </Form.Item>
          </div>
        </div>
        <AddAndCropLogo
          visible={visibleImage}
          setVisible={setVisibleImage}
          saveCropLogo={(e) => onAddFile(e, "image")}
          img={img}
          setImg={setImg}
        />
      </div>
    </section>
  );
};

export default AdditionalStuff;