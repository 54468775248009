import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import s from "./Notifications.module.less";
import PropTypes from "prop-types";
import Close from "../../assets/svg/Close";
import {
  getNotificationsFeed,
  getUnreadNotificationsCount,
  sendMarkNotificationsAsRead
} from "../../store/notificationsFeedSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import NotificationItem from "./NotificationItem";
import NotificationsAbsence from "./NotificationsAbsence";
import Loader from "../Loader/Loader";
const Bell = ({ opened, onClick, providedRef, totalUnreadCount }) => {
  return (
    <div ref={providedRef} onClick={onClick} className={`${s.bell} ${opened ? s.opened : ""}`}>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="34" height="34" rx="17" />
        <path
          d="M20.375 22.5556H26L24.4194 20.9944C24.2071 20.7848 24.0387 20.5358 23.9239 20.2619C23.809 19.9879 23.75 19.6943 23.75 19.3978V15.8889C23.7502 14.5099 23.3175 13.1648 22.5116 12.0388C21.7057 10.9127 20.5662 10.0611 19.25 9.60111V9.22222C19.25 8.63285 19.0129 8.06762 18.591 7.65087C18.169 7.23413 17.5967 7 17 7C16.4033 7 15.831 7.23413 15.409 7.65087C14.9871 8.06762 14.75 8.63285 14.75 9.22222V9.60111C12.1288 10.5167 10.25 12.9867 10.25 15.8889V19.3989C10.25 19.9967 10.0093 20.5711 9.58063 20.9944L8 22.5556H13.625M20.375 22.5556H13.625M20.375 22.5556V23.6667C20.375 24.5507 20.0194 25.3986 19.3865 26.0237C18.7536 26.6488 17.8951 27 17 27C16.1049 27 15.2464 26.6488 14.6135 26.0237C13.9806 25.3986 13.625 24.5507 13.625 23.6667V22.5556"
          stroke="#D9DFE3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {totalUnreadCount > 0 && <div className={s.unreadNotifications}>{totalUnreadCount}</div>}
    </div>
  );
};

const NotificationsBlock = ({
  opened,
  setOpened,
  providedRef,
  closeNotifications,
  buyerUnreadNotificationsCount,
  sellerUnreadNotificationsCount,
  buyerNotifications,
  sellerNotifications
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("NotificationsFeed");
  const { user } = useSelector((state) => state.auth);
  const { TabPane } = Tabs;
  const scrollHandler = (e, side) => {
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 20) {
      if (side === "BUYER") {
        if (
          buyerNotifications.items.length < buyerNotifications.total &&
          !buyerNotifications.loading
        ) {
          dispatch(getNotificationsFeed("BUYER", buyerNotifications.items.length, 10));
        }
      } else if (side === "SELLER") {
        if (
          sellerNotifications.items.length < sellerNotifications.total &&
          !sellerNotifications.loading
        ) {
          dispatch(getNotificationsFeed("SELLER", sellerNotifications.items.length, 10));
        }
      }
    }
  };
  const [currentlyOpenedTab, setCurrentlyOpenedTab] = useState("buyer");
  const watchedNotificationsArr = useRef(new Set());
  const observerHandler = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const id = entry.target.getAttribute("data-id");
        watchedNotificationsArr.current = new Set([...watchedNotificationsArr.current, id]);
        observer.unobserve(entry.target);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerHandler, {
      threshold: 1,
      root: providedRef.current
    });
    if (opened) {
      providedRef.current
        .querySelectorAll("[data-isread='0']")
        .forEach((el) => observer.observe(el));
    }

    return () => {
      observer.disconnect();
    };
  }, [buyerNotifications, sellerNotifications, opened, currentlyOpenedTab]);
  useEffect(() => {
    if (!opened && [...watchedNotificationsArr.current].length) {
      dispatch(
        sendMarkNotificationsAsRead([...watchedNotificationsArr.current].map((el) => Number(el)))
      );
      watchedNotificationsArr.current = new Set();
    }
  }, [opened]);
  return (
    <div ref={providedRef} className={`${s.notificationsBlock} ${opened ? s.opened : ""}`}>
      <span className={s.close} onClick={closeNotifications}>
        <Close />
      </span>
      <p className={s.title}>{t("title")}</p>
      <Tabs defaultActiveKey="buyer" onChange={(key) => setCurrentlyOpenedTab(key)}>
        <TabPane
          tab={`${t("buyer")} ${
            buyerUnreadNotificationsCount > 0 ? `(${buyerUnreadNotificationsCount})` : ""
          }`}
          key="buyer"
        >
          <div className={s.messagesBlock} onScroll={(e) => scrollHandler(e, "BUYER")}>
            {buyerNotifications?.items?.length !== 0 ? (
              <>
                {buyerNotifications?.items.map((el) => (
                  <NotificationItem
                    item={el}
                    t={t}
                    key={el.id}
                    setOpened={setOpened}
                    tz={user?.tz?.timeZone}
                  />
                ))}
                {buyerNotifications.loading && <Loader style={{ width: "100%" }} />}
              </>
            ) : (
              <NotificationsAbsence />
            )}
          </div>
        </TabPane>
        <TabPane
          tab={`${t("seller")} ${
            sellerUnreadNotificationsCount > 0 ? `(${sellerUnreadNotificationsCount})` : ""
          } `}
          key="seller"
        >
          <div className={s.messagesBlock} onScroll={(e) => scrollHandler(e, "SELLER")}>
            {sellerNotifications?.items?.length !== 0 ? (
              <>
                {sellerNotifications?.items.map((el) => (
                  <NotificationItem
                    item={el}
                    t={t}
                    key={el.id}
                    setOpened={setOpened}
                    tz={user?.tz?.timeZone}
                  />
                ))}
                {sellerNotifications.loading && <Loader style={{ width: "100%" }} />}
              </>
            ) : (
              <NotificationsAbsence />
            )}
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

const Notifications = () => {
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);

  const bellRef = useRef(null);
  const notificationsBlockRef = useRef(null);

  const { count, buyerNotifications, sellerNotifications } = useSelector(
    (state) => state.notificationsFeed
  );

  const switchOpened = () => {
    setOpened((prev) => !prev);
  };
  const closeNotificationsClickListener = (e) => {
    if (
      bellRef.current &&
      notificationsBlockRef.current &&
      e.target !== bellRef.current &&
      e.target !== notificationsBlockRef.current &&
      !bellRef.current.contains(e.target) &&
      !notificationsBlockRef.current.contains(e.target)
    ) {
      setOpened(false);
    }
  };
  const closeNotifications = () => setOpened(false);

  useEffect(() => {
    dispatch(getUnreadNotificationsCount());
  }, []);
  useEffect(() => {
    if (opened) {
      document.body.addEventListener("click", closeNotificationsClickListener);
    } else {
      document.body.removeEventListener("click", closeNotificationsClickListener);
    }
  }, [opened]);

  return (
    <>
      <Bell
        opened={opened}
        onClick={switchOpened}
        providedRef={bellRef}
        totalUnreadCount={count.unreadAll}
      />
      {createPortal(
        <NotificationsBlock
          opened={opened}
          setOpened={setOpened}
          providedRef={notificationsBlockRef}
          closeNotifications={closeNotifications}
          buyerUnreadNotificationsCount={count.unreadBuyer}
          sellerUnreadNotificationsCount={count.unreadSeller}
          buyerNotifications={buyerNotifications}
          sellerNotifications={sellerNotifications}
        />,
        document.getElementById("root")
      )}
    </>
  );
};

Bell.propTypes = {
  opened: PropTypes.bool,
  onClick: PropTypes.func,
  providedRef: PropTypes.object,
  totalUnreadCount: PropTypes.number
};

NotificationsBlock.propTypes = {
  opened: PropTypes.bool,
  setOpened: PropTypes.func,
  providedRef: PropTypes.object,
  closeNotifications: PropTypes.func,
  buyerUnreadNotificationsCount: PropTypes.number,
  sellerUnreadNotificationsCount: PropTypes.number,
  buyerNotifications: PropTypes.object,
  sellerNotifications: PropTypes.object
};

export default Notifications;
