import styles from "./UploadButton.module.less";
import uploadImage from "Assets/image/uploadButton.png";
import { mediaUrl } from "Api/api";

export const UploadButton = ({ title, uploadInfo, text, image, className, ...props }) => (
  <div>
    <div className={`${styles.uploadButton} ${className}`} {...props}>
      {image ? (
        <img
          style={{ height: "80px", width: "80px", objectFit: "contain" }}
          className={styles.uploadImage}
          width={85}
          height={85}
          src={`${mediaUrl}${image}`}
        />
      ) : (
        <img className={styles.uploadImage} src={uploadImage} />
      )}
      <div className={styles.uploadTextWrap}>
        {title && <strong>{title}</strong>}
        <p className={styles.uploadText}>
          {text}
        </p>
        <span className={styles.uploadLoadText}>{uploadInfo}</span>
      </div>
    </div>
  </div>
);
