/* eslint-disable no-extra-boolean-cast */
import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, Button, Checkbox, DatePicker, Form, Select, Space, Tooltip, Upload } from "antd";
import { TimePicker } from "antd-v5";

import TextArea from "antd/lib/input/TextArea";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";
import DropdownIcon from "../DropdownIcon/DropdownIcon";
import Trash from "../../assets/svg/trash";
import { getCities, getCountries } from "../../store/resourcesSlice";
import {
  backendUrl,
  draftsAPI,
  exhibitionAPI,
  mediaUrl,
  resourcesAPI,
  uploadsAPI
} from "../../api/api";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { meetingApi } from "../../api";
import { DebounceSearchSelect, SimpleSelect, styles } from "Components/Search";

import s from "./create-exhibition-panel.module.less";
import useOnlyLatinLetterInputHandlerAndModal from "../../hooks/useOnlyLatinLetterInputHandlerAndModal";
import AutoCompleteCustom from "./AutoComplete/AutoComplete";
import { setExhibitionPanel, setSavedExhibition } from "../../store/exhibitionSlice";
import axios from "axios";

const dateFormat = "YYYY-MM-DD";

const modalTypes = {
  FINISH_EDITING: "FINISH_EDITING",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  CREATION_SUCCESS: "CREATION_SUCCESS",
  WRONG_NEW_EXHIBITION_OR_EXHIBITION_ID: "WRONG_NEW_EXHIBITION_OR_EXHIBITION_ID",
  STAND_EXISTS: "STAND_EXISTS",
  NOT_FOUND_EXHIBITION: "NOT_FOUND_EXHIBITION",
  EXHIBITION_ALREADY: "EXHIBITION_ALREADY",
  NO_CORRECT_TIMES: "NO_CORRECT_TIMES",
  EMPTY_EXHIBITION: "EMPTY_EXHIBITION",
  SAVING_SUCCESS: "SAVING_SUCCESS"
};

const CreateExhibitionPanel = ({ num = 1, showCreateExhibitionPanel }) => {
  const [openState, setOpenState] = useState(false);
  const { isEditMode, savedExhibition } = useSelector((state) => state.exhibitions);
  const { isAdmin, user } = useSelector((state) => state.auth);
  const { t } = useTranslation([
    "CreateExhibitionPanel",
    "Countries",
    "FS8-changeCompanyProfile",
    "FS100-MyExhibitions",
    "FA52A-Moderation-changes"
  ]);
  const { countries, cities: resourcesCities } = useSelector((state) => state.resources);
  const [errors, setErrors] = useState({
    passageDiagram: false,
    description: false,
    boothNumber: false
  });
  const [checkboxError, setCheckboxError] = useState(false);
  const { lang } = useSelector((state) => state.auth);
  const [passageDiagram, setPassageDiagram] = useState("");
  const [form] = Form.useForm();
  const [exhibition, setExhibition] = useState({});
  const [modalType, setModalType] = useState("");
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const uploadRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [newExhibitionName, setNewExhibitionName] = useState("");
  const [exhibitionName, setExhibitionName] = useState("");
  const [exhibitionId, setExhibitionId] = useState(null);
  const isPrivateCheckbox = Form.useWatch("isPrivate", form);
  const isVisibleCheckbox = Form.useWatch("isVisible", form);
  const [cities, setCities] = useState([]);

  const [isCreating, setIsCreating] = useState(true);

  const [initialValues, setInitialValues] = useState({});
  const [checkBoxSelected, setCheckboxSelected] = useState({
    isPrivate: isPrivateCheckbox || initialValues.isPrivate,
    isVisible: isVisibleCheckbox || initialValues.isVisible
  });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { myExhibitions, existExhibitions, existExhibitionsArchive } = useSelector(
    (state) => state.exhibitions
  );
  const [validateCheckBox, setValidateCheckBox] = useState(false);
  const [modalText, setModalText] = useState("");

  const exhibitionArciveMappedNameArray = existExhibitionsArchive.map(
    (archiveExhibition) => archiveExhibition.value
  );
  const exhibitionsMappedNameArray = existExhibitions?.map((exhibitions) => exhibitions.value);

  const exhibitionNameAnreadyExsist =
    (newExhibitionName && exhibitionsMappedNameArray.includes(newExhibitionName)) ||
    (!newExhibitionName && exhibitionsMappedNameArray.includes(initialValues.exhibitionName));

  const [times, setTimes] = useState({
    endDate: "",
    startDate: "",
    times: []
  });

  const myExhibitionsMapped = myExhibitions.items.map((obj) => obj.exhibitionName);

  const standAlreadyExsist =
    (newExhibitionName && myExhibitionsMapped.includes(newExhibitionName)) ||
    (!newExhibitionName && myExhibitionsMapped.includes(initialValues.exhibitionName));

  const scrollToFirstInvalidFiled = () => {
    setTimeout(() => {
      const firstInvalidField = document.querySelector(".ant-form-item-has-error");
      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest"
        });
      }
    }, 100);
  };

  useEffect(() => {
    if (!isAdmin && times.times.length > 0) {
      dispatch(setExhibitionPanel(true));
    } else {
      dispatch(setExhibitionPanel(false));
    }
  }, [times.times, isAdmin]);

  const formatDate = (date) => {
    return `${date.$y}-${(date.$M + 1).toString().padStart(2, "0")}-${date.$D
      .toString()
      .padStart(2, "0")}`;
  };

  const restoreData = async () => {
    await handleCitiesSearch("", exhibition.countryId);
    console.log(savedExhibition);
    onFinish(
      {
        ...savedExhibition.exhibition,
        startDate: formatDate(savedExhibition.exhibition.startDate),
        endDate: formatDate(savedExhibition.exhibition.endDate)
      },
      savedExhibition.times
    );
    dispatch(setSavedExhibition(null));
  };
  useEffect(() => {
    if (!savedExhibition) return;
    setCheckboxSelected((prev) => ({
      ...prev,
      isPrivate: savedExhibition?.checkbox?.isPrivate,
      isVisible: savedExhibition?.checkbox?.isVisible
    }));
    restoreData();
  }, [savedExhibition]);

  useEffect(() => {
    const getDraft = async () => {
      if (!isAdmin && showCreateExhibitionPanel) {
        const qParams = {
          companyId: user.company?.id,
          entityType: "exhibition"
        };
        try {
          const { data } = await draftsAPI.getDraft(qParams);
          const passageDiagram = data?.stand?.passageDiagram;
          const { stand } = data;
          let exhibition = {};
          if (data.exhibitionId) {
            exhibition = (await exhibitionAPI.getExhibitionById(data.exhibitionId)).data;
            setExhibitionId(data.exhibitionId);
            setIsDisabled(true);
          } else {
            const { newExhibition } = data;
            exhibition = newExhibition;
          }
          setPassageDiagram(passageDiagram);
          const response = await handleCitiesSearch(
            "",
            data?.countryId || data?.newExhibition?.countryId
          );
          console.log(data, response, "ll");
          response && setCities(response.data);
          const formValues = {
            exhibitionName: exhibition?.exhibitionName,
            website: exhibition?.website,
            countryId: exhibition?.countryId,
            cityId: exhibition?.cityId,
            areaName: exhibition?.areaName,
            startDate: exhibition?.startDate ? dayjs(exhibition?.startDate) : "",
            endDate: exhibition?.endDate ? dayjs(exhibition?.endDate) : "",
            isPrivate: !!exhibition?.isPrivate,
            boothNumber: stand?.boothNumber,
            description: stand?.description,
            isVisible: !!stand?.isVisible,
            statsConfirmedMeetings: exhibition?.statsConfirmedMeetings,
            statsUnconfirmedMeetings: exhibition?.statsUnconfirmedMeetings
          };
          setInitialValues(formValues);
          setExhibitionName(formValues.exhibitionName);
          if (exhibition?.startDate && exhibition?.endDate) {
            setTimes((prev) => ({
              ...prev,
              startDate: dayjs(dayjs(exhibition?.startDate).format(dateFormat)),
              endDate: dayjs(dayjs(exhibition?.endDate).format(dateFormat)),
              times: calcDaysWorkingTimeArr(
                exhibition?.startDate,
                exhibition?.endDate,
                exhibition?.times
              )
            }));
          }
          setNewExhibitionName(exhibition?.exhibitionName);

          if (exhibition?.times) {
            exhibition.times.forEach((el, i) => {
              form.setFieldsValue({
                [`rangePicker_${i}`]: [dayjs(el.startDate), dayjs(el.endDate)]
              });
            });
          }
          if (stand?.passageDiagram) {
            setPassageDiagram(stand?.passageDiagram);
          }
          form.setFieldsValue({ isVisible: !!formValues.isVisible });
          form.setFieldsValue({ isPrivate: !!formValues.isPrivate });
          form.setFieldsValue({ boothNumber: formValues.boothNumber });
          form.setFieldsValue({ ...formValues });
        } catch (error) {
          throw new Error(error);
        }
      }
    };

    if (formRef.current && form.__INTERNAL__.name) getDraft();
  }, [showCreateExhibitionPanel, form]);

  useEffect(() => {
    if (initialValues.isPrivate || initialValues.isVisible) {
      setCheckboxSelected((prev) => ({
        ...prev,
        isPrivate: initialValues.isPrivate,
        isVisible: initialValues.isVisible
      }));
    }
  }, [initialValues.isPrivate, initialValues.isVisible]);

  useEffect(() => {
    if (newExhibitionName !== exhibitionName && !exhibitionId) {
      setIsDisabled(false);
      setExhibitionId(null);
    }
  }, [newExhibitionName, exhibitionName]);

  const clearImage = () => {
    setPassageDiagram("");
  };

  useEffect(() => {
    if (exhibitionName) {
      setNewExhibitionName(exhibitionName);
    }
  }, [exhibitionName]);

  const handleResetForm = () => {
    const formFields = Object.keys(form.getFieldValue());
    formFields.forEach((fieldName) => {
      form.setFieldsValue({ [fieldName]: undefined });
    });
  };

  const clearForm = () => {
    form.resetFields();
    handleResetForm();
    setExhibition({});
    setTimes({
      endDate: "",
      startDate: "",
      times: []
    });
  };

  const deleteDraft = async () => {
    try {
      await exhibitionAPI.createExhibition({
        sendModeration: false
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const clearError = (fieldName) => form.setFields([{ name: fieldName, errors: [] }]);

  useEffect(() => {
    if (!countries.length) {
      dispatch(getCountries());
    }
    if (!resourcesCities.length) {
      dispatch(getCities());
    }
  }, []);

  const citiesOptions = useMemo(
    () =>
      cities.map(({ id, localizedName }) => ({
        label: localizedName[lang],
        value: id
      })),
    [cities, lang]
  );

  console.log(citiesOptions);

  useMemo(() => {
    const {
      exhibitionName,
      website,
      country,
      cityId,
      areaName,
      endDate,
      startDate,
      description,
      times,
      id
    } = exhibition;
    if (formRef.current && form.__INTERNAL__.name) {
      if (!_.isEmpty(exhibition)) {
        setExhibitionId(id);
        setNewExhibitionName(exhibitionName);
        setIsDisabled(true);
        console.log("1");
        form.setFieldsValue({
          website,
          cityId,
          areaName,
          countryId: country?.id,
          exhibitionName,
          startDate: dayjs(dayjs(startDate).format(dateFormat), dateFormat),
          endDate: dayjs(dayjs(endDate).format(dateFormat), dateFormat),
          description,
          isPrivate: !!exhibition?.isPrivate,
          isVisible: !exhibition?.isPrivate
        });
        setCheckboxSelected((prevState) => ({
          ...prevState,
          isVisible: !exhibition?.isPrivate,
          isPrivate: !!exhibition?.isPrivate
        }));
        form.setFieldsValue({ website: website });
        if (startDate && endDate) {
          setTimes((prev) => ({
            ...prev,
            startDate: dayjs(dayjs(startDate).format(dateFormat)),
            endDate: dayjs(dayjs(endDate).format(dateFormat)),
            times: calcDaysWorkingTimeArr(startDate, endDate, times)
          }));
        }

        if (times) {
          times.forEach((_, i) => {
            form.setFieldsValue({
              [`rangePicker_${i}`]: [
                dayjs(dayjs(times.startDate).format("YYYY-MM-DD 10:00")),
                dayjs(dayjs(times.endDate).format("YYYY-MM-DD 18:00"))
              ]
            });
          });
        }
      } else {
        setIsDisabled(false);
      }
    }
  }, [exhibition, formRef, form]);

  const handleErrors = (errorFields) => {
    errorFields?.forEach(({ name }) => {
      const errorField = name[name.length - 1];
      setErrors((prevErrors) => {
        return { ...prevErrors, [errorField]: true };
      });
    });
  };

  function clearAllErrors(arr) {
    const errorFieldsNames = arr.map(({ name }) => {
      if (name.length === 1) {
        return `${name[0]}`;
      } else if (name.length === 2) {
        return `${name[0]}.${name[1]}`;
      }
      return "";
    });
    form.resetFields(errorFieldsNames);
  }

  const normalizeExhibitionData = (exhibition) => {
    const exhibitionName = exhibition.exhibitionName;
    const startDate = exhibition.startDate;
    const endDate = exhibition.endDate;
    const date1 = dayjs(startDate);
    const date2 = dayjs(endDate);
    const daysCount = date2.diff(date1, "day");

    const extractedRangePickerArray = [];
    for (const key in exhibition) {
      if (key.includes("rangePicker")) {
        extractedRangePickerArray.push(exhibition[key]);
      }
    }

    function formatTime(date) {
      return dayjs(date.$d).format("HH:mm");
    }

    const formattedTimeArray = extractedRangePickerArray.map((nestedArray) => {
      const startTime = formatTime(nestedArray[0]);
      const endTime = formatTime(nestedArray[1]);
      return { startTime, endTime };
    });

    const workingHours = Array.from({ length: daysCount + 1 }, (_, i) => {
      return {
        startDate: date1.add(i, "day").format("DD.MM.YYYY"),
        timeRange: formattedTimeArray
      };
    });

    const newObj = {
      exhibitionName,
      website: exhibition.website,
      countryId: exhibition.countryId,
      cityId: exhibition.cityId,
      areaName: exhibition.areaName,
      startDate: dayjs(exhibition.startDate).format("DD-MM-YYYY"),
      endDate: dayjs(exhibition.endDate).format("DD-MM-YYYY"),
      times: workingHours,
      isPrivate: exhibition.isPrivate ? true : false,
      stand: {
        isVisible: exhibition.isVisible ? true : false,
        passageDiagram: passageDiagram,
        boothNumber: exhibition.boothNumber,
        description: exhibition.description
      }
    };
    if (exhibitionId) newObj.id = exhibitionId;

    return newObj;
  };

  const onFinish = async (
    {
      stand,
      description,
      boothNumber,
      areaName,
      cityId,
      countryId,
      website,
      exhibitionName,
      startDate,
      endDate,
      ...rest
    },
    custoTimes
  ) => {
    const isFree = exhibitionName ? await isExhibitionNameFree(exhibitionName) : true;
    const exhibitionTimes = custoTimes || times;
    console.log(isFree, "isFree");
    if (!isFree && isCreating) {
      setModalText(t("infoModal.errorExhibitionAlreadyExist"));
      setShowErrorModal(true);
      form.setFieldsValue({ exhibitionName: "" });
    } else {
      let sendData = {
        sendModeration: !!isAdmin
      };
      delete rest.passageDiagram;
      delete rest.isVisible;
      delete rest.isPrivate;
      let exhibition = {};
      let standData = _.omitBy(stand, _.isNil());
      console.log(rest, "rest");
      if (exhibitionTimes.times.length) {
        /*  exhibition.times = Object.keys(rest).map((key) => ({
          startTime: dayjs(rest[key][0]).format("HH:mm"),

          endTime: dayjs(rest[key][1]).format("HH:mm")
        }));
        Object.keys(rest).map((key) => {
          console.log(dayjs(rest[key][0]).format("HH:mm"), key, key[0]?.format);
        }); */
        exhibition.times = Object.keys(rest).map((key) => {
          const startDate = rest[key][0];
          const endDate = rest[key][1];

          const formatTime = (date) => {
            if (date.format) {
              return dayjs(date).format("HH:mm");
            } else {
              // Use $H and $m properties to construct the time string
              return `${date.$H.toString().padStart(2, "0")}:${date.$m
                .toString()
                .padStart(2, "0")}`;
            }
          };

          return {
            startTime: formatTime(startDate),
            endTime: formatTime(endDate)
          };
        });
      }

      if (endDate) {
        exhibition.endDate = dayjs(endDate).format(dateFormat);
      }

      if (startDate) {
        exhibition.startDate = dayjs(startDate).format(dateFormat);
      }

      if (passageDiagram?.length) {
        standData["passageDiagram"] = passageDiagram;
      }
      sendData = {
        newExhibition: {
          ...exhibition,
          description,
          areaName,
          cityId,
          countryId,
          website,
          exhibitionName,
          isPrivate: checkBoxSelected.isPrivate
        },
        stand: { ...standData, description, boothNumber, isVisible: checkBoxSelected.isVisible },
        sendModeration: !!isAdmin
      };

      sendData.newExhibition = _(sendData.newExhibition)
        .omitBy(_.isUndefined)
        .omitBy(_.isNull)
        .value();
      sendData.stand = _(sendData.stand).omitBy(_.isUndefined).omitBy(_.isNull).value();

      if ((newExhibitionName === exhibition?.exhibitionName || exhibitionId) && exhibitionId) {
        delete sendData.newExhibition;
        sendData.exhibitionId = exhibitionId;
      } else {
        exhibition.exhibitionName = exhibitionName;
      }
      if (isAdmin) {
        sendData.newExhibition.isPrivate = false;
      }

      if (isAdmin) {
        delete sendData.stand;
      }

      const isNewExhibitionEmpty = _.isEmpty(sendData.newExhibition);
      const isStandEmpty = _.isEmpty(sendData.stand);

      if (!isAdmin && isNewExhibitionEmpty && isStandEmpty) {
        deleteDraft();
      } else {
        try {
          await exhibitionAPI.createExhibition({ ...sendData });
          handleOpenModal(modalTypes.SAVING_SUCCESS);
          if (isAdmin) clearForm();
        } catch ({ response }) {
          const { message: errorMessage } = response.data;
          handleOpenModal(modalTypes[errorMessage]);
        }
      }
    }
  };

  const handleExhibitionSelect = useCallback(async (_, { key }) => {
    try {
      const { data } = await exhibitionAPI.getExhibitionById(key);
      if (data.isPrivate) {
        handleOpenModal("isPrivate");
        setExhibitionName(" ");
      } else if (data.isArchive) {
        handleOpenModal("isArchive");
        setExhibitionName(" ");
      } else if (data.onModeration) {
        handleOpenModal("onModeration");
        setExhibitionName("baba");
      } else {
        const response = await handleCitiesSearch("", data.countryId);
        setCities(response.data);
        setExhibition(data);
        setIsCreating(false);
      }
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const beforeUpload = (file) => {
    if (file.size / 1024 > 5024) {
      clearImage();
      setErrors((prev) => ({
        ...prev,
        passageDiagram: true
      }));
      return false;
    }
    if (errors.passageDiagram) {
      setErrors((prev) => ({
        ...prev,
        passageDiagram: false
      }));
    }
  };

  const isExhibitionNameFree = async (exhibitionName) => {
    try {
      let data = await axios
        .get(`${backendUrl}/exhibitions/search?exhibitionName=${exhibitionName}&all=true`)
        .then(({ data }) => data);

      const isThereExhibitionName = data.some((item) => item.exhibitionName === exhibitionName);
      return !isThereExhibitionName;
    } catch (error) {
      throw new Error(error);
    }
  };

  const uploadImg = async (data) => {
    const formData = new FormData();
    formData.append("file", data.file, data.file.type.replace("/", "."));
    formData.append("originalname", data.file.name);
    const uploadedImage = await (await uploadsAPI.uploadImage(formData)).data.filename;
    setPassageDiagram(uploadedImage);
  };

  const normalizeFile = (event) => {
    if (Array.isArray(event)) {
      return event;
    }
    return event && event.fileList;
  };

  const onWorkingTimeChange = (val, index) => {
    setTimes((prev) => {
      return {
        ...prev,
        times: prev.times.map((el, i) => {
          if (i === index) {
            return {
              ...el,
              startTime: val ? val[0].format("HH:mm") : undefined,
              endTime: val ? val[1].format("HH:mm") : undefined,
              timeRange: val
            };
          } else {
            return el;
          }
        })
      };
    });
  };
  const onEndDateChange = (val) => {
    const date = val ? dayjs(val.$d).format(dateFormat) : undefined;
    setTimes((prev) => ({
      ...prev,
      endDate: date,
      times: calcDaysWorkingTimeArr(prev.startDate, date, prev.times)
    }));
    clearError("endDate");
  };
  const onStartDateChange = (val) => {
    const date = val ? dayjs(val.$d).format(dateFormat) : undefined;
    setTimes((prev) => {
      const prevDate =
        prev.endDate && dayjs(prev.endDate).isBefore(date)
          ? dayjs(date).format(dateFormat)
          : prev.endDate;
      return {
        ...prev,
        startDate: date,
        endDate: prevDate,
        times: calcDaysWorkingTimeArr(date, prevDate, prev.times)
      };
    });
    clearError("startDate");
  };
  const calcDaysWorkingTimeArr = (startDate, endDate, curTimesArr) => {
    if (!startDate || !endDate) return [];

    const date1 = dayjs(startDate);
    const date2 = dayjs(endDate);
    const daysCount = date2.diff(date1, "day");
    const defaultTimeObj = {
      startTime: undefined,
      endTime: undefined,
      date: undefined,
      timeRange: [undefined, undefined]
    };
    const newTimesArr = Array.from({ length: daysCount + 1 }, (_, i) => {
      const endTime = i === daysCount ? "16:00" : "18:00";

      return {
        ...defaultTimeObj,
        date: date1.add(i, "day").format("DD.MM"),
        timeRange: [
          dayjs(dayjs().format("YYYY-MM-DD 10:00")),
          dayjs(dayjs().format(`YYYY-MM-DD ${endTime}`))
        ]
      };
    });

    curTimesArr?.forEach((el, i) => {
      const endTime = i === daysCount ? "16:00" : "18:00";

      if (i < newTimesArr.length) {
        newTimesArr[i] = {
          ...newTimesArr[i],
          startTime: el.startTime,
          endTime: el.endTime,
          timeRange: [
            dayjs(dayjs().format(`YYYY-MM-DD ${el.startTime || "10:00"}`)),
            dayjs(dayjs().format(`YYYY-MM-DD ${el.endTime || endTime}`))
          ]
        };
      }
    });
    newTimesArr.forEach((el, i) => {
      const endTime = i === daysCount ? "16:00" : "18:00";
      if (formRef.current) {
        form.setFieldsValue({
          [`rangePicker_${i}`]: [
            dayjs(dayjs().format(`YYYY-MM-DD ${el.startTime || "10:00"}`)),
            dayjs(dayjs().format(`YYYY-MM-DD ${el.endTime || endTime}`))
          ]
        });
      }
    });
    return newTimesArr;
  };

  const navigate = useNavigate();

  const handleCloseModal = () => setModalType("");
  const handleOpenModal = (modalType) => setModalType(modalType);

  const renderSelectOptions = useMemo(
    () =>
      countries.map((country) => (
        <Select.Option key={country.id} value={country.id}>
          {t(country.name, { ns: "Countries" })}
        </Select.Option>
      )),
    [countries, t]
  );

  const handleIsVisible = ({ target: checked }) => {
    console.log("ww");
    setCheckboxSelected((prevState) => ({
      ...prevState,
      isVisible: checked.checked,
      isPrivate: false
    }));
    form.setFieldsValue({ isPrivate: false });
    form.setFieldsValue({ isVisible: checked.checked });
  };

  const handleIsPrivate = ({ target: checked }) => {
    setCheckboxSelected((prevState) => ({
      ...prevState,
      isVisible: false,
      isPrivate: checked.checked
    }));
    form.setFieldsValue({ isPrivate: checked.checked });
    form.setFieldsValue({ isVisible: false });
  };

  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();

  const handleCitiesSearch = async (name, customCountry) => {
    const countryIds = form.getFieldValue("countryId");
    if (!countryIds && !customCountry) return;
    const response = await resourcesAPI.getCities(name, customCountry || countryIds);
    return { data: response.data?.cities };
  };

  console.log(exhibition);

  return (
    <>
      {OnlyLatinLettersModal}
      {modalType && (
        <Modal
          footer={null}
          visible={modalType}
          onOk={handleCloseModal}
          onCancel={handleCloseModal}
          className={s.modal}
        >
          {renderModalContent({
            modalType,
            t,
            handleCloseModal,
            isAdmin
          })}
        </Modal>
      )}

      <div className={s.wrapper} style={{ minWidth: !isEditMode ? 1581 : 1718 }}>
        <Form
          form={form}
          ref={formRef}
          labelAlign="left"
          autoComplete="off"
          scrollToFirstError
          onFinish={onFinish}
          onFinishFailed={({ errorFields }) => {
            clearAllErrors(errorFields);
            onFinish(form.getFieldsValue());
          }}
          style={{ borderRight: "none" }}
          requiredMark={false}
          validateTrigger="onSubmit"
          name="addNewExhibitionForm"
        >
          <div>
            <div
              className={s.tableRow}
              style={{
                borderRight: "none",
                width: !isEditMode ? 1581 : 1754,
                border: isAdmin && "none"
              }}
            >
              <div className={s.head}>
                <div style={{ width: 56 }} className={`${s.cell} ${s.num} ${s.small}`}>
                  {num}
                </div>
                <div className={`${s.cell} ${s.big}`}>{t("exhibitionName")}</div>
                <div style={{ width: 150 }} className={`${s.cell} ${s.big}`}>
                  {t("website")}
                </div>
                <div style={{ width: 163 }} className={`${s.cell} ${s.big}`}>
                  {t("country")}
                </div>
                <div style={{ width: 170 }} className={`${s.cell} ${s.big}`}>
                  {t("city")}
                </div>
                <div style={{ width: 150 }} className={`${s.cell} ${s.big}`}>
                  {t("areaName")}
                </div>
                <div className={`${s.cell} ${s.mid}`} style={{ width: 160 }}>
                  {t("startDate")}
                </div>
                <div className={`${s.cell} ${s.mid}`} style={{ width: 175 }}>
                  {t("endDate")}
                </div>
                <div
                  className={`${s.cell} ${s.xl}`}
                  style={{
                    width:
                      (!isAdmin && !isEditMode && times.times.length > 0) ||
                      (!isAdmin && isEditMode) ||
                      times.times.length > 0
                        ? 300
                        : 160
                  }}
                >
                  {t("times")}
                </div>
                <div className={`${s.cell} ${s.big}`} style={{ width: 225 }}>
                  {t("companies1")}
                </div>
                <div
                  className={`${s.cell} ${s.big}`}
                  style={{
                    width:
                      (!isAdmin && !isEditMode && times.times.length > 0) ||
                      (!isAdmin && isEditMode) ||
                      times.times.length > 0
                        ? 223
                        : isAdmin
                        ? 228
                        : 214
                  }}
                >
                  {t("moderationStatus")}
                </div>
              </div>

              <div className={s.tableBody}>
                <div className={`${s.cell2} ${s.small}`}></div>
                <div className={`${s.cell2} ${s.big} ${s.withDropDown}`}>
                  <Form.Item
                    name={"exhibitionName"}
                    rules={[
                      {
                        required: true,
                        message: ""
                      },
                      {
                        validator:
                          (!newExhibitionName && !exhibitionName) ||
                          (exhibitionName && !newExhibitionName),
                        message: ""
                      }
                    ]}
                  >
                    <AutoCompleteCustom
                      isError={Boolean(errors.exhibitionName)}
                      handleSelect={handleExhibitionSelect}
                      OnlyLatinLettersTextInput={OnlyLatinLettersTextInput}
                      form={form}
                      value={exhibitionName}
                      t={t}
                      setNewExhibitionName={setNewExhibitionName}
                      setErrors={setErrors}
                      errors={errors}
                      clearError={clearError}
                    />
                  </Form.Item>
                </div>
                <div className={`${s.cell2} ${s.big}`}>
                  <Form.Item
                    className={s.formItem}
                    name={"website"}
                    rules={[
                      {
                        required: true,
                        message: ""
                      },
                      {
                        validator: (_, value) => {
                          const httpPattern = /^https?:\/\//;
                          const urlPattern =
                            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*(\?.*)?\/?$/;
                          if (!value || !httpPattern.test(value)) {
                            return Promise.reject(
                              t("websiteError", { ns: "FS8-changeCompanyProfile" })
                            );
                          }
                          if (!value || !urlPattern.test(value)) {
                            return Promise.reject(
                              t("webSiteUnCorrected", { ns: "FS8-changeCompanyProfile" })
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                      }
                    ]}
                  >
                    <TextArea
                      className={`${s.TextArea} ${errors.website ? s.fieldError : ""}`}
                      placeholder={t("addExhibitionLink")}
                      onChange={(e) => {
                        setErrors((prev) => ({ ...prev, website: false }));
                        clearError("website");
                        OnlyLatinLettersTextInput(
                          e.target.value,
                          "website",
                          form,
                          true,
                          null,
                          null,
                          true
                        );
                      }}
                      style={{ minHeight: "119.6px", resize: "none" }}
                      disabled={isDisabled}
                    />
                  </Form.Item>
                </div>
                <div className={`${s.cell2} ${s.big}`}>
                  <Form.Item
                    name={"countryId"}
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <Select
                      className={s.countrySelect}
                      style={{ width: "100%" }}
                      placeholder={t("selectCountry")}
                      onDropdownVisibleChange={(state) => {
                        form.setFields([{ name: "countryId", errors: [] }]);
                        setOpenState(state);
                      }}
                      open={openState}
                      size="small"
                      suffixIcon={
                        <DropdownIcon
                          className={openState ? "ant-select-suffix" : ""}
                          onClick={() => setOpenState((prevState) => !prevState)}
                        />
                      }
                      disabled={isDisabled}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) => {
                        return optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase());
                      }}
                    >
                      {renderSelectOptions}
                    </Select>
                  </Form.Item>
                </div>
                <div className={`${s.cell2} ${s.big}`}>
                  <Form.Item
                    name={"cityId"}
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <DebounceSearchSelect
                      className={s.countrySelect}
                      search={handleCitiesSearch}
                      size="small"
                      style={{ width: "100%" }}
                      store={setCities}
                      disabled={isDisabled}
                      placeholder={t("selectCity")}
                      options={citiesOptions}
                      defaultValue={form.getFieldValue("cityId")}
                    />
                  </Form.Item>
                </div>
                <div className={`${s.cell2} ${s.big}`}>
                  <Form.Item
                    name={"areaName"}
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <TextArea
                      className={`${s.TextArea} ${errors.areaName ? s.fieldError : ""}`}
                      placeholder={t("enterAreaName")}
                      autoSize={{
                        minRows: 4,
                        maxRows: 4
                      }}
                      style={{ minHeight: "119.6px", resize: "none" }}
                      onChange={(e) => {
                        setErrors((prev) => ({ ...prev, areaName: false }));
                        clearError("areaName");
                        OnlyLatinLettersTextInput(
                          e.target.value,
                          "areaName",
                          form,
                          true,
                          null,
                          null,
                          true
                        );
                      }}
                      disabled={isDisabled}
                    />
                  </Form.Item>
                </div>
                <div className={`${s.cell2} ${s.mid}`} style={{ width: 160 }}>
                  <Form.Item
                    name={"startDate"}
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <DatePicker
                      className={s.datePicker}
                      onChange={onStartDateChange}
                      format={"DD.MM.YYYY"}
                      placement="bottomRight"
                      showToday={false}
                      disabledDate={(current) =>
                        (times.endDate && current > dayjs(times.endDate).endOf("day")) ||
                        (current && current < dayjs().startOf("day"))
                      }
                      disabled={isDisabled}
                    />
                  </Form.Item>
                </div>
                <div className={`${s.cell2} ${s.mid}`} style={{ width: 175 }}>
                  <Form.Item
                    name={"endDate"}
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <DatePicker
                      className={s.datePicker}
                      onChange={onEndDateChange}
                      format={"DD.MM.YYYY"}
                      placement="bottomRight"
                      showToday={false}
                      disabledDate={(current) => {
                        return (
                          (current &&
                            times.startDate &&
                            current < dayjs(times.startDate).startOf("day")) ||
                          (current && current < dayjs().startOf("day"))
                        );
                      }}
                      disabled={isDisabled}
                    />
                  </Form.Item>
                </div>
                <div
                  className={`${s.cell2} ${s.xl}`}
                  style={{
                    width:
                      (!isAdmin && !isEditMode && times.times.length > 0) ||
                      (!isAdmin && isEditMode) ||
                      times.times.length > 0
                        ? 300
                        : 160
                  }}
                >
                  {times.times.map((el, i) => (
                    <div key={`${el.date}_${i}`} className={"d-flex align-items-baseline"}>
                      {el.date}:
                      <Form.Item
                        name={`rangePicker_${i}`}
                        className={s.formItem}
                        rules={[
                          {
                            required: true,
                            message: ""
                          }
                        ]}
                      >
                        <TimePicker.RangePicker
                          className={s.datePicker}
                          placeholder={[t("startTime"), t("endTime")]}
                          format={"HH:mm"}
                          style={{ marginLeft: "5px" }}
                          minuteStep={5}
                          onChange={(val) => {
                            onWorkingTimeChange(val, i);
                          }}
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </div>
                  ))}
                </div>
                <div className={`${s.cell2} ${s.big}`} style={{ width: 225 }}>
                  {initialValues.statsUnconfirmedMeetings || 0}/
                  {initialValues.statsConfirmedMeetings || 0}
                </div>
                <div className={`${s.cell2} ${s.big}`} style={{ width: 212 }}>
                  <div className={s.status}>
                    <span>
                      {t("editing", { ns: "CreateExhibitionPanel" })}
                      <Tooltip
                        placement="bottomLeft"
                        title={t("edited", { ns: "FS100-MyExhibitions" })}
                      >
                        <QuestionCircleOutlined
                          style={{
                            position: "relative",
                            cursor: "help",
                            alignSelf: "flex-start",
                            marginBottom: 7,
                            marginLeft: 5,
                            color: "#474747"
                          }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={s.stand}
                style={{
                  minWidth: isAdmin
                    ? times.times.length > 0
                      ? 1889
                      : 1754
                    : times.times.length > 0 || isEditMode
                    ? 1775
                    : 1648
                }}
              >
                {!isAdmin && (
                  <div className={s.standLeft}>
                    <div>
                      <div className="d-flex">
                        <div className={`${s.cell2} ${s.small}`}></div>
                        <div
                          className={`${s.cell} ${s.big}`}
                          style={{ height: 54, alignItems: "center" }}
                        >
                          {t("boothNumber")}:
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className={`${s.cell2} ${s.small}`}></div>
                        <div className={`${s.cell2} ${s.big}`}>
                          <Form.Item
                            name={"boothNumber"}
                            rules={[
                              {
                                required: true,
                                message: ""
                              }
                            ]}
                          >
                            <TextArea
                              className={`${s.TextArea} ${errors.boothNumber ? s.fieldError : ""}`}
                              placeholder={t("enterboothNumber")}
                              autoSize={{
                                minRows: 4,
                                maxRows: 4
                              }}
                              onChange={(e) => {
                                setErrors((prev) => ({ ...prev, boothNumber: false }));
                                clearError("boothNumber");
                                OnlyLatinLettersTextInput(
                                  e.target.value,
                                  "boothNumber",
                                  form,
                                  true,
                                  null,
                                  null,
                                  true
                                );
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{ height: 54, alignItems: "center" }}
                        className={`${s.cell} ${s.big}`}
                      >
                        {t("schemaToStand")}
                      </div>
                      <div className={`${s.cell2} ${s.big}`}>
                        <Form.Item
                          name={"passageDiagram"}
                          valuePropName="fileList"
                          getValueFromEvent={normalizeFile}
                        >
                          {passageDiagram ? (
                            <>
                              <img
                                src={`${mediaUrl}${passageDiagram}`}
                                style={{ maxWidth: "100%" }}
                              />
                              <p
                                className={"orange"}
                                style={{ cursor: "pointer" }}
                                onClick={() => setPassageDiagram("")}
                              >
                                {t("delete")}
                              </p>
                            </>
                          ) : (
                            <>
                              <Upload
                                ref={uploadRef}
                                maxCount={1}
                                multiple={false}
                                accept=".jpg,.jpeg"
                                beforeUpload={beforeUpload}
                                customRequest={(data) => uploadImg(data)}
                                showUploadList={false}
                              >
                                <p
                                  className={`${s.uploadText} ${
                                    errors.passageDiagram ? s.error : ""
                                  }`}
                                >
                                  {t("uploadSchema")}
                                </p>
                              </Upload>
                            </>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={s.standRight}
                  style={{
                    width:
                      (!isEditMode && times.times.length > 0) ||
                      isEditMode ||
                      times.times.length > 0
                        ? "85.7%"
                        : "83.3%"
                  }}
                >
                  <div
                    className={s.standDescription}
                    style={{
                      width: "calc(100% - 160px)",
                      maxWidth: isAdmin
                        ? times.times.length > 0
                          ? 1662
                          : 1544
                        : isEditMode
                        ? 1351
                        : times.times.length > 0
                        ? 1354
                        : 1294,
                      paddingLeft: isAdmin ? 54 : 0
                    }}
                  >
                    <Form.Item
                      className={s.formItem}
                      name={"description"}
                      rules={[
                        {
                          required: true,
                          message: ""
                        }
                      ]}
                    >
                      <TextArea
                        showCount
                        autoSize={{
                          minRows: 7,
                          maxRows: 8
                        }}
                        style={{ padding: "24px 16px" }}
                        onChange={(e) => {
                          setErrors((prev) => ({ ...prev, description: false }));
                          clearError("description");
                          OnlyLatinLettersTextInput(
                            e.target.value,
                            "description",
                            form,
                            true,
                            null,
                            null,
                            true
                          );
                        }}
                        className={`${s.TextArea}`}
                        placeholder={t("description")}
                        maxLength={1000}
                      />
                    </Form.Item>
                    <Modal
                      width={680}
                      visible={showErrorModal}
                      onCancel={() => setShowErrorModal(false)}
                      footer={[
                        <Button
                          className={`btn btn_v2`}
                          key={1}
                          onClick={() => setShowErrorModal(false)}
                        >
                          ОК
                        </Button>
                      ]}
                    >
                      <p>{modalText}</p>
                    </Modal>
                  </div>
                  <div
                    className={`${s.controls} ${s.big}`}
                    style={{ width: !isAdmin ? 170 : times.times.length > 0 ? 167 : 172 }}
                  >
                    {!isAdmin && (
                      <div className={s.checkboxes}>
                        <Form.Item
                          name={"isVisible"}
                          valuePropName="checked"
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const isPrivate = getFieldValue("isPrivate");
                                console.log("validator");
                                if (!Boolean(value) && isPrivate) {
                                  setCheckboxError(false);
                                  return Promise.resolve();
                                } else if (Boolean(value) && !isPrivate) {
                                  setCheckboxError(false);
                                  return Promise.resolve();
                                } else if (Boolean(value)) {
                                  setCheckboxError(false);
                                  return Promise.resolve();
                                } else if (exhibitionId) {
                                  setCheckboxError(false);
                                  return Promise.resolve();
                                } else {
                                  return Promise.resolve();
                                  /* return Promise.reject(); */
                                }
                              }
                            })
                          ]}
                        >
                          <Space>
                            <Checkbox
                              className={`small ${
                                isDisabled || checkBoxSelected.isVisible
                                  ? "ant-checkbox-checked"
                                  : ""
                              }`}
                              style={{ borderColor: "red" }}
                              defaultChecked={isDisabled || Boolean(initialValues.isVisible)}
                              checked={checkBoxSelected.isVisible}
                              ariaChecked="isVisible"
                              disabled={isDisabled}
                              onChange={handleIsVisible}
                            />
                            <span>{t("isVisible")}</span>
                            <Tooltip
                              placement="bottomLeft"
                              title={isDisabled ? t("visibility_v2") : t("visibility")}
                            >
                              <QuestionCircleOutlined
                                style={{
                                  position: "relative",
                                  cursor: "help",
                                  alignSelf: "end",
                                  marginBottom: 0,
                                  color: "#474747",
                                  marginLeft: "auto"
                                }}
                              />
                            </Tooltip>
                          </Space>
                        </Form.Item>
                        <Form.Item
                          className={s.formItem}
                          name={"isPrivate"}
                          valuePropName="checked"
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const isVisible = getFieldValue(["isVisible"]);

                                if (!Boolean(value) && isVisible) {
                                  setCheckboxError(false);
                                  return Promise.resolve();
                                } else if (Boolean(value) && !isVisible) {
                                  setCheckboxError(false);
                                  return Promise.resolve();
                                } else if (Boolean(value)) {
                                  setCheckboxError(false);
                                  return Promise.resolve();
                                } else if (exhibitionId) {
                                  setCheckboxError(false);
                                  return Promise.resolve();
                                } else {
                                  return Promise.resolve();
                                  /* setCheckboxError(false);
                                  return Promise.reject(); */
                                }
                              }
                            })
                          ]}
                        >
                          <Space>
                            <Checkbox
                              className={`small ${
                                checkBoxSelected.isPrivate ? "ant-checkbox-checked" : ""
                              }`}
                              style={{ borderColor: "red" }}
                              defaultChecked={initialValues.isPrivate}
                              checked={checkBoxSelected.isPrivate}
                              ariaChecked="isPrivate"
                              disabled={isDisabled}
                              onChange={handleIsPrivate}
                            />
                            <span>{t("isPrivate")}</span>
                            <Tooltip
                              placement="bottomLeft"
                              title={isDisabled ? t("private_v2") : t("private")}
                            >
                              <QuestionCircleOutlined
                                style={{
                                  position: "relative",
                                  cursor: "help",
                                  alignSelf: "end",
                                  marginBottom: 0,
                                  color: "#474747"
                                }}
                              />
                            </Tooltip>
                          </Space>
                        </Form.Item>
                      </div>
                    )}
                    {validateCheckBox && checkboxError && (
                      <p style={{ marginLeft: "20px", marginBottom: "25px", color: "#ff4d4f" }}>
                        {t("visibleStand")}
                      </p>
                    )}
                    <div
                      className={s.actions}
                      style={
                        isAdmin
                          ? {
                              width:
                                times.times.length > 0
                                  ? "136%"
                                  : isAdmin && times.times.length < 0
                                  ? "132%"
                                  : "122%",
                              justifyContent: "center",
                              gap: 20,
                              marginTop: "96%"
                            }
                          : {
                              marginTop: "3%"
                            }
                      }
                    >
                      <div className={s.wrap}>
                        <Tooltip
                          placement="bottomLeft"
                          title={isAdmin ? t("saveTheExhibition") : t("saveAndContinue")}
                        >
                          <button
                            className={s.saveBtn}
                            type={isAdmin ? "button" : "submit"}
                            onClick={() => {
                              if (isAdmin) {
                                form
                                  .validateFields()
                                  .then((data) => {
                                    onFinish(data);
                                  })
                                  .catch(({ errorFields }) => {
                                    handleErrors(errorFields);
                                  });
                              }
                            }}
                          >
                            S
                          </button>
                        </Tooltip>
                        {!isAdmin && (
                          <Tooltip placement="left" title={t("sendModerationBtn")}>
                            <span
                              className={s.moderate}
                              onClick={async () => {
                                const isFree = newExhibitionName
                                  ? await isExhibitionNameFree(newExhibitionName)
                                  : true;
                                setValidateCheckBox(true);
                                form
                                  .validateFields()
                                  .then((data) => {
                                    if (isFree || !isCreating) {
                                      const sendData = normalizeExhibitionData(data);
                                      if (exhibitionNameAnreadyExsist && !isDisabled) {
                                        setModalText(t("infoModal.errorExhibitionAlreadyExist"));
                                        setShowErrorModal(true);
                                      } else if (standAlreadyExsist) {
                                        handleOpenModal(modalTypes.STAND_EXISTS);
                                      } else {
                                        const formValues = form.getFieldsValue();
                                        navigate("/account/exhibition-preview", {
                                          state: {
                                            exhibition: sendData,
                                            isNewExhibition:
                                              exhibitionName === sendData?.exhibitionName,
                                            isPrivate: checkBoxSelected.isPrivate,
                                            isVisible: checkBoxSelected.isVisible,
                                            formValues,
                                            times
                                          }
                                        });
                                      }
                                    } else {
                                      setModalText(t("infoModal.errorExhibitionAlreadyExist"));
                                      setShowErrorModal(true);
                                    }
                                  })
                                  .catch(({ errorFields }) => {
                                    console.log(errorFields);
                                    handleErrors(errorFields);
                                    scrollToFirstInvalidFiled();
                                  });
                              }}
                            >
                              M
                            </span>
                          </Tooltip>
                        )}
                        <Tooltip title={t("clear")}>
                          <span>
                            <Trash
                              stroke={"#222222"}
                              onClick={() => {
                                deleteDraft();
                                clearForm();
                                clearImage();
                                setExhibitionName("");
                                setCheckboxError(false);
                                setValidateCheckBox(false);
                              }}
                            />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

const renderModalContent = ({ modalType, t, handleCloseModal, clearForm, isAdmin }) => {
  const modalContent = {
    UNKNOWN_ERROR: <p style={{ marginTop: 0, width: 475 }}>{t("infoModal.unknownError")}</p>,
    CREATION_SUCCESS: <p style={{ marginTop: 0, width: 290 }}>{t("infoModal.creation_success")}</p>,
    SAVING_SUCCESS: isAdmin ? (
      <div className={s.saved_modal}>
        <h2>{t("infoModal.finishEditingAdmin1")}</h2>
        <p>{t("infoModal.finishEditingAdmin2")}</p>
        <Button className="btn btn_v2" onClick={handleCloseModal}>
          OK
        </Button>
      </div>
    ) : (
      <div className={s.saved_modal}>
        <h3>{t("saved")}</h3>
        <p>{t("instruction")}</p>
        <Button className="btn btn_v2" onClick={handleCloseModal}>
          OK
        </Button>
      </div>
    ),
    FINISH_EDITING: (
      <div className={s.modal_finishModal}>
        <h2>{t("infoModal.finishEditing1")}</h2>
        <p>{t("infoModal.finishEditing2")}</p>
        <Button className="btn btn_v2">{t("infoModal.previewBtn")}</Button>
      </div>
    ),
    EXHIBITION_ALREADY: (
      <div className={s.modal_exist}>
        {/* TODO: styling */}
        <p style={{ marginTop: 10, width: 520 }}>
          {t("infoModal.existAlready1")}
          <Link
            to={"/admin-panel/edit-and-view-exhibition"}
            target="_blank"
            onClick={() => {
              handleCloseModal();
              clearForm();
            }}
            className={s.adminLink}
          >
            {t("infoModal.existAlready2")}
          </Link>
        </p>
      </div>
    ),
    WRONG_NEW_EXHIBITION_OR_EXHIBITION_ID: "WRONG_NEW_EXHIBITION_OR_EXHIBITION_ID",
    STAND_EXISTS: (
      <div className={s.modal_exist}>
        <p style={{ marginTop: 0, width: 520 }}>{t("infoModal.standExhist")}</p>
        <Button key="ok" className="btn btn_v2" onClick={handleCloseModal}>
          OK
        </Button>
      </div>
    ),
    NOT_FOUND_EXHIBITION: "NOT_FOUND_EXHIBITION",
    NO_CORRECT_TIMES: "NO_CORRECT_TIMES",
    isPrivate: (
      <div className={s.isPrivate}>
        <h3>{t("dearUser", { ns: "FA52A-Moderation-changes" })}</h3>
        <p>{t("isPrivate_modal.text1")}</p>
        <p>{t("isPrivate_modal.text2")}</p>
        {!isAdmin && (
          <p>
            {t("isPrivate_modal.text3")}{" "}
            <Link to={"/account/make-complaint"} state={{ isExhibition: true }}>
              {t("isPrivate_modal.link")}
            </Link>{" "}
          </p>
        )}
        <Button key="ok" className="btn btn_v2" onClick={handleCloseModal}>
          OK
        </Button>
      </div>
    ),
    isArchive: (
      <div className={s.isPrivate}>
        <p style={{ marginTop: 0, width: 520, fontWeight: 500 }}>{t("isArchive_modal")}</p>
        <Button key="ok" className="btn btn_v2" onClick={handleCloseModal}>
          OK
        </Button>
      </div>
    ),
    onModeration: (
      <div className={s.isPrivate}>
        <p style={{ marginTop: 0, width: 575, fontWeight: 500 }}>{t("onModeration")}</p>
        <Button key="ok" className="btn btn_v2" onClick={handleCloseModal}>
          OK
        </Button>
      </div>
    )
  };

  return modalContent[modalType] || null;
};
CreateExhibitionPanel.propTypes = {
  num: PropTypes.number,
  showCreateExhibitionPanel: PropTypes.bool,
  fetchData: PropTypes.func,
  closePanel: PropTypes.func
};
export default React.memo(CreateExhibitionPanel);
