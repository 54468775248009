import React, { useState, useRef, useEffect } from "react";
import { Cascader, Divider } from "antd";
import { useTranslation } from "react-i18next";
import Checkbox from "antd/lib/checkbox/Checkbox";
import PropTypes from "prop-types";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";

import styles from "./CategoriesSelect.module.less";
import Close from "../../assets/svg/Close";

const CategoriesSelect = ({
  currentSubId,
  setCurrentSubId,
  node,
  translate,
  categories,
  translateTitle,
  isValidate
}) => {
  const [currentCategory, setCurrentCategory] = useState("");
  const ref = useRef(null);

  const list = document.querySelector(".ant-cascader-menu:nth-child(2)");
  const [open, setOpen] = useState(false);

  const { t } = useTranslation([translate]);

  useEffect(() => {
    if (list) {
      list.scrollTop = 0;
    }
  }, [currentCategory]);

  const foundElements = categories.reduce((accumulator, category) => {
    const filteredSubcategories = category.subcategories
      .filter((subCategory) => currentSubId.includes(subCategory.id))
      .sort((a, b) => {
        return currentSubId.indexOf(a.id) - currentSubId.indexOf(b.id);
      });
    const mappedSubcategories = filteredSubcategories.map((obj) => {
      return {
        label: translateTitle
          ? t(`${translateTitle}.${category.type}.${obj.type}`)
          : t(`${category.type}.${obj.type}`),
        id: obj.id
      };
    });
    return accumulator.concat(mappedSubcategories);
  }, []);

  const combinedArray = foundElements.map(({ label, id }) => {
    return { label: label, id: id };
  });

  const newArray = categories.map((item) => {
    const count = item.subcategories.filter((value) => currentSubId.includes(value.id)).length;
    return {
      label: (
        <div className={styles.labelContainer} onClick={() => handleClickCategory(item.type)}>
          <div className={styles.square}>
            <span className={styles.plus}>+</span>
          </div>
          <span style={{ width: "300px", wordWrap: "break-word" }}>
            {translateTitle ? t(`${translateTitle}.${item.type}.label`) : t(`${item.type}.label`)}
          </span>
          <span
            className={`${styles.currentLenthCategories} ${count && styles.currentSubcategories} ${
              currentSubId.length === 3 && count === 0 && styles.disabled
            }`}
          >
            {count} / {item.subcategories.length}
          </span>
        </div>
      ),
      value: item.id,
      children: [
        {
          label: (
            <p className={styles.currentCategory}>
              {translateTitle
                ? t(`${translateTitle}.${currentCategory}.label`)
                : t(`${currentCategory}.label`)}
            </p>
          ),
          value: "value",
          disabled: false
        },
        ...item.subcategories.map((subItem) => {
          const isVisibleCheckobox = currentSubId.includes(subItem.id);
          return {
            label: (
              <span className={styles.wrapperCheckbox} style={{ display: "flex", gap: "15px" }}>
                <Checkbox
                  disabled={!isVisibleCheckobox && currentSubId.length === 3}
                  checked={isVisibleCheckobox}
                />
                <span style={{ width: "300px", wordWrap: "break-word" }}>
                  {translateTitle
                    ? t(`${translateTitle}.${item.type}.${subItem.type}`)
                    : t(`${item.type}.${subItem.type}`)}
                </span>
              </span>
            ),
            value: subItem.id,
            disabled: !isVisibleCheckobox && currentSubId.length === 3
          };
        })
      ]
    };
  });

  const handleCascaderChange = (sub, event) => {
    const lastElement = sub[sub.length - 1];
    const foundItem = newArray
      .map((item) => item.children.find((child) => child.value === lastElement))
      .filter((elem) => elem);
    if (currentSubId.includes(foundItem[0].value)) {
      setCurrentSubId(currentSubId.filter((item) => item !== foundItem[0].value));
    } else {
      setCurrentSubId([...currentSubId, foundItem[0].value]);
    }
    event.stopPropagation();
  };

  const handleDropdownVisibleChange = () => {
    setOpen((prev) => !prev);
  };

  const handleClickCategory = (str) => {
    setCurrentCategory(str);
  };

  const handleCloseButtonClick = () => {
    if (ref.current) {
      ref.current.blur();
    }
  };

  const handleClickDeleteButton = (id, label) => {
    setCurrentSubId(currentSubId.filter((value) => value !== id));
  };

  const dropdownRender = (menus) => (
    <div className={styles.dropdownRenderContainer}>
      <div className={styles.dropdownRender}>
        <div className={styles.leftContent}>
          <p className={styles.dropdownTitle}>{t("title")}</p>
          <span className={styles.selectedLenth}>{currentSubId.length}/3</span>
        </div>
        <div onClick={handleCloseButtonClick} className={styles.cross}>
          <div className={styles.horizontalLine}></div>
          <div className={styles.verticalLine}></div>
        </div>
      </div>
      <Divider style={{ marginBottom: 5, marginTop: 0 }} />
      {menus}
    </div>
  );

  return (
    <div id="test" className={styles.Cascader}>
      <Cascader
        options={newArray}
        ref={ref}
        style={{ border: !isValidate ? "none" : "1px solid rgb(255,	77, 79)", borderRadius: "2px" }}
        getPopupContainer={() => (node ? node.current : document.getElementById("root"))}
        dropdownRender={dropdownRender}
        suffixIcon={<DropdownIcon onClick={handleDropdownVisibleChange} />}
        open={open}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        value={combinedArray.length ? combinedArray[0].label : []}
        onChange={(value, event) => {
          handleCascaderChange(value, event);
        }}
      />
      {isValidate ? <p style={{ color: "rgb(255,	77, 79)" }}>{t("title")}</p> : null}
      <ul className={styles.subcategoryList}>
        {combinedArray.length
          ? combinedArray.slice(1).map(({ id, label }) => (
              <li title={label} key={id} className={styles.subcategoryItem}>
                <span className={styles.subcategoryText}>{label}</span>
                <button
                  className={styles.buttonDelete}
                  onClick={() => handleClickDeleteButton(id, label)}
                >
                  <Close />
                </button>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default CategoriesSelect;

CategoriesSelect.propTypes = {
  currentSubId: PropTypes.array,
  setCurrentSubId: PropTypes.func,
  node: PropTypes.object,
  translate: PropTypes.string,
  categories: PropTypes.array,
  translateTitle: PropTypes.string
};
