import React, { createContext, useState, useContext, useCallback } from 'react';

const ColorContext = createContext();

export const useColor = () => useContext(ColorContext);

export const ColorProvider = ({ children }) => {
  const [haspMap, setHaspMap] = useState({});
  const DetailsColors = [
    "#FF3F42",
    "#249F5D",
    "#676AFF",
    "#FF72F6",
    "#50BDAB",
    "#D67C2D",
    "#343543",
    "#6DBAD6",
    "#2C0BE3",
    "#249F5D" // Повторяем цвет, чтобы не было проблем, если вдруг последний цвет использовался
  ];

  const chooseColor = useCallback((exhibitionName) => {
    if (haspMap[exhibitionName]) {
      return haspMap[exhibitionName];
    }

    // Вычисляем хеш на основе имени
    let hash = 0;
    for (let i = 0; i < exhibitionName.length; i++) {
      hash = exhibitionName.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Получаем индекс в пределах массива цветов
    const index = Math.abs(hash) % DetailsColors.length;
    const color = DetailsColors[index];

    // Обновляем haspMap
    setHaspMap(prevHaspMap => ({ ...prevHaspMap, [exhibitionName]: color }));

    return color;
  }, []);

  const value = { chooseColor };

  return (
    <ColorContext.Provider value={value}>
      {children}
    </ColorContext.Provider>
  );
};