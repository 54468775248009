import { DoubleRightOutlined } from '@ant-design/icons';
import { Space, Table, Typography } from 'antd';
import { dialogueApi } from 'Api';
import Pin from 'Assets/svg/Pin.svg';
import { makeColumn, showTime, sortHandler } from 'Helpers/tableHelpers';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import s from './DialogsTable.module.less';

const DialogsTable = ({ pagination, dataSource, loading, setSorting, fetchData }) => {
  const { t } = useTranslation("FS19-Dialogs");

  const pinDialogues = useCallback(
    (roomId) => {
      dialogueApi
        .pinDialogues(roomId)
        .then((res) => {
          fetchData();
        })
        .catch(console.log);
    },
    [fetchData]
  );

  const unpinDialogues = useCallback(
    (roomId) => {
      dialogueApi
        .unpinDialogues(roomId)
        .then((res) => {
          fetchData();
        })
        .catch(console.log);
    },
    [fetchData]
  );

  const columns = [
    {
      ...makeColumn(t, "company", 200, "companyName"),
      render: (company, { pinned, isAdmin }) => (
        <Space size={10}>
          {pinned && <img src={Pin} style={{ width: 19, color: "#EA5B0C" }} />}
          <Typography.Text ellipsis>{isAdmin ? t("support") : company}</Typography.Text>
        </Space>
      )
    },
    {
      ...makeColumn(t, "messageTime", 150, "lastMessage"),
      render: showTime
    },
    makeColumn(t, "allMessages", 125),
    {
      ...makeColumn(t, "newMessages", 125, "newMessages"),
      render: (newMessages) => (
        <span style={newMessages > 0 ? { color: "#EA5B0C", fontWeight: 500 } : null}>
          {newMessages}
        </span>
      )
    },
    {
      ...makeColumn(null, "toDialog", 170),
      render: (toDialog, { isAdmin, key }) => (
        <Link
          to={`/account/dialogs/${isAdmin ? key : toDialog}`}
          state={{ roomId: key, chatWithAdmin: isAdmin }}
          className={s.toDialog}
        >
          <DoubleRightOutlined style={{ fontSize: 16, color: "#EA5B0C" }} />
          {t("toDialog")}
        </Link>
      )
    },
    {
      ...makeColumn(null, "pinned", 170),
      render: (pinned, { key }) => (
        <Space size="middle">
          <a className={s.link} onClick={(pinned ? unpinDialogues : pinDialogues).bind(null, key)}>
            {t(pinned ? "unpinDialog" : "pinDialog")}
          </a>
        </Space>
      )
    }
  ];

  return (
    <section className={s.section}>
      <Table
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        onChange={sortHandler(setSorting)}
        rowClassName={({ newMessages }) => newMessages && "selected"}
      />
    </section>
  );
};

DialogsTable.propTypes = {
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  dataSource: PropTypes.array,
  fetchData: PropTypes.func,
  setSorting: PropTypes.func
};

export default DialogsTable;
