import React, {useEffect, useRef, useState} from 'react';
import s from '../../FK2.1-productPage/ProductPage.module.less';
import {Modal} from 'antd';
import {CloseOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons';
import {mediaUrl} from '../../../api/api';
import {convertStringToLinks} from '../../../helpers/convertStringToLinks';
import YouTube from 'react-youtube';
import {useTranslation} from 'react-i18next';

const PreviewDetailItemProduct = ({item}) => {

  const { t } = useTranslation([
    "FK2.1-productPage",
    "DeliveryTime",
    "FK3-сompanyProfile",
    "FS10-AddNewProduct"
  ]);

  const [mainPhoto, setMainPhoto] = useState("");
  const [photoArr, setPhotoArr] = useState([]);

  const [openSlider, setOpenSlider] = useState(false);
  const [sliderPhoto, setSliderPhoto] = useState(null);
  const [sliderArr, setSliderArr] = useState([]);

  const small = useRef();

  useEffect(() => {
    if (item) {
      setMainPhoto(item.image);
      setSliderArr( [...item.productPhotos.map((m) => m.photo), item.image]);
      setPhotoArr(item.productPhotos)
    }
  }, [item])

  const handlerScrollRight = () => {
    small.current.scrollBy(130, 0);
  };
  const handlerScrollLeft = () => {
    small.current.scrollBy(-130, 0);
  };

  useEffect(() => {
    if (item && mainPhoto && photoArr) {
      if (
        mainPhoto !== (item && item.image) &&
        !photoArr.filter((f) => f.photo === item.image).length
      ) {
        setPhotoArr([...photoArr, { photo: item.image }]);
      }
    }
  }, [item, mainPhoto]);

  const photoHandler = (num) => {
    if (num === 1) {
      if (sliderPhoto > 0) {
        setSliderPhoto(sliderPhoto - 1);
      } else {
        setSliderPhoto(sliderArr.length - 1);
      }
    } else if (num === 2) {
      if (sliderPhoto === sliderArr.length - 1) {
        setSliderPhoto(0);
      } else {
        setSliderPhoto(sliderPhoto + 1);
      }
    }
  };

  return (
    <div style={{ display: "flex", marginBottom: '20px' }} className={s.mainWrapper}>
      <Modal
        className={s.modal}
        visible={openSlider}
        onOk={() => setOpenSlider(false)}
        onCancel={() => setOpenSlider(false)}
        footer={null}
        closeIcon={<CloseOutlined style={{ fontSize: "30px", color: "#fff" }} />}
      >
        <div
          style={sliderArr.length > 1 ? {} : { visibility: "hidden" }}
          className={s.prev}
          onClick={() => photoHandler(1)}
        >
          <LeftOutlined className={s.arrow} />
        </div>
        <img className={s.imgModal} src={item && `${mediaUrl}${sliderArr[sliderPhoto]}`} alt="" />
        <div
          style={sliderArr.length > 1 ? {} : { visibility: "hidden" }}
          className={s.next}
          onClick={() => photoHandler(2)}
        >
          <RightOutlined className={s.arrow} />
        </div>
      </Modal>
      <div className={s.main}>
        <div style={{ display: "flex" }}>
          <div>
            <div className={s.imgWrapper}>
              <img
                onClick={() => {
                  setOpenSlider(true);
                  setSliderPhoto(sliderArr.indexOf(mainPhoto));
                }}
                className={s.image}
                src={item && `${mediaUrl}${mainPhoto}`}
                alt=""
              />
            </div>
            <div style={{ position: "relative" }}>
              <div className={s.smallImgWrapper} ref={small}>
                {photoArr &&
                photoArr.map((item, index) => (
                  <img
                    key={index}
                    className={s.smallImage}
                    src={item && `${mediaUrl}${item.photo}`}
                    alt="photo"
                    onClick={() => setMainPhoto(item.photo)}
                  />
                ))}
              </div>
              {photoArr.length > 3 && (
                <div className={s.arrowLeft} onClick={handlerScrollLeft}>
                  <LeftOutlined />
                </div>
              )}
              {photoArr.length > 3 && (
                <div className={s.arrowRight} onClick={handlerScrollRight}>
                  <RightOutlined />
                </div>
              )}
            </div>
          </div>
          <div style={{ margin: "0 32px 32px 32px" }}>
            <div className={s.title}>{item && item.productName}</div>
            <div className={s.subTitle}>{t("unitPrice")}</div>
            <div className={s.subValue}>
              {item && item.unitPrice / 100} {item && item.currency.name}
            </div>
            <div className={s.subTitle}>{t("minimumOrder")}</div>
            <div className={s.subValue}>
              {item && item.minimalAmount} {t("amount")}
            </div>
            <div className={s.subTitle}>{t("paymentMethod")}</div>
            <div className={s.paymentMethod}>
              {item &&
              item.paymentMethods.map((m) => (
                <div key={m.id} className={s.subValue}>
                  {m.name}
                </div>
              ))}
            </div>

            <div className={s.subTitle}>{t("certificates")}</div>
            <div className={s.subValue}>
              {item && convertStringToLinks(item.availableCertificates)}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <div className={s.title}>{t("description")}</div>
          <div className={s.text}>{item && item.description}</div>
          {item.advantage1 && item.advantage2 && item.advantage3 ? (
            <>
              <div className={s.title} style={{ marginTop: "32px" }}>
                {t("competitiveAdvantages")}
              </div>
              <ul>
                {item.advantage1 && <li className={s.advantage}>{item && item.advantage1}</li>}
                {item.advantage2 && <li className={s.advantage}>{item && item.advantage2}</li>}
                {item.advantage3 && <li className={s.advantage}>{item && item.advantage3}</li>}
              </ul>
            </>
          ) : null}
        </div>

        <div style={{ marginTop: "32px", width: "500px" }}>
          <div style={{ display: "flex" }}>
            <div className={s.wrap} style={{ marginRight: "60px" }}>
              <div>
                <div className={s.subTitle}>{t("productionCapacity")}</div>
                <div>{item && item.productiveCapacity}</div>
              </div>
              <div>
                <div className={s.subTitle}>{t("incoterms")}</div>
                <div className={s.paymentMethod}>
                  {item &&
                  item.incoterms.map((item, index) => (
                    <div key={index}>
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={s.wrap}>
              <div>
                <div className={s.subTitle}>{t("deliveryTime")}</div>
                <div>{item && t(item.deliveryTime.name, { ns: "DeliveryTime" })}</div>
              </div>
              <div>
                <div className={s.subTitle}>{t("package")}</div>
                <div>{item && t(item.deliveryUnit.name)}</div>
              </div>
            </div>
          </div>

          {item.video !== "" && item.video !== null ? (
            <div style={{ marginTop: "50px", position: "relative", zIndex: 2 }}>
              <div className={s.title}>{t("productVideo")}</div>
              {(() => {
                let regExp =
                  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                let match = item && item.video.match(regExp);
                let videoId = match && match[7].length == 11 ? match[7] : false;

                if (item && item.video.indexOf("youtube") > 0) {
                  return <YouTube videoId={videoId} style={{ width: "390px", height: "260px" }} />;
                } else if (item && item.video.indexOf("youtu.be") > 0) {
                  return <YouTube videoId={videoId} style={{ width: "390px", height: "260px" }} />;
                } else {
                  return (
                    item && (
                      <video
                        src={`${mediaUrl}${item.video}`}
                        title="video"
                        controls="controls"
                        style={{
                          width: "max-content",
                          height: "max-content",
                          maxHeight: "260px",
                          maxWidth: "390px"
                        }}
                      />
                    )
                  );
                }
              })()}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PreviewDetailItemProduct;
