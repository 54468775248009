export function convertQueryParameters(parameters) {
  const queryParams = [];
  if (parameters) {
    for (let key in parameters) {
      if (parameters[key] && parameters[key]?.length !==0) {
        queryParams.push(`&${String(key)}=${parameters[key].join(',')}`);
      }
    }
  }
  return queryParams.length ? queryParams.join('') : '';
}
