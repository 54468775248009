import React, { useEffect, useState } from "react";
import { countryGroup } from "../../pages/FS8-changeCompanyProfile/CoyntryGroupId";
import { Form, Select } from "antd";
import s from "../../pages/FS8-changeCompanyProfile/ChangeCompanyProfile.module.less";
import Check from "../../assets/svg/Check";

const MainMarkets = ({ market, t, countriesArr, setValue }) => {
  const { Option } = Select;
  const [activeCountryGroup, setActiveCountryGroup] = useState({
    ES: false,
    AFRICA: false,
    NORTH_AMERICA: false,
    SOUTH_AMERICA: false,
    NEAR_EAST: false,
    CIS: false,
    AUSTRALIA_AND_NEW_ZEALAND: false,
    THE_WHOLE_WORLD: false
  });

  console.log(market, "market");

  useEffect(() => {
    let obj = {};
    for (let key in countryGroup) {
      if (market && countryGroup[key].every((elem) => market?.includes(elem))) {
        obj[key] = true;
      }
    }
    const mergedObj = Object.assign({}, activeCountryGroup, obj);
    for (const key in mergedObj) {
      if (!obj.hasOwnProperty(key)) {
        mergedObj[key] = false;
      }
    }
    setActiveCountryGroup(mergedObj);
  }, [market]);

  const select5 = (value) => {
    const lastIndex = value[value?.length - 1];
    if (typeof lastIndex === "string") {
      if (!activeCountryGroup[lastIndex]) {
        setActiveCountryGroup({ ...activeCountryGroup, [value]: true });
        value.splice(value.indexOf(countryGroup[lastIndex]));
        setValue(value.concat(countryGroup[lastIndex].filter((item) => value.indexOf(item) < 0)));
      } else {
        setActiveCountryGroup({ ...activeCountryGroup, [value]: false });
        value.splice(value.indexOf(countryGroup[lastIndex]));
        setValue(value.filter((item) => !countryGroup[lastIndex].includes(item)));
      }
    } else setValue(value);
  };

  const activeSelectStyle = {
    padding: "5px 12px",
    backgroundColor: "#e6f7ff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 600
  };
  const selectStyle = { padding: "5px 12px" };

  return (
    <Form.Item
      name="mainMarkets"
      className={s.countrySelectWrapper}
      rules={[
        {
          required: true,
          message: t("mainMarketError", { ns: "FS8-changeCompanyProfile" })
        }
      ]}
    >
      <Select
        style={{ maxWidth: "600px" }}
        mode="multiple"
        placeholder={t("selectPlaceholder", { ns: "FS8-changeCompanyProfile" })}
        onChange={select5}
        allowClear
        showSearch
        optionFilterProp="children"
      >
        <Option value={"THE_WHOLE_WORLD"} style={{ padding: 0 }}>
          <div style={activeCountryGroup.THE_WHOLE_WORLD ? activeSelectStyle : selectStyle}>
            <div>{t("TheWholeWorld", { ns: "FS8-changeCompanyProfile" })}</div>
            {activeCountryGroup.THE_WHOLE_WORLD && <Check />}
          </div>
        </Option>

        <Option value={"ES"} style={{ padding: 0 }}>
          <div style={activeCountryGroup.ES ? activeSelectStyle : selectStyle}>
            <div>{t("Eu", { ns: "FS8-changeCompanyProfile" })}</div>
            {activeCountryGroup.ES && <Check />}
          </div>
        </Option>

        <Option value={"AFRICA"} style={{ padding: 0 }}>
          <div style={activeCountryGroup.AFRICA ? activeSelectStyle : selectStyle}>
            <div>{t("Africa", { ns: "FS8-changeCompanyProfile" })}</div>
            {activeCountryGroup.AFRICA && <Check />}
          </div>
        </Option>

        <Option value={"NORTH_AMERICA"} style={{ padding: 0 }}>
          <div style={activeCountryGroup.NORTH_AMERICA ? activeSelectStyle : selectStyle}>
            <div>{t("NAmerica", { ns: "FS8-changeCompanyProfile" })}</div>
            {activeCountryGroup.NORTH_AMERICA && <Check />}
          </div>
        </Option>

        <Option value={"SOUTH_AMERICA"} style={{ padding: 0 }}>
          <div style={activeCountryGroup.SOUTH_AMERICA ? activeSelectStyle : selectStyle}>
            <div>{t("SAmerica", { ns: "FS8-changeCompanyProfile" })}</div>
            {activeCountryGroup.SOUTH_AMERICA && <Check />}
          </div>
        </Option>

        <Option value={"NEAR_EAST"} style={{ padding: 0 }}>
          <div style={activeCountryGroup.NEAR_EAST ? activeSelectStyle : selectStyle}>
            <div>{t("NearEast", { ns: "FS8-changeCompanyProfile" })}</div>
            {activeCountryGroup.NEAR_EAST && <Check />}
          </div>
        </Option>

        <Option value={"CIS"} style={{ padding: 0 }}>
          <div style={activeCountryGroup.CIS ? activeSelectStyle : selectStyle}>
            <div>{t("Cis", { ns: "FS8-changeCompanyProfile" })}</div>
            {activeCountryGroup.CIS && <Check />}
          </div>
        </Option>

        <Option value={"AUSTRALIA_AND_NEW_ZEALAND"} style={{ padding: 0 }}>
          <div
            style={activeCountryGroup.AUSTRALIA_AND_NEW_ZEALAND ? activeSelectStyle : selectStyle}
          >
            <div>{t("AustraliaZealand", { ns: "FS8-changeCompanyProfile" })}</div>
            {activeCountryGroup.AUSTRALIA_AND_NEW_ZEALAND && <Check />}
          </div>
        </Option>

        <Option disabled value={"divider"} style={{ padding: 0, cursor: "default" }}>
          <div className={s.line} />
        </Option>
        {countriesArr
          .slice()
          .sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          })
          .map((m) => (
            <Option key={`${m.id}_markets`} value={m.id}>
              {m.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default MainMarkets;
