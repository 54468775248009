import { useNavigate } from "react-router-dom";

import BlackArrow from "../../assets/svg/BlackArrow";
import PropTypes from "prop-types";
import styles from "./GoBackHeader.module.less";
import { useTranslation } from "react-i18next";

export const GoBackHeader = ({ text, companyId }) => {
  const { t } = useTranslation("GoBackHeader");
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className={styles.container}>
        <div onClick={() => handleGoBack()} className={styles.button}>
          <BlackArrow color="#EA5B0C" className={styles.arrow} />
          <p className={`${styles.goBackText} orange`}>{text}</p>
        </div>
      </div>
    </>
  );
};

GoBackHeader.propTypes = {
  text: PropTypes.string,
  companyId: PropTypes.number
};
