
import { createSlice } from "@reduxjs/toolkit";
import { settingsAPI } from "../api/api";
import { setIsLoading } from "./authSlice";

const initialState = {
  settings: {
    request: false,
    meeting: false,
    changeMeeting: false,
    message: false,
    m10: false,
    m30: false,
    h1: false,
    h2: false,
    h5: false,
    h10: false,
    d1: false,
    d3: false,
    requestOnPlatform: true,
    meetingOnPlatform: true,
    changeMeetingOnPlatform: true,
    messageOnPlatform: true,
    m10OnPlatform: false,
    m30OnPlatform: false,
    h1OnPlatform: false,
    h2OnPlatform: false,
    h5OnPlatform: false,
    h10OnPlatform: false,
    d1OnPlatform: false,
    d3OnPlatform: false
  }
};

const settingSlice = createSlice({

  name: "settings",
  initialState,
  reducers: {
    setSettings(state, action) {
      state.settings = action.payload;
    }
  }
});

export const { setSettings } = settingSlice.actions;
export default settingSlice.reducer;

export const fetchSettings = () => (dispatch) => {
  dispatch(setIsLoading(true));
  settingsAPI
    .getSettings()
    .then((res) => {
      dispatch(setSettings(res.data.settings));
    })
    .catch((e) => {
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};
export const updateSettings =
  (
    request,
    meeting,
    changeMeeting,
    message,
    m10,
    m30,
    h1,
    h2,
    h5,
    h10,
    d1,
    d3,
    requestOnPlatform,
    meetingOnPlatform,
    changeMeetingOnPlatform,
    messageOnPlatform,
    m10OnPlatform,
    m30OnPlatform,
    h1OnPlatform,
    h2OnPlatform,
    h5OnPlatform,
    h10OnPlatform,
    d1OnPlatform,
    d3OnPlatform,
    setModalIsVisible,
    setSaveError
  ) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    settingsAPI
      .changeSettings(
        request,
        meeting,
        changeMeeting,
        message,
        m10,
        m30,
        h1,
        h2,
        h5,
        h10,
        d1,
        d3,
        requestOnPlatform,
        meetingOnPlatform,
        changeMeetingOnPlatform,
        messageOnPlatform,
        m10OnPlatform,
        m30OnPlatform,
        h1OnPlatform,
        h2OnPlatform,
        h5OnPlatform,
        h10OnPlatform,
        d1OnPlatform,
        d3OnPlatform
      )
      .then((res) => {
        dispatch(
          setSettings({
            request,
            meeting,
            changeMeeting,
            message,
            m10,
            m30,
            h1,
            h2,
            h5,
            h10,
            d1,
            d3,
            requestOnPlatform,
            meetingOnPlatform,
            changeMeetingOnPlatform,
            messageOnPlatform,
            m10OnPlatform,
            m30OnPlatform,
            h1OnPlatform,
            h2OnPlatform,
            h5OnPlatform,
            h10OnPlatform,
            d1OnPlatform,
            d3OnPlatform
          })
        );
      })
      .catch((e) => {
        setSaveError(true);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
        setModalIsVisible(true);
      });
  };

