import { Col } from "antd";
import React from "react";
import { profileAPI } from "../api/api";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useProductServiceInfo = (admin = false, companyId) => {
  const { t } = useTranslation(["ProductServiceInfo"]);
  const [total, setTotal] = useState(0);
  const [free, setFree] = useState(0);
  const [count, setCount] = useState(0);
  const [infinite, setInfinite] = useState(false)
  const getInfo = () => {
    profileAPI
      .getProductsServicesInfo(admin ? companyId : "")
      .then((res) => {
        setTotal(res.data.total);
        setFree(res.data.free);
        setCount(res.data.count);
        setInfinite(res.data.infinite)
      })
      .catch((e) => {
      });
  };
  const InfoVisualisation = () => {
    return (
      <Col span={12} className="mb-18">
          <div className="info_table">
              <p>
                  {t("total", { ns: "ProductServiceInfo" })} <span>{infinite ? t("noLimit", { ns: "ProductServiceInfo" }) : total}</span>
              </p>
              <p>
                  {t("count", { ns: "ProductServiceInfo" })}
                  <span>{count}</span>
              </p>
              <p>
                  {t("free", { ns: "ProductServiceInfo" })}
                  <span>{infinite ? t("noLimit", { ns: "ProductServiceInfo" }) : free}</span>
              </p>
          </div>
      </Col>
    );
  };
  useEffect(() => {
    getInfo();
  }, []);
  return { getInfo, InfoVisualisation };
};
export default useProductServiceInfo;
