/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./ExhibitionTable.module.less";
import Pagination from "../../../components/Pagination/Pagination";
import { fetchCompanyExhibitionStand } from "../../../store/companySlice";
import ExhibitionTableItem from "./ExhibitionTableItem";
import { useTranslation } from "react-i18next";

const ExhibitionTable = ({ companyId, user, children, fk2, slice, link }) => {
  const { t } = useTranslation("FK3-сompanyProfile");
  const pageSize = 10;
  const dispatch = useDispatch();
  const { totalExhibition, exhibition } = useSelector((state) => state.company);
  const [page, setPage] = useState(1);
  const [fullData, setFullData] = useState(false);
  const { isAdmin } = useSelector((state) => state.auth);

  useEffect(() => {
    if (companyId) dispatch(fetchCompanyExhibitionStand(companyId, pageSize, page, isAdmin));
  }, [page, companyId, isAdmin]);

  const sliceData = () => {
    if (slice && !fullData) return exhibition.slice(0, 2);
    else return exhibition;
  };

  return (
    <>
      {exhibition?.length ? children : null}
      <div className={s.section} style={{ overflow: 'visible' }}>
        {sliceData().map((item, index) => (
          <ExhibitionTableItem
            user={user}
            companyId={companyId}
            key={index}
            index={(index + 1) * page}
            item={item}
            t={t}
            link={link}
          />
        ))}
      </div>
      {totalExhibition > 2 && (fullData || !slice) && (
        <Pagination
          pageSize={pageSize}
          current={page}
          setCurrent={(num) => setPage(num)}
          total={totalExhibition}
          style={{
            margin: "32px auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        />
      )}
      {slice && !fullData && exhibition.length > 2 && (
        <div className={s.full} onClick={() => setFullData(true)}>
          {fk2("exhibition.4", { field: exhibition.length - 2 })}
        </div>
      )}
    </>
  );
};

export default ExhibitionTable;
