import { createSlice } from "@reduxjs/toolkit";
import { resourcesAPI } from "Api/api";
import { feedbackAPI, matchmakingAPI } from "../api/api";

const initialState = {
  productCategories: [],
  productSubcategories: [],
  serviceCategories: [],
  serviceRegions: [],
  currencies: [],
  deliveryUnits: [],
  deliveryTime: [],
  paymentMethods: [],
  incoterms: [],
  countries: [],
  businessType: [],
  complaintTopics: [],
  technicalTopic: [],
  complaintStatuses: [],
  annualTurnoverTypes: [],
  numberEmployeesTypes: [],
  percentageTurnoverTypes: [],
  allMeetingStatuses: [],
  allTranslationStatuses: [],
  allFeedbackMainStatuses: [],
  requestsWithNoMatchStatuses: [],
  matchmakingStatuses: [],
  cities: []
};
const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {
    setProductCategories(state, action) {
      state.productCategories = action.payload;
    },
    setServiceCategories(state, action) {
      state.serviceCategories = action.payload;
    },
    setServiceRegions(state, action) {
      state.serviceRegions = action.payload;
    },
    setProductSubcategories(state, action) {
      state.productSubcategories = action.payload;
    },
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
    setDeliveryUnits(state, action) {
      state.deliveryUnits = action.payload;
    },
    setDeliveryTime(state, action) {
      state.deliveryTime = action.payload;
    },
    setPaymentMethods(state, action) {
      state.paymentMethods = action.payload;
    },
    setIncoterms(state, action) {
      state.incoterms = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setBusinessType(state, action) {
      state.businessType = action.payload;
    },
    setComplaintTopics(state, action) {
      state.complaintTopics = action.payload;
    },
    setTechnicalTopics(state, action) {
      state.technicalTopic = action.payload;
    },
    setAllRoles(state, action) {
      state.roles = action.payload;
    },
    setAllComplaintStatuses(state, action) {
      state.complaintStatuses = action.payload;
    },
    setAnnualTurnoverTypes(state, action) {
      state.annualTurnoverTypes = action.payload;
    },
    setNumberEmployeesTypes(state, action) {
      state.numberEmployeesTypes = action.payload;
    },
    setPercentageTurnoverTypes(state, action) {
      state.percentageTurnoverTypes = action.payload;
    },
    setAllMeetingsStatuses(state, action) {
      state.allMeetingStatuses = action.payload;
    },
    setAllTranslationStatuses(state, action) {
      state.allTranslationStatuses = action.payload;
    },
    setAllFeedbackMainStatuses(state, action) {
      state.allFeedbackMainStatuses = action.payload;
    },
    setRequestsWithNoMatchStatuses(state, action) {
      state.requestsWithNoMatchStatuses = action.payload;
    },
    setMatchMakingStatuses(state, action) {
      state.matchmakingStatuses = action.payload;
    },
    setCities(state, action) {
      state.cities = action.payload;
    }
  }
});
export const {
  setProductCategories,
  setProductSubcategories,
  setServiceCategories,
  setCurrencies,
  setDeliveryUnits,
  setDeliveryTime,
  setPaymentMethods,
  setIncoterms,
  setCountries,
  setServiceRegions,
  setBusinessType,
  setComplaintTopics,
  setTechnicalTopics,
  setAllRoles,
  setAllComplaintStatuses,
  setAnnualTurnoverTypes,
  setNumberEmployeesTypes,
  setPercentageTurnoverTypes,
  setAllMeetingsStatuses,
  setAllTranslationStatuses,
  setAllFeedbackMainStatuses,
  setRequestsWithNoMatchStatuses,
  setMatchMakingStatuses,
  setCities
} = resourcesSlice.actions;

export const getProductCategories = () => (dispatch) => {
  resourcesAPI
    .getProductCategories()
    .then((res) => {
      dispatch(setProductCategories(res.data));
    })
    .catch((e) => {});
};
export const getProductSubCategories = () => (dispatch) => {
  resourcesAPI
    .getProductSubCategories()
    .then((res) => {
      dispatch(setProductSubcategories(res.data));
    })
    .catch((e) => {});
};
export const getServiceCategories = () => (dispatch) => {
  resourcesAPI
    .getServiceCategories()
    .then((res) => {
      dispatch(setServiceCategories(res.data));
    })
    .catch((e) => {});
};

export const getServiceRegions = () => (dispatch) => {
  resourcesAPI
    .getServiceRegions()
    .then((res) => {
      dispatch(setServiceRegions(res.data));
    })
    .catch((e) => {});
};

export const getCurrencies = () => (dispatch) => {
  resourcesAPI
    .getCurrencies()
    .then((res) => {
      dispatch(setCurrencies(res.data));
    })
    .catch((e) => {});
};

export const getDeliveryUnits = () => (dispatch) => {
  resourcesAPI
    .getDeliveryUnits()
    .then((res) => {
      dispatch(setDeliveryUnits(res.data));
    })
    .catch((e) => {});
};

export const getDeliveryTime = () => (dispatch) => {
  resourcesAPI
    .getDeliveryTime()
    .then((res) => {
      dispatch(setDeliveryTime(res.data));
    })
    .catch((e) => {});
};

export const getPaymentMethods = () => (dispatch) => {
  resourcesAPI
    .getPaymentMethods()
    .then((res) => {
      dispatch(setPaymentMethods(res.data));
    })
    .catch((e) => {});
};

export const getIncoterms = () => (dispatch) => {
  resourcesAPI
    .getIncoterms()
    .then((res) => {
      dispatch(setIncoterms(res.data));
    })
    .catch((e) => {});
};

export const getCountries = () => (dispatch) => {
  resourcesAPI
    .getCountries()
    .then((res) => {
      dispatch(setCountries(res.data));
    })
    .catch((e) => {});
};

export const fetchBusinessType = () => (dispatch) => {
  resourcesAPI
    .getBusinessType()
    .then((res) => {
      dispatch(setBusinessType(res.data));
    })
    .catch((e) => {});
};

export const getComplaintTopics = () => (dispatch) => {
  resourcesAPI
    .getComplaintTopics()
    .then((res) => {
      dispatch(setComplaintTopics(res.data));
    })
    .catch((e) => {});
};

export const getTechnicalTopics = () => (dispatch) => {
  resourcesAPI
    .getTechnicalTopics()
    .then((res) => {
      dispatch(setTechnicalTopics(res.data));
    })
    .catch((e) => {});
};

export const getAllRoles = () => (dispatch) => {
  resourcesAPI.getAllRoles().then((res) => {
    dispatch(setAllRoles(res.data));
  });
};

export const getAllComplaintStatuses = () => (dispatch) => {
  resourcesAPI.getAllComplaintStatuses().then((res) => {
    dispatch(setAllComplaintStatuses(res.data));
  });
};

export const getAnnualTurnoverTypes = () => (dispatch) => {
  resourcesAPI.getAnnualTurnoverTypes().then((res) => {
    dispatch(setAnnualTurnoverTypes(res.data));
  });
};

export const getCities = () => (dispatch) => {
  console.log("resps");
  resourcesAPI.getCities().then((res) => {
    dispatch(setCities(res.data.cities));
  });
};

export const getNumberEmployeesTypes = () => (dispatch) => {
  resourcesAPI.getNumberEmployeesTypes().then((res) => {
    dispatch(setNumberEmployeesTypes(res.data));
  });
};

export const getPercentageTurnoverTypes = () => (dispatch) => {
  resourcesAPI.getPercentageTurnoverTypes().then((res) => {
    dispatch(setPercentageTurnoverTypes(res.data));
  });
};

export const getAllMeetingsStatuses = () => (dispatch) => {
  resourcesAPI.getAllMeetingsStatuses().then((res) => {
    dispatch(setAllMeetingsStatuses(res.data));
  });
};

export const getAllTranslationStatuses = () => (dispatch) => {
  resourcesAPI.getAllTranslationsStatuses().then((res) => {
    dispatch(setAllTranslationStatuses(res.data));
  });
};

export const getAllFeedbackMainStatuses = () => (dispatch) => {
  resourcesAPI.getAllFeedbackMainStatuses().then((res) => {
    dispatch(setAllFeedbackMainStatuses(res.data));
  });
};

export const getRequestsWithNoMatchStatuses = () => (dispatch) => {
  feedbackAPI.getFeedBackStatuses().then((res) => {
    dispatch(setRequestsWithNoMatchStatuses(res.data));
  });
};

export const getMatchMakingStatuses = () => (dispatch) => {
  matchmakingAPI.getMatchMakingStatuses().then((res) => {
    dispatch(setMatchMakingStatuses(res.data));
  });
};
export default resourcesSlice.reducer;
