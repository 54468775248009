import { createSlice } from "@reduxjs/toolkit";
import { serviceAPI } from "../api/api";
import { setIsLoading } from "./authSlice";

export const initialCurrentService = {
  advantage1: "",
  advantage2: "",
  advantage3: "",
  availableCertificates: "",
  companyId: 0,
  createdAt: "",
  description: "",
  id: 0,
  image: "",
  promotedService: null,
  promotedServiceId: null,
  rangeId: undefined,
  serviceKeywords: [],
  serviceName: "",
  servicePhotos: [],
  serviceRange: { id: undefined },
  subcategories: [{ id: undefined }],
  updatedAt: "",
  video: "",
  visible: true,
  moderationStatus: {id: 3, name: "ACCEPTED"}
};
const initialState = {
  currentService: initialCurrentService,
  services: {
    items: [],
    total: 0
  }
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServices(state, action) {
      state.services = action.payload;
    },
    setCurrentService(state, action) {
      state.currentService = action.payload;
    }
  }
});

export const { setServices, setCurrentService } = servicesSlice.actions;

export const getServices =
  (limit, page, subcategoryId, sortColumn, sortDirection, companyId, withModerations = false) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    serviceAPI
      .getServices(
        limit,
        page,
        subcategoryId,
        sortColumn,
        sortDirection,
        companyId,
        withModerations
      )
      .then((res) => {
        dispatch(setServices(res.data));
      })
      .catch((e) => {
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };
export const getServiceById =
  (id, withModerations = false, withEdits = false) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    serviceAPI
      .getServiceById(id, withModerations, withEdits)
      .then((res) => {
        dispatch(setCurrentService(res.data));
      })
      .catch((e) => {
      })
      .finally(() => dispatch(setIsLoading(false)));
  };
export default servicesSlice.reducer;
