/* eslint-disable react/prop-types */
import { Button, DatePicker, Divider, Input, Select, Space } from "antd";
import { memo, useEffect, useState, useRef } from "react";

import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import PropTypes from "prop-types";
import axios from "axios";
import dayjs from "dayjs";
import s from "./FilterTableSearch.module.less";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const dateFormat = "DD.MM.YYYY";

const FilterTableSearch = ({
  className,
  filters,
  setFilters,
  initialFilters,
  submitHandler,
  changePageSize,
  pageSize,
  translate,
  onlyPageSize = false,
  node,
  clearExhibitions = null,
  resetCurrentPage = null,
  refContainer,
  id
}) => {
  const initialState = filters.reduce(
    (prevState, filter) => ({ ...prevState, [filter.title]: false }),
    {}
  );
  const { t, i18n } = useTranslation(["FS19-Dialogs", "FA9-RequestsList"]);
  const [openState, setOpenState] = useState(initialState);
  const [pageSizeShow, setPageSizeShow] = useState(false);

  const onReset = () => {
    setFilters(initialFilters);
    if (clearExhibitions) clearExhibitions();
  };

  const onSearch = async (value, request, withoutValue = false, params = "") => {
    const request2 = request.split("?")[1];
    if ((typeof value == "undefined" || !value.trim().length) && !withoutValue) {
      const updatedFilters = filters.map((filter) => {
        if (filter.request === request) {
          filter.items = [];
          return filter;
        }
        return filter;
      });
      return setFilters(updatedFilters);
    }
    const fetch = await axios.get(request + value + params);
    const updatedFilters = filters.map((filter) => {
      if (filter.request === request) {
        const updatedItems = { ...filter, items: [...fetch.data] };
        return updatedItems;
      }
      return filter;
    });

    return setFilters(updatedFilters);
  };

  const onTextSelectChange = (value, title) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.title === title) {
        const updatedValue = { ...filter, selectedValue: value };
        return updatedValue;
      }
      return filter;
    });

    setFilters(updatedFilters);
  };

  const convertDate = (d) => {
    const day = dayjs(d).format("YYYY-MM-DD");
    return `${day}`;
  };

  if (onlyPageSize) {
    return (
      <div id={id} className={`${s.controls} ${s.only_pagesize}`}>
        {!!pageSize && (
          <div className={s.controls__select}>
            {t("showpageSize", { ns: "FA9-RequestsList" })}
            <Select
              getPopupContainer={() => refContainer.current}
              open={pageSizeShow}
              defaultValue={pageSize}
              onChange={(value) => changePageSize({ target: { value } })}
              onDropdownVisibleChange={(state) => {
                setPageSizeShow(state);
              }}
              suffixIcon={
                <DropdownIcon
                  onClick={() => {
                    setPageSizeShow((prev) => !prev);
                  }}
                />
              }
            >
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
            {translate ? translate("pageSize") : t("pageSize", { ns: "FA9-RequestsList" })}
          </div>
        )}
      </div>
    );
  }

  return (
    <section className={`${s.section} ${className}`}>
      <h3 style={{ marginBottom: 24 }}>
        {t("filter_table_search_title", { ns: "FA9-RequestsList" })}
      </h3>
      <div className={s.filters}>
        {filters.map((filter, i) => {
          if (filter.notFilter || filter?.hide) {
            return "";
          }
          // filter.filterType === 'search' ========================================
          if (filter.filterType === "search") {
            return (
              <label key={filter?.title}>
                {initialFilters[i]?.title}
                <Select
                  getPopupContainer={() => (node ? node.current : document.getElementById("root"))}
                  value={filter.selectedValue}
                  mode={filter.single ? "" : "multiple"}
                  className="selection22"
                  style={{
                    width: 160,
                    maxHeight: 32
                  }}
                  placeholder={t("all")}
                  onDropdownVisibleChange={(state) => {
                    if (filter.customSearch) {
                      onSearch("", filter.request, true);
                    }
                    setOpenState({ ...initialState, [filter.title]: state });
                  }}
                  onChange={(value, option) => {
                    if (filter.onChange) {
                      filter.onChange(value);
                    }
                    if (filter.onChange && option.length > 0) {
                      filter.onChange(option?.[0]?.data);
                    }
                    onTextSelectChange(value, filter.title);
                  }}
                  showSearch={false}
                  clearIcon={false}
                  showArrow
                  open={openState[filter.title]}
                  suffixIcon={
                    <DropdownIcon
                      onClick={() =>
                        setOpenState({ ...initialState, [filter.title]: !openState[filter.title] })
                      }
                    />
                  }
                  dropdownRender={(menu) => (
                    <>
                      <Space align="center" style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder={t("search", { ns: "FS19-Dialogs" })}
                          value={filter.searchValue}
                          allowClear
                          onChange={(e) => {
                            if (!filter.customSearch) {
                              onSearch(e.target.value, filter.request, false, filter.params);
                            } else {
                              filter.customSearch({
                                filterValue: e.target.value,
                                filteringValue: filter.filteringValue
                                  ? filter.filteringValue
                                  : "name",
                                items: filter.items,
                                getData: onSearch("", filter.request, true),
                                request: filter.request,
                                fieldtranslate: filter.fieldtranslate ? filter.fieldtranslate : ""
                              });
                            }
                          }}
                        />
                      </Space>
                      <Divider style={{ margin: "8px 0" }} />
                      {menu}
                    </>
                  )}
                >
                  {filter.items.map((item) => {
                    return (
                      <Option
                        key={`${item.id}_${item.status || item.name}`}
                        value={
                          typeof filter.dataField !== "undefined" ? item[filter.dataField] : item.id
                        }
                        data={item}
                      >
                        {filter.viewValue
                          ? filter.fieldtranslate
                            ? filter.valueInViewValue
                              ? filter.fieldtranslate(
                                  item[filter.viewValue][filter.valueInViewValue]
                                )
                              : filter.fieldtranslate(item[filter.viewValue])
                            : filter.valueInViewValue
                            ? item[filter.viewValue][filter.valueInViewValue]
                            : item[filter.viewValue] || item[filter.secondViewValue]
                          : item.companyName}
                      </Option>
                    );
                  })}
                </Select>
              </label>
            );
          }
          // filter.filterType === 'text' ========================================
          else if (filter.filterType === "text") {
            return (
              <label key={filter.title}>
                {initialFilters[i].title}
                <Select
                  getPopupContainer={() => (node ? node.current : document.getElementById("root"))}
                  value={filter.selectedValue}
                  mode={filter.single ? "" : "multiple"}
                  style={{
                    width: 160,
                    maxHeight: 32
                  }}
                  placeholder={t("all")}
                  notFoundContent={"Loading..."}
                  onDropdownVisibleChange={(state) => {
                    if (filter.request.length) {
                      onSearch("", filter.request, true);
                    }

                    setOpenState({ ...initialState, [filter.title]: state });
                  }}
                  onChange={(value) => {
                    if (filter.onChange) {
                      filter.onChange(value);
                    }
                    onTextSelectChange(value, filter.title);
                  }}
                  showSearch={false}
                  clearIcon={false}
                  showArrow
                  open={openState[filter.title]}
                  suffixIcon={
                    <DropdownIcon
                      onClick={() =>
                        setOpenState({ ...initialState, [filter.title]: !openState[filter.title] })
                      }
                    />
                  }
                >
                  {filter.items.map((item) => {
                    return (
                      <Option
                        key={`${item.id}_${item.status || item.name}`}
                        value={
                          typeof filter.dataField !== "undefined" ? item[filter.dataField] : item.id
                        }
                      >
                        {filter.t
                          ? filter.viewValue
                            ? filter.valueInViewValue
                              ? filter.t(item[filter.viewValue][filter.valueInViewValue])
                              : filter.t(item[filter.viewValue])
                            : filter.t(`${item.status || item.name}`)
                          : item.status || item.name}
                      </Option>
                    );
                  })}
                </Select>
              </label>
            );
          }
          // filter.filterType === 'date' ========================================
          else if (filter.filterType === "date") {
            return (
              <label key={filter.title}>
                {initialFilters[i].title}
                <DatePicker
                  getPopupContainer={() => (node ? node.current : document.getElementById("root"))}
                  style={{ width: 140, height: 32 }}
                  format={dateFormat}
                  placeholder={t("all")}
                  placement="bottomRight"
                  showToday={false}
                  onChange={(date) => {
                    if (date) {
                      setFilters(
                        filters.map((filterItem) =>
                          filterItem.title === filter.title
                            ? { ...filterItem, selectedValue: date, dateData: convertDate(date.$d) }
                            : filterItem
                        )
                      );
                    } else {
                      setFilters(
                        filters.map((filterItem) =>
                          filterItem.title === filter.title
                            ? { ...filterItem, selectedValue: "", dateData: "" }
                            : filterItem
                        )
                      );
                    }
                  }}
                  value={filter.selectedValue}
                />
              </label>
            );
          }
        })}
      </div>
      <div className={s.controls}>
        {pageSize ? (
          <div className={s.controls__select}>
            {t("showpageSize", { ns: "FA9-RequestsList" })}
            <Select
              getPopupContainer={() => (node ? node.current : document.getElementById("root"))}
              open={pageSizeShow}
              defaultValue={pageSize}
              onChange={(value) => changePageSize({ target: { value } })}
              onDropdownVisibleChange={(state) => {
                setPageSizeShow(state);
              }}
              suffixIcon={
                <DropdownIcon
                  onClick={() => {
                    setPageSizeShow((prev) => !prev);
                  }}
                />
              }
            >
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={25}>25</Select.Option>
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
            </Select>
            {translate ? translate("pageSize") : t("pageSize", { ns: "FA9-RequestsList" })}
          </div>
        ) : (
          ""
        )}
        <Button className={s.reset} onClick={onReset}>
          {t("reset")}
        </Button>
        <Button
          className={`btn btn_v2 ${s.show}`}
          onClick={() => {
            submitHandler();
            if (resetCurrentPage) resetCurrentPage();
          }}
        >
          {t("display")}
        </Button>
      </div>
    </section>
  );
};

FilterTableSearch.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
  initialFilters: PropTypes.array,
  submitHandler: PropTypes.func,
  changePageSize: PropTypes.func,
  pageSize: PropTypes.number,
  translate: PropTypes.func,
  onlyPageSize: PropTypes.bool
};

export default FilterTableSearch;
