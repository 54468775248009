import { Button, Form, Input } from "antd";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import InfoBlock from "../InfoBlok/InfoBlock";
import Loader from "../Loader/Loader";
import PropTypes from "prop-types";
import { communicationHistoryAPI } from "../../api/api";
import s from "./CommentsWindow.module.less";
import { setIsLoading } from "../../store/authSlice";
import { useTranslation } from "react-i18next";

const BUYER = "Buyer";
const SELLER = "Seller";
const USER = "User";
const COMPLAINTSENDER = "Sender";
const COMPLAINTOBJ = "ProblemObject";
const PROBLEMOBJECT = "ProblemSender";
const CommentsWindow = ({ id, entityType, side, userType = BUYER }) => {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation([
    "CommentsWindow",
    "FA28-ComplaintProcess",
    "FA36.1-TechProblemProcess",
    "lastContact"
  ]);
  const [historyItems, setHistoryItems] = useState([]);
  const [fetchOffsest, setFetchOffset] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [lastEl, setLastEl] = useState();
  const {
    user
  } = useSelector((state) => state.auth);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const historyWindowRef = ref.current;
    if (historyItems.length && scrollToBottom) {
      historyWindowRef.scrollTop = historyWindowRef.scrollHeight;
      setScrollToBottom(false);
    } else if (lastEl) {
      historyWindowRef.scrollTop = lastEl.offsetTop;
    }
  }, [historyItems]);

  useEffect(() => {
    if (fetching) {
      communicationHistoryAPI
        .getHistory(entityType, id, fetchOffsest, 10, side)
        .then((res) => {
          setHistoryItems((prev) => [...res.data.items, ...prev]);
          setTotalCount(res.data.total);
        })
        .catch((e) => console.log(e.response))
        .finally(() => setFetching(false));
    }
  }, [fetching]);
  const historyScroll = (e) => {
    if (e.target.scrollTop === 0 && historyItems.length < totalCount) {
      setLastEl(e.target.querySelector("div"));
      setFetching(true);
    }
  };

  useEffect(() => {
    const historyWindowRef = ref.current;
    historyWindowRef.addEventListener("scroll", historyScroll);
    setFetchOffset(historyItems.length);
    return () => {
      historyWindowRef.removeEventListener("scroll", historyScroll);
    };
  }, [historyItems]);
  const submitOnEnter = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      form.submit();
    }
  };
  const addCommentSubmit = (values) => {
    const historyWindowRef = ref.current;
    setLastEl(null);
    const { comment } = values;
    dispatch(setIsLoading(true));
    if (
      historyWindowRef.scrollTop + historyWindowRef.clientHeight ===
        historyWindowRef.scrollHeight ||
      historyWindowRef.scrollHeight -
        (historyWindowRef.scrollTop + historyWindowRef.clientHeight) <=
        50
    ) {
      setScrollToBottom(true);
    }

    communicationHistoryAPI
      .addCommentToHistory(id, entityType, user.email, comment, side)
      .then((res) => {
        setHistoryItems((prev) => [...prev, res.data]);
        form.setFieldsValue({ comment: undefined });
        inputRef.current.focus({ cursor: "start" });
      })
      .catch((e) => console.log(e.response))
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };
  return (
    <>
      <p className={`${s.title}`}>
        <span className={` fw-500 fz-17`}>
          {t("userInteractionHistory", {
            ns: "CommentsWindow"
          })}
          {userType === BUYER
            ? t("withBuyer", {
                ns: "CommentsWindow"
              })
            : userType === SELLER
            ? t("withSeller", {
                ns: "CommentsWindow"
              })
            : userType === USER
            ? t("withUser", { ns: "CommentsWindow" })
            : userType === COMPLAINTOBJ
            ? t("historyObject", { ns: "FA28-ComplaintProcess" })
            : userType === COMPLAINTSENDER
            ? t("historySender", { ns: "FA28-ComplaintProcess" })
            : userType === PROBLEMOBJECT
            ? t("historySender", { ns: "FA36.1-TechProblemProcess" })
            : ""}
        </span>
      </p>
      <div className={`${s.block} customScroll mt-18 pr-5`} ref={ref}>
        {fetching && <Loader />}
        {historyItems.length ? historyItems.map((el) => <InfoBlock el={el} key={el.id} />) : ""}
      </div>
      <div className="mt-30">
        <p className={`${s.title} fw-500`}>{t("addComment", { ns: "CommentsWindow" })}</p>
        <Form
          form={form}
          className="mt-16"
          name="addCommentForm"
          validateTrigger="onSubmit"
          onFinish={addCommentSubmit}
        >
          <Form.Item
            name="comment"
            rules={[
              {
                validator: (_, value) => {
                  if ((value && value.trim().length === 0) || !value) {
                    return Promise.reject();
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            ]}
          >
            <Input.TextArea
              style={{ height: "102px" }}
              ref={inputRef}
              onPressEnter={submitOnEnter}
              onChange={() => {
                form.setFields([{ name: "comment", errors: [] }]);
              }}
            />
          </Form.Item>
          <Form.Item noStyle>
            <Button className={`submit btn btn_v2`} type="default" htmlType="submit" size="large">
              {t("add", { ns: "CommentsWindow" })}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
CommentsWindow.propTypes = {
  id: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired,
  side: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userType: PropTypes.oneOf([BUYER, SELLER, COMPLAINTOBJ, COMPLAINTSENDER, PROBLEMOBJECT, USER])
};
export default CommentsWindow;
