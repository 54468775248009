import {Calendar, Coins, People, Planer} from '../../assets/svg/KeyIndicators';
import React, {useEffect, useMemo} from 'react';

import BusinessTypeItem from '../FK3-сompanyProfile/About/BusinessTypeItem/BusinessTypeItem';
import KeyIndicators from '../FK3-сompanyProfile/About/KeyIndicators/KeyIndicators';
import Map from '../FK3-сompanyProfile/Map/Map';
import PlugItem from '../FS9-showcaseSelection/PlugItem';
import YouTube from 'react-youtube';
import {mediaUrl} from '../../api/api';
import s from '../FK3-сompanyProfile/About/About.module.less';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {HelperYoutubeSize} from "../FK3-сompanyProfile/About/About";


const PreviewAbout = ({profile, videoLink}) => {
  const {t} = useTranslation(['FK3-сompanyProfile', 'FS8-changeCompanyProfile']);
  const navigate = useNavigate();
  useEffect(() => {
    if (profile.profile.id === null) {
      navigate('/account/change-company-profile');
    }
  }, []);

  const businessTypeNormalize = useMemo(() => profile.profile.businessTypes.map(m => typeof m === 'number' ? m : m.id), [profile]);

  const {annualTurnoverTypes, numberEmployeesTypes, percentageTurnoverTypes} = useSelector(
    (state) => state.changeCompanyProfile.resources,
  );
  const image = useMemo(() => profile.customProfile.images.filter((f) => f && f), []);
  const keyIndicator = [
    {
      svg: <People/>,
      title: t('KeyIndicators.description1'),
      value:
        profile.profile.numberEmployees !== null && profile.profile.numberEmployees !== undefined &&
        (typeof profile.profile.numberEmployees === 'number'
          ? numberEmployeesTypes[profile.profile.numberEmployees - 1].name
          : profile.profile.numberEmployees.name),
    },
    {
      svg: <Coins/>,
      title: t('KeyIndicators.description2'),
      value:
        profile.profile.annualTurnover !== null && profile.profile.annualTurnover !== undefined &&
        (typeof profile.profile.annualTurnover === 'number'
          ? annualTurnoverTypes[profile.profile.annualTurnover - 1].name
          : profile.profile.annualTurnover.name),
    },
    {
      svg: <Planer/>,
      title: t('KeyIndicators.description3'),
      value:
        profile.profile.percentageExportsTurnover !== null && profile.profile.percentageExportsTurnover !== undefined &&
        (typeof profile.profile.percentageExportsTurnover === 'number'
          ? percentageTurnoverTypes[profile.profile.percentageExportsTurnover - 1].name
          : profile.profile.percentageExportsTurnover.name),
    },
    {
      svg: <Calendar/>,
      title: t('KeyIndicators.description4'),
      value: profile.profile.yearFoundation && profile.profile.yearFoundation,
    },
    {
      svg: <Planer/>,
      title: t('KeyIndicators.description5'),
      value:
        profile.profile.percentageImportsTurnover !== null && profile.profile.percentageImportsTurnover !== undefined &&
        (typeof profile.profile.percentageImportsTurnover === 'number'
          ? percentageTurnoverTypes[profile.profile.percentageImportsTurnover - 1].name
          : profile.profile.percentageImportsTurnover.name),
    },
    {
      svg: <People/>,
      title: t('membership1', {ns: 'FS8-changeCompanyProfile'}),
      value: profile.profile.membershipAssociations && profile.profile.membershipAssociations,
    },
  ];

  return (
    <div style={{marginTop: '20px'}}>
      <div className={s.section}>
        <div className={s.title}>{t('about')}</div>
        <div className={s.text}>{profile.profile.description}</div>
        <div className={s.text} style={{display: 'flex'}}>
          <div style={{textDecoration: 'underline'}}>{t('keyWords')}</div>
          {profile.profile.companyKeywords?.length ? (
            typeof profile.profile.companyKeywords === 'object' &&
            profile.profile.companyKeywords !== null &&
            profile.profile.companyKeywords.length ? (
              profile.profile.companyKeywords.map((item, index) => (
                <div key={index} className={s.teg}>
                  {item.keyword} {index + 1 === profile.profile.companyKeywords?.length ? '' : ','}
                </div>
              ))
            ) : (
              profile.profile.companyKeywords.split(' ').map((item, index) => (
                <div key={index} className={s.teg}>
                  {item.trim().replace(/[\s.,%]/g, ', ')}
                </div>
              ))
            )
          ) : (
            <div/>
          )}
        </div>
      </div>

      {profile.customProfile.showcase.length ? <div className={s.section}>
        <div className={s.title}>{t('productsAndService')}</div>
        <div className={s.productItem}>
          {profile.customProfile.showcase.map((item, index) => (
            <PlugItem key={index} id={index} full/>
          ))}
        </div>
      </div> : null}

      <div className={s.section}>
        <div className={s.title}>{t('businessType.title')}</div>
        <div className={s.productItem}>
          {businessTypeNormalize.map((item, index) => (
            <BusinessTypeItem preview={true} t={t} id={item} key={index}/>
          ))}
        </div>
      </div>

      {keyIndicator.filter(f => f.value !== false && f.value !== '' && f.value !== null).length ? <div className={s.section}>
        <div className={s.title}>{t('KeyIndicators.title')}</div>
        <div
          className={s.indicator}
        >
          {keyIndicator.map((item, index) => (
            <KeyIndicators key={index} title={item.title} svg={item.svg} value={item.value}/>
          ))}
        </div>
      </div> : null}

      <div className={s.section}>
        <div className={s.title}>{t('tradingCompanies')}</div>
        <Map profile={profile.profile} bool={false}/>
      </div>

      <div className={s.section}>
        {!!profile.customProfile.files.filter(f =>  f.filename  !== '').length || !!image.length || profile.profile.video === null || profile.profile.video !== ''? <div className={s.title}>{t('additionalMaterials')}</div> : null}

        {profile.profile.video !== null && profile.profile.video !== '' ? (
          <div>
            <div className={s.text} style={{fontSize: '18px'}}>
              {t('video')}
            </div>
            {(() => {
              let regExp =
                /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
              let match = videoLink.match(regExp);
              let videoId = match && match[7].length == 11 ? match[7] : false;

              if (videoLink && videoLink.includes('youtube')) {
                return <YouTube  videoId={videoId} onReady={HelperYoutubeSize}/>;
              } else if (videoLink && videoLink.includes('youtu.be')) {
                return <YouTube  videoId={videoId} onReady={HelperYoutubeSize}/>;
              } else {
                return (
                  <video
                    src={`${mediaUrl}${videoLink}`}
                    title="video"
                    controls="controls"
                    style={{width: 'max-content', height: 'max-content', maxHeight: '260px', maxWidth: '390px'}}

                  />
                );
              }
            })()}
          </div>
        ) : null}

        {image.length? <div>
          <div className={s.text} style={{fontSize: '18px', marginTop: '32px'}}>
            {t('image')}
          </div>
          <div
            className={s.imgWrapper}
            style={image.length === 4 ? {justifyContent: 'space-between'} : {}}
          >
            {image.map((item, index) => (
              <img
                style={{
                  width: '300px',
                  height: '180px',
                  marginBottom: '30px',
                  objectFit: 'contain',
                  marginRight: image.length === 4 ? '0' : '30px',
                }}
                key={index}
                src={`${mediaUrl}${item}`}
                alt="img"
              />
            ))}
          </div>
        </div> : null}

        {profile.customProfile.files.filter(f =>  f.filename  !== '').length? <div>
          <div className={s.text} style={{fontSize: '18px', marginTop: '32px'}}>
            {t('additionalFiles')}
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {profile.customProfile.files.map((item, index) =>
              item.filename ? (
                <div><a href={`${mediaUrl}${item.filename.split('|')[0]}`} key={index} className={s.link}>
                  {index + 1}. <span style={{textDecoration: 'underline'}}>{item.filename.split('|')[1]}</span>
                </a> - <span style={{fontSize: '16px'}}>{item.description}</span></div>

              ) : null,
            )}
          </div>
        </div> : null}
      </div>
    </div>
  );
};

PreviewAbout.propTypes = {
  profile: {},
};

export default PreviewAbout;
