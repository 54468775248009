export const defaultFilters = {
  companiesIds: [],
  countriesIds: [],
  describeStatusIds: [],
  exhibitionsIds: [],
  itemName: []
};

export const ALLOWED_STATUS_IDS = [1, 2, 3, 5, 10];

export const defaultDataCompany = {
    companyName: "",
    country: {
      name: ""
    },
    annualTurnover: {
      name: ""
    },
    website: "",
    companyKeywords: [""],
    numberEmployees: "",
    countryId: ""
  };
