/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import s from "./AddUser.module.less";
import { Select } from "antd";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Check from "../../assets/svg/Check";
import { fetchCountries } from "../../store/changeCompanyProfileSlice";
import { countryGroup } from "../../pages/FS8-changeCompanyProfile/CoyntryGroupId";

const MainMarkets = ({ options, mainMarkets, setMainMarkets }) => {
  const { t } = useTranslation("FA2.1-AddUser", "FS8-changeCompanyProfile");
  const { countries } = useSelector((state) => state.changeCompanyProfile.resources);
  const dispatch = useDispatch();

  const { Option } = Select;

  useEffect(() => {
    let obj = {};
    for (let key in countryGroup) {
      if (mainMarkets && countryGroup[key].every((elem) => mainMarkets?.includes(elem))) {
        obj[key] = true;
      }
    }
    const mergedObj = Object.assign({}, activeCountryGroup, obj);
    for (const key in mergedObj) {
      if (!obj.hasOwnProperty(key)) {
        mergedObj[key] = false;
      }
    }
    setActiveCountryGroup(mergedObj);
  }, [mainMarkets]);

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  const [activeCountryGroup, setActiveCountryGroup] = useState({
    ES: false,
    AFRICA: false,
    NORTH_AMERICA: false,
    SOUTH_AMERICA: false,
    NEAR_EAST: false,
    CIS: false,
    AUSTRALIA_AND_NEW_ZEALAND: false,
    THE_WHOLE_WORLD: false
  });

  function handleChange(value) {
    const lastIndex = value[value?.length - 1];

    if (!value.length) {
      setMainMarkets([]);
    }

    if (typeof lastIndex === "string") {
      if (!activeCountryGroup[lastIndex]) {
        setActiveCountryGroup({ ...activeCountryGroup, [lastIndex]: true });
        setMainMarkets((prevValue) => [...prevValue, ...countryGroup[lastIndex]]);
        console.log(countryGroup[lastIndex]);
      } else {
        setActiveCountryGroup({ ...activeCountryGroup, [lastIndex]: false });
        setMainMarkets(
          value.slice(0, value.length - 1).filter((elem) => !countryGroup[lastIndex].includes(elem))
        );
      }
    } else {
      if (!value.includes(lastIndex)) {
        setMainMarkets((prevValue) => [...prevValue, lastIndex]);
      } else {
        setMainMarkets(value.slice(value.indexOf(countryGroup[lastIndex]) + 1, value.length));
      }
    }
  }

  const countriesArr = useMemo(
    () =>
      countries
        .map((f) => ({
          ...f,
          name: t(f.name, { ns: "Countries" })
        }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    [t, countries]
  );

  const activeSelectStyle = {
    padding: "5px 12px",
    backgroundColor: "#e6f7ff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 600
  };
  const selectStyle = { padding: "5px 12px" };

  return (
    <section>
      <h2 className={s.title} style={{ marginBottom: 10 }}>
        {t("mainMarkets")}*
      </h2>
      <p className={s.help} style={{ marginBottom: 10 }}>
        {t("mainMarketsChoose")}
      </p>

      <Form.Item
        rules={[
          {
            required: true,
            validator: (rule, value) =>
              ![...new Set(mainMarkets.filter((item) => item !== undefined))].length
                ? Promise.reject(t("mainMarketError", { ns: "FS8-changeCompanyProfile" }))
                : Promise.resolve()
          }
        ]}
        name="mainMarkets"
        className={s.countrySelectWrapper}
      >
        <Select
          style={{ maxWidth: "600px" }}
          mode="multiple"
          placeholder={t("selectPlaceholder", { ns: "FS8-changeCompanyProfile" })}
          onChange={(value) => handleChange(value)}
          onClear={() => handleChange([])}
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) =>
            typeof option.children === "string" &&
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={[...new Set(mainMarkets.filter((item) => item !== undefined))]}
        >
          <Option value={"THE_WHOLE_WORLD"} style={{ padding: 0 }}>
            <div style={activeCountryGroup.THE_WHOLE_WORLD ? activeSelectStyle : selectStyle}>
              <div>{t("TheWholeWorld", { ns: "FS8-changeCompanyProfile" })}</div>
              {activeCountryGroup.THE_WHOLE_WORLD && <Check />}
            </div>
          </Option>

          <Option value={"ES"} style={{ padding: 0 }}>
            <div style={activeCountryGroup.ES ? activeSelectStyle : selectStyle}>
              <div>{t("Eu", { ns: "FS8-changeCompanyProfile" })}</div>
              {activeCountryGroup.ES && <Check />}
            </div>
          </Option>

          <Option value={"AFRICA"} style={{ padding: 0 }}>
            <div style={activeCountryGroup.AFRICA ? activeSelectStyle : selectStyle}>
              <div>{t("Africa", { ns: "FS8-changeCompanyProfile" })}</div>
              {activeCountryGroup.AFRICA && <Check />}
            </div>
          </Option>

          <Option value={"NORTH_AMERICA"} style={{ padding: 0 }}>
            <div style={activeCountryGroup.NORTH_AMERICA ? activeSelectStyle : selectStyle}>
              <div>{t("NAmerica", { ns: "FS8-changeCompanyProfile" })}</div>
              {activeCountryGroup.NORTH_AMERICA && <Check />}
            </div>
          </Option>

          <Option value={"SOUTH_AMERICA"} style={{ padding: 0 }}>
            <div style={activeCountryGroup.SOUTH_AMERICA ? activeSelectStyle : selectStyle}>
              <div>{t("SAmerica", { ns: "FS8-changeCompanyProfile" })}</div>
              {activeCountryGroup.SOUTH_AMERICA && <Check />}
            </div>
          </Option>

          <Option value={"NEAR_EAST"} style={{ padding: 0 }}>
            <div style={activeCountryGroup.NEAR_EAST ? activeSelectStyle : selectStyle}>
              <div>{t("NearEast", { ns: "FS8-changeCompanyProfile" })}</div>
              {activeCountryGroup.NEAR_EAST && <Check />}
            </div>
          </Option>

          <Option value={"CIS"} style={{ padding: 0 }}>
            <div style={activeCountryGroup.CIS ? activeSelectStyle : selectStyle}>
              <div>{t("Cis", { ns: "FS8-changeCompanyProfile" })}</div>
              {activeCountryGroup.CIS && <Check />}
            </div>
          </Option>

          <Option value={"AUSTRALIA_AND_NEW_ZEALAND"} style={{ padding: 0 }}>
            <div
              style={activeCountryGroup.AUSTRALIA_AND_NEW_ZEALAND ? activeSelectStyle : selectStyle}
            >
              <div>{t("AustraliaZealand", { ns: "FS8-changeCompanyProfile" })}</div>
              {activeCountryGroup.AUSTRALIA_AND_NEW_ZEALAND && <Check />}
            </div>
          </Option>

          <Option disabled style={{ padding: 0, cursor: "default" }}>
            <div className={s.line} />
          </Option>
          {countriesArr
            .slice()
            .sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
            .map((m) => (
              <Option key={`${m.id}_markets`} value={m.id}>
                {m.name}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </section>
  );
};

export default MainMarkets;
