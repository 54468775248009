import Pagination from "Components/Pagination/Pagination.js";
import PropTypes from "prop-types";
import { Table } from "antd";
import { memo } from "react";
import s from "./FilterTable.module.less";

// response,

const FilterTable = ({
  pageSize,
  current,
  columns,
  data,
  total,
  onChange,
  tableChange = () => {},
  style = {},
  downloadingPdf,
  tableRef
}) => {
  return (
    <>
      <section className={s.section} style={{ ...style }}>
        {downloadingPdf ? (
          <table className={s.section} style={{ ...style }} ref={tableRef}>
            <Table
              className={s.section__table}
              columns={columns}
              dataSource={data}
              pagination={{
                current,
                pageSize
              }}
              onChange={tableChange}
            />
          </table>
        ) : (
          <Table
            className={s.section__table}
            columns={columns}
            dataSource={data}
            pagination={{
              current,
              pageSize
            }}
            onChange={tableChange}
          />
        )}
      </section>
      <Pagination
        current={current}
        pageSize={pageSize}
        setCurrent={onChange}
        total={total}
        style={{ justifyContent: "center" }}
      />
    </>
  );
};
FilterTable.propTypes = {
  pageSize: PropTypes.number,
  current: PropTypes.number,
  columns: PropTypes.any,
  data: PropTypes.array,
  total: PropTypes.number,
  onChange: PropTypes.func,
  tableChange: PropTypes.func,
  style: PropTypes.object
};
export default memo(FilterTable);
