import s from "./Notifications.module.less"
import { useTranslation } from "react-i18next"
import Bell from "../../assets/svg/bell.svg"

const NotificationsAbsence = () => {
  const { t } = useTranslation("NotificationsFeed")
  return (
    <div className={s.notificationsAbsence}>
      <div className={s.wrapper}>
        <img className={s.bell} src={Bell} alt="bell" />
      </div>
      <p className={s.text}>{t("notificationsAbsence")}</p>
    </div>
  )
}

export default NotificationsAbsence
