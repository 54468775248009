import { complaintsAPI } from "Api/api";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allComplaints: { items: [], total: 0 },
  allAdminComplaints: [],
  searchComplaint: [],
  complaintById: []
};

const complaintsSlice = createSlice({
  name: "complaints",
  initialState,
  reducers: {
    setAllComplaints(state, action) {
      state.allComplaints = action.payload;
    },
    setAllAdminComplaints(state, action) {
      state.allAdminComplaints = action.payload;
    },
    setSearchComplaints(state, action) {
      state.searchComplaint = action.payload;
    },
    setComplaintById(state, action) {
      state.complaintById = action.payload;
    }
  }
});

export const { setComplaintById, setSearchComplaints, setAllAdminComplaints, setAllComplaints } =
  complaintsSlice.actions;

export const getAllComplaints = (limit, page) => (dispatch) => {
  complaintsAPI.getAllComplaints(limit, page).then((res) => {
    dispatch(setAllComplaints(res.data));
  });
};

export const getAllAdminComplaints = (qParams = {}) => (dispatch) => {
  complaintsAPI.getAllComplaintsAdmin(qParams).then((res) => {
    dispatch(setAllAdminComplaints(res.data));
  });
};

export const searchComplaint = () => (dispatch) => {
  complaintsAPI.searchComplaint().then((res) => {
    dispatch(setSearchComplaints(res.data));
  });
};

export const complaintById = (id) => (dispatch) => {
  complaintsAPI.complaintById(id).then((res) => {
    dispatch(setComplaintById(res.data));
  });
};

export default complaintsSlice.reducer;
