import PropTypes from "prop-types";
import s from "./InfoBlock.module.less";
import {convertDate, convertTime} from "../../helpers/timeFormat";
import { useTranslation } from "react-i18next";

const InfoBlock = ({ el }) => {
  const { t } = useTranslation(["CommentsWindow", "lastContact"])
  return (
    <div className={s.block}>
      <div className={s.title}>
        <span>{convertDate(el.createdAt)} {convertTime(el.createdAt)}</span>&nbsp;
        <span>{el.email}</span>
      </div>
      <div className={s.body}>{
        el.isComment ? `${t("note", { ns: "CommentsWindow" })} ${el.message}` : t(el.message, { ns: "lastContact" })

      }</div>
    </div>
  );
};
InfoBlock.propTypes = {
  el: PropTypes.object
};
export default InfoBlock;
