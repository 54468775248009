import "dayjs/locale/es";
import "dayjs/locale/ru";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect, useLayoutEffect } from "react";
import { authMe, setLang } from "./store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ColorProvider } from './context/ColorContext';

import About from "./pages/FK3-сompanyProfile/About/About";
import Account from "Pages/Account/Account";
import { AddNewProduct } from "./pages/FS10-AddNewProduct/AddNewProduct";
import AddNewService from "./pages/FS11-AddNewService/AddNewService";
import AddUser from "Pages/FA2.1-AddUser/AddUser";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import Agreement from "./pages/Agreement/Agreement";
import { AllTechIssue } from "./pages/AllTechIssues/AllTechIssue";
import BuyerAndSellerFeedbackMailTemplate from "./pages/FA48-BuyerAndSellerFeedbackMailTemplate/BuyerAndSellerFeedbackMailTemplate";
import CatalogCategories from "./pages/FK1.1-CatalogCategories/CatalogCategories";
import CatalogProductCategories from "./pages/FK1.1-CatalogCategories/CatalogProductCategories";
import ChangeCompanyProfile from "./pages/FS8-changeCompanyProfile/ChangeCompanyProfile";
import CompanyCategories from "./pages/FK2.2-companyCategories/CompanyCategories";
import CompanyProduct from "./pages/FK2.3-companyProduct/CompanyProduct";
import CompanyProfile from "./pages/FK3-сompanyProfile/CompanyProfile";
import { ComplaintProcess } from "./pages/FA28-ComplaintProcess/ComplaintProcess";
import { ConfigProvider } from "antd";
import { ContactUs } from "./pages/FS4-Contact-us/ContactUs";
import Cookies from "js-cookie";
import Dialog from "./pages/FS20-Dialog/Dialog";
import Dialogs from "Pages/FS19-Dialogs/Dialogs";
import EditProductOrService from "./pages/FS12-EditProductOrService/EditProductOrService";
import EmailConfirmationMailTemplate from "./pages/FA6-EmailConfirmationMailTemplate/EmailConfirmationMailTemplate";
import { EmailConfirmationPage } from "./pages/email-confirmation";
import { EmployeesList } from "./pages/FA34-EmployeesList/EmployeesList";
import ExporterIncomingRequestMailTemplate from "./pages/FA5-ExporterIncomingRequestMailTemplate/ExporterIncomingRequestMailTemplate";
import ForgotPassword from "./pages/FS30-forgotPassword/ForgotPassword";
import Loader from "./components/Loader/Loader";
import { ModerationChanges } from "./pages/FA52A-ModerationChanges/ModerationChanges";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import { RecordsOfDeletions } from "./pages/FA100-RecordsOfDeletions/RecordsOfDeletions";
import { RejectedChangesNotificationMailTemplate } from "./pages/FA52-B-RejectedChangesNotificationMailTemplate/RejectedChangesNotificationMailTemplate";
import ReschedulingMeetingLetterTemplate from "./pages/FDA1.1-ReschedulingMeetingLetterTemplate/ReschedulingMeetingLetterTemplate";
import UserWhoCanceledMeetingLetterTemplate from "./pages/FDA1.2-UserWhoCanceledMeetingLetterTemplate/UserWhoCanceledMeetingLetterTemplate";
import AboutCancelingMeetingLetterTemplate from "./pages/FDA1.3-AboutCancelingMeetingLetterTemplate/AboutCancelingMeetingLetterTemplate";
import ConfirmingMeetingLetterTemplate from "./pages/FDA1.4-ConfirmingMeetingLetterTemplate/ConfirmingMeetingLetterTemplate";
import RejectingMeetingLetterTemplate from "./pages/FDA1.5-RejectingMeetingLetterTemplate/RejectingMeetingLetterTemplate";
import ScrollTopFunc from "./helpers/ScrollTopFunc";
import VoluntaryVerification from "./pages/VoluntaryVerification/VoluntaryVerification";
import en from "antd/es/locale/en_US";
import es from "antd/es/locale/es_ES";
import i18n from "i18next";
import { io } from "socket.io-client";
import ru from "antd/es/locale/ru_RU";
import MeetingAtTheExhibition from "./pages/Fk2.4-MeetingAtTheExhibition/MeetingAtTheExhibition";

const Home = lazy(() => import("./pages/FS0-home"));
const HowToBy = lazy(() => import("./pages/FS1-HowToBuy/HowToBy"));
const HowToSell = lazy(() => import("./pages/FS2-HowToSell/HowToSell"));
const IncomingRequestsMeetingBuyer = lazy(() =>
  import("./pages/FS35-IncomingRequestsMeetingBuyer/IncomingRequestsMeetingBuyer")
);
const IncomingRequestsMeetingModerBuyer = lazy(() =>
  import("./pages/FS36-IncomingRequestsMeetingModerBuyer/IncomingRequestsMeetingModerBuyer")
);
const IncomingRequestsMeetingModerSeller = lazy(() =>
  import("./pages/FS26-IncomingRequestsMeetingModerSeller/IncomingRequestsMeetingModerSeller")
);
const IncomingRequestsMeetingSeller = lazy(() =>
  import("./pages/FS25-IncomingRequestsMeetingSeller/IncomingRequestsMeetingSeller")
);
const IncomingRequestsSeller = lazy(() =>
  import("./pages/FS16-IncomingRequestsSeller/IncomingRequestsSeller")
);
const Inquiries = lazy(() => import("./pages/FA12-Inquiries/Inquiries"));

const LeaveRequestBuyer = lazy(() => import("./pages/FS21-LeaveRequestBuyer/LeaveRequestBuyer"));
const ListOfComplaints = lazy(() => import("./pages/FS38-List-of-complaints/ListOfComplaints"));
const ListOfTranslationRequests = lazy(() =>
  import("./pages/FA50-ListOfTranslationRequests/ListOfTranslationRequests")
);
const Login = lazy(() => import("./pages/FS6-login"));
const Main = lazy(() => import("./pages/FK0-mainCatalog/Main"));
const MainCatalog = lazy(() => import("./pages/FK0-mainCatalog/MainCatalog"));
const MainRequestList = lazy(() => import("./pages/FA51-MainRequestList/MainRequestList"));
const MakeComplaint = lazy(() => import("./pages/FS37-MakeComplaint/MakeComplaint"));
const MatchMaking = lazy(() => import("./pages/FS3-matchMaking/MatchMaking"));
const MatchMakingRequests = lazy(() =>
  import("./pages/FA21-MatchMakingRequests/MatchMakingRequests")
);
const MeetingInvitationMailTemplate = lazy(() =>
  import("./pages/FA8-MeetingInvitationMailTemplate/MeetingInvitationMailTemplate")
);
const MeetingInvitationModerMailTemplate = lazy(() =>
  import("./pages/FA8.1-MeetingInvitationModerMailTemplate/MeetingInvitationModerMailTemplate")
);
const MeetingModeratorCabinet = lazy(() =>
  import("./pages/FA37-MeetingModeratorCabinet/MeetingModeratorCabinet")
);
const MeetingWithBuyer = lazy(() => import("./pages/FK2-MeetingWithBuyer/MeetingWithBuyer"));
const MeetingWithSeller = lazy(() => import("./pages/FK2-MeetingWithSeller/MeetingWithSeller"));
const MeetingWithSellerAndModerator = lazy(() => import("./pages/FK2.5-MeetingWithSellerAndModerator/MeetingWithSellerAndModerator"));
const MeetingsList = lazy(() => import("./pages/FA16-MeetingsList/MeetingsList"));
const Meeting = lazy(() => import("./pages/Meeting/Meeting"));
const MeetingsWithModerationList = lazy(() =>
  import("./pages/FA17-MeetingsWithModerationList/MeetingsWithModerationList")
);
const OutgoingRequestsBuyer = lazy(() =>
  import("./pages/FS22-OutgoingRequestsBuyer/OutgoingRequestsBuyer")
);
const OutgoingRequestsMeetingBuyer = lazy(() =>
  import("./pages/FS23-OutgoingRequestsMeetingBuyer/OutgoingRequestsMeetingBuyer")
);
const OutgoingRequestsMeetingModerBuyer = lazy(() =>
  import("./pages/FS24-OutgoingRequestsMeetingModerBuyer/OutgoingRequestsMeetingModerBuyer")
);
const OutgoingRequestsMeetingModerSeller = lazy(() =>
  import("./pages/FS34-OutgoingRequestsMeetingModerSeller/OutgoingRequestsMeetingModerSeller")
);
const OutgoingRequestsMeetingSeller = lazy(() =>
  import("./pages/FS33-OutgoingRequestsMeetingSeller/OutgoingRequestsMeetingSeller")
);
const PasswordRecoveryTemplate = lazy(() =>
  import("./pages/FA19-PasswordRecoveryTemplate/PasswordRecoveryTemplate")
);
const PaymentApplications = lazy(() =>
  import("./pages/FS18-PaymentApplications/PaymentApplications")
);
const PaymentConfirmationMailTemplate = lazy(() =>
  import("./pages/FA46-PaymentConfirmationMailTemplate/PaymentConfirmationMailTemplate")
);
const Policy = lazy(() => import("./pages/Policy/Policy"));

const ProductAndServicePromotion = lazy(() =>
  import("./pages/FS28-ProductAndServicePromotion/ProductAndServicePromotion")
);
const ProductPage = lazy(() => import("./pages/FK2.1-productPage/ProductPage"));
const Promocodes = lazy(() => import("./pages/FA35-Promocodes/Promocodes"));
const PromotedGoods = lazy(() => import("./pages/FA23-PromotedGoods/PromotedGoods"));
const Registration = lazy(() => import("./pages/FS5-registration"));
const ReportTechIssues = lazy(() => import("./pages/FS39-report-tech-issues/ReportTechIssues"));
const RequestsList = lazy(() => import("./pages/FA9-RequestsList/RequestsList"));
const ResetPassword = lazy(() => import("./pages/FS30-forgotPassword/ResetPassword"));
const SafetyInstruction = lazy(() => import("./pages/FS41-Safety-instuction/SafetyInstruction"));
const MeetingSchedule = lazy(() => import("./pages/DV1-Meetings/DV1-Meetings"));
const MeetingsArchive = lazy(() => import("./pages/DV3-MeetingsArchive/MeetingsArchive"));

const SearchProducts = lazy(() => import("./pages/FK4-SearchProducts/SearchProducts"));
const SearchRequestsWithNoMatch = lazy(() =>
  import("./pages/FA10-SearchRequestsWithNoMatch/SearchRequestsWithNoMatch")
);
const ServiceCategories = lazy(() => import("./pages/FK2.2-companyCategories/ServiceCategories"));
const ServicePage = lazy(() => import("./pages/FK2.8-servicePage/ServicePage"));
const ServiceProduct = lazy(() => import("./pages/FK2.3-companyProduct/ServiceProduct"));
const Settings = lazy(() => import("./pages/FS27-Settings/Settings"));
const SubscribedAccounts = lazy(() => import("./pages/FA18-SubscribedAccounts/SubscribedAccounts"));
const SubscriptionManagement = lazy(() =>
  import("./pages/FS17-SubscriptionManagement/SubscriptionManagement")
);
const Tariffs = lazy(() => import("./pages/FA13-Tariffs/Tariffs"));
const TechProblemProcess = lazy(() =>
  import("./pages/FA36.1-TechProblemProcess/TechProblemProcess")
);
const TechnicalProblems = lazy(() => import("./pages/FA36-TechnicalProblems/TechnicalProblems"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions/TermsAndConditions"));
const TranslationServicesApplication = lazy(() =>
  import("./pages/FS32-TranslationServicesApplication/TranslationServicesApplication")
);
const UserComplaints = lazy(() => import("./pages/FA27-UserComplaints/UserComplaints"));
const UserProfile = lazy(() => import("./pages/FS7-userProfile/UserProfile"));
const UsersList = lazy(() => import("./pages/FA2-UsersList/UsersList"));
const QuestionsAndSuggestions = lazy(() =>
  import("./pages/FA20-QuestionsAndSuggestions/QuestionsAndSuggestions")
);
const SellerToBuyerInvitationMailTemplate = lazy(() =>
  import("./pages/FA8.2-SellerToBuyerInvitationMailTemplate/SellerToBuyerInvitationMailTemplate")
);

const SellerToBuyerInvitationModerMailTemplate = lazy(() =>
  import(
    "./pages/FA8.3-SellerToBuyerInvitationModerMailTemplate/SellerToBuyerInvitationModerMailTemplate"
  )
);
const MeetingNotificationTemplate = lazy(() =>
  import("./pages/FA8.4-MeetingNotificationTemplate/MeetingNotificationTemplate")
);

const RegistrationContinuation = lazy(() =>
  import("./pages/FS5-registration/RegistrationContinuation")
);

const EditAndViewExhibition = lazy(() =>
  import("Pages/BA1-EditAndViewExhibition/EditAndViewExhibition")
);

const MyExhibitions = lazy(() => import("./pages/FS100-MyExhibitions/MyExhibitions"));
const AddNewExhibition = lazy(() => import("./pages/BA2-AddNewExhibition/AddNewExhibition"));
const UserExhibitionPreview = lazy(() =>
  import("./pages/FS101-UserExhibitionPreview/UserExhibitionPreview")
);
const CanceledStandMailTemplate = lazy(() => import("./pages/FA-CanceledStandMailTemplate"));

import CookiesPolicy from "./pages/CookiesPolicy/CookiesPolicy";
import {
  getNotificationsFeed,
  getUnreadNotificationsCount,
  newNotificationHandler
} from "./store/notificationsFeedSlice";
import { changeCookieModalText } from "./helpers/changeCookieModalText";
import TimeoutLogout from "./helpers/TimeoutLogout";
import ArchiveExhibition from "./pages/FS102-ArchiveExhibitions/FS102-ArchiveExhibition";
const ExhibitionPage = lazy(() => import("./pages/FK-ExhibitionPage/ExhibitionPage"));
import { CompleteCompanyNameModal } from "./components/CompleteCompanyNameModal";

if (!Cookies.get("lang")) {
  Cookies.set("lang", "en", { expires: 365, path: "/" });
}
export const socket = io({ autoConnect: false });
const App = () => {
  const { user, isAdmin, lang } = useSelector((state) => state.auth);
  const { buyerNotifications, sellerNotifications } = useSelector(
    (state) => state.notificationsFeed
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authMe());
    dispatch(setLang(Cookies.get("lang")));
  }, []);

  useLayoutEffect(() => {
    const changeCookieBtnInterval = setInterval(() => {
      const ocanoSaveBtn = document.querySelector(".osano-cm-denyAll");
      if (ocanoSaveBtn) {
        ocanoSaveBtn.innerText = changeCookieModalText(ocanoSaveBtn.innerText);
      }
    }, 100);

    setTimeout(() => clearInterval(changeCookieBtnInterval), 10000);

    return () => {
      if (changeCookieBtnInterval) {
        clearInterval(changeCookieBtnInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);
  useEffect(() => {
    let timer;
    if (user) {
      if (!isAdmin) {
        dispatch(getNotificationsFeed("BUYER", buyerNotifications.items.length, 10));
        dispatch(getNotificationsFeed("SELLER", sellerNotifications.items.length, 10));
      }
      socket.connect();
      socket.on("connect", () => { });
      socket.on("notification", (n) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(getUnreadNotificationsCount());
        }, 500);
        console.log(n);

        dispatch(newNotificationHandler(n));
      });
      socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          socket.connect();
        }
      });
      socket.on("meetingStatus", (...args) => {
        //console.log("meetingStatus", args);
      });
    } else {
      socket.disconnect();
      socket.removeAllListeners();
    }
  }, [user]);

  const locales = { ru, en, es };

  return (
    <BrowserRouter>
      <ColorProvider>
        <Suspense fallback={<Loader style={{ width: "100vw", height: "100vh" }} />}>
          <ScrollTopFunc />
          <TimeoutLogout />
          <CompleteCompanyNameModal />
          <ConfigProvider locale={locales[lang]}>
            <div id="app">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/how-to-buy" element={<HowToBy />} />
                <Route path="/how-to-sell" element={<HowToSell />} />
                <Route path="/register" element={<Registration />} />
                <Route path="/login" element={<Login />} />
                <Route path="/admin-login" element={<AdminLogin />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/match-making" element={<MatchMaking />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/safety-instruction" element={<SafetyInstruction />} />
                <Route path="/confirm-email/:token" element={<EmailConfirmationPage />} />
                <Route path="/main-catalog" element={<MainCatalog />}>
                  <Route path="" element={<Main />} />
                  <Route path="categories/:id" element={<CatalogCategories />} />
                  <Route path="product-categories/:id" element={<CatalogProductCategories />} />
                </Route>
                <Route path="/subscription-management" element={<SubscriptionManagement />} />
                <Route path="/company-profile/:id" element={<CompanyProfile />}>
                  <Route path="" element={<About />} />
                  <Route path="product/:id" element={<ProductPage />} />
                  <Route path="product" element={<CompanyProduct />} />
                  <Route exact path="product-categories/:id" element={<CompanyCategories />} />
                  <Route exact path="service/:id" element={<ServicePage />} />
                  <Route exact path="service" element={<ServiceProduct />} />
                  <Route exact path="service-categories/:id" element={<ServiceCategories />} />
                  {/*<Route path="exhibition/:id" element={<ExhibitionPage/>}/>*/}
                </Route>
                <Route path="/company-profile/:id/exhibition/:id" element={<ExhibitionPage />} />
                <Route path="/company-profile/:id/online-meeting" element={<MeetingWithSeller />} />
                <Route
                  path="/company-profile/:id/meeting-at-the-exhibition"
                  element={<MeetingAtTheExhibition />}
                />
                <Route
                  path="/company-profile/:id/online-meeting-moderation"
                  element={<MeetingWithSellerAndModerator withModeration={true} />}
                />
                <Route path="/buyer-meeting/:id" element={<MeetingWithBuyer />} />{" "}
                {/*route for test*/}
                <Route
                  path="/buyer-meeting-moderation/:id"
                  element={<MeetingWithBuyer withModeration={true} />}
                />{" "}
                {/*route for test*/}
                <Route
                  element={
                    <PrivateRoutes
                      redirectPath={"/account/change-company-profile"}
                      isAllowed={
                        !!user &&
                        !isAdmin &&
                        (!!user.registerStep || user.company.type === 4 || user.company.type === 1)
                      }
                    />
                  }
                >
                  <Route path={"/continue-registration"} element={<RegistrationContinuation />} />
                </Route>
                <Route
                  element={<PrivateRoutes redirectPath={"/login"} isAllowed={!!user && !isAdmin} />}
                >
                  <Route
                    element={
                      <PrivateRoutes
                        redirectPath={"/continue-registration"}
                        isAllowed={
                          !!user &&
                          !isAdmin &&
                          (!user.registerStep || (user?.company?.type && user.company.type === 1))
                        }
                      />
                    }
                  >
                    <Route path="/meeting/:id" element={<Meeting />} />
                    <Route path="/account" element={<Account />}>
                      <Route path="leave-request-buyer" element={<LeaveRequestBuyer />} />
                      <Route path="change-company-profile" element={<ChangeCompanyProfile />} />
                      <Route path="dialogs" element={<Dialogs />} />
                      <Route path="dialogs/:id" element={<Dialog />} />
                      <Route path="settings" element={<Settings />} />
                      <Route path="user-profile" element={<UserProfile />} />
                      <Route path="add-product" element={<AddNewProduct />} />
                      <Route path="add-service" element={<AddNewService />} />

                      <Route
                        path="product-and-service-promotion"
                        element={<ProductAndServicePromotion />}
                      />
                      <Route path="edit-product-or-service" element={<EditProductOrService />} />
                      <Route path="incoming-requests-seller" element={<IncomingRequestsSeller />} />
                      <Route
                        path="incoming-requests-meeting-seller"
                        element={<IncomingRequestsMeetingSeller />}
                      />
                      <Route
                        path="incoming-requests-meeting-moder-seller"
                        element={<IncomingRequestsMeetingModerSeller />}
                      />
                      <Route
                        path="outgoing-requests-meeting-seller"
                        element={<OutgoingRequestsMeetingSeller />}
                      />
                      <Route
                        path="outgoing-requests-meeting-moder-seller"
                        element={<OutgoingRequestsMeetingModerSeller />}
                      />
                      <Route path="payment-applications" element={<PaymentApplications />} />
                      <Route path="outgoing-requests-buyer" element={<OutgoingRequestsBuyer />} />
                      <Route
                        path="outgoing-requests-meeting-buyer"
                        element={<OutgoingRequestsMeetingBuyer />}
                      />
                      <Route
                        path="outgoing-requests-meeting-moder-buyer"
                        element={<OutgoingRequestsMeetingModerBuyer />}
                      />
                      <Route
                        path="incoming-requests-meeting-buyer"
                        element={<IncomingRequestsMeetingBuyer />}
                      />
                      <Route
                        path="incoming-requests-meeting-moder-buyer"
                        element={<IncomingRequestsMeetingModerBuyer />}
                      />

                      <Route path="make-complaint" element={<MakeComplaint />} />
                      <Route path="make-complaint/exhibition" element={<MakeComplaint />} />

                      <Route
                        path="translation-services-application"
                        element={<TranslationServicesApplication />}
                      />
                      <Route path="all-complaints" element={<ListOfComplaints />} />
                      <Route path="report-tech-issues" element={<ReportTechIssues />} />
                      <Route path="all-tech-issues" element={<AllTechIssue />} />
                      <Route path="my-exhibitions" element={<MyExhibitions />} />
                      <Route path="meeting-schedule" element={<MeetingSchedule />} />
                      <Route path="meetings-archive" element={<MeetingsArchive />} />
                      <Route path="exhibition-preview" element={<UserExhibitionPreview />} />
                      <Route path="archive-exhibitions" element={<ArchiveExhibition />} />
                    </Route>
                  </Route>
                </Route>
                <Route element={<PrivateRoutes redirectPath={"/"} isAllowed={!!user && isAdmin} />}>
                  <Route path="/admin-panel" element={<AdminPanel />}>
                    <Route
                      element={
                        <PrivateRoutes
                          redirectPath={"/admin-panel"}
                          isAllowed={!!user && isAdmin && user.role.id === 1}
                        />
                      }
                    >
                      <Route path="dialogs/:id" element={<Dialog />} />
                      <Route
                        path="exporter-incoming-request-mail-template"
                        element={<ExporterIncomingRequestMailTemplate />}
                      />
                      <Route
                        path="email-confirmation-mail-template"
                        element={<EmailConfirmationMailTemplate />}
                      />
                      <Route
                        path="password-recovery-template"
                        element={<PasswordRecoveryTemplate />}
                      />
                      <Route
                        path="meeting-invitation-template"
                        element={<MeetingInvitationMailTemplate />}
                      />
                      <Route
                        path="meeting-invitation-moder-template"
                        element={<MeetingInvitationModerMailTemplate />}
                      />
                      <Route
                        path="meeting-invitation-from-seller-template"
                        element={<SellerToBuyerInvitationMailTemplate />}
                      />
                      <Route
                        path="meeting-invitation-from-seller-moder-template"
                        element={<SellerToBuyerInvitationModerMailTemplate />}
                      />
                      <Route
                        path="payment-confirmation-mail-template"
                        element={<PaymentConfirmationMailTemplate />}
                      />
                      <Route
                        path="buyer-and-seller-feedback-mail-template"
                        element={<BuyerAndSellerFeedbackMailTemplate />}
                      />
                      <Route
                        path="rejected-changes-notification-mail-template"
                        element={<RejectedChangesNotificationMailTemplate />}
                      />
                      <Route
                        path="meeting-notification-mail-template"
                        element={<MeetingNotificationTemplate />}
                      />
                      <Route path="records-of-deletions" element={<RecordsOfDeletions />} />
                      <Route
                        path={"stand-canceled-mail-template"}
                        element={<CanceledStandMailTemplate />}
                      />
                      <Route
                        path={"rescheduling-meeting-letter-template"}
                        element={<ReschedulingMeetingLetterTemplate />}
                      />
                      <Route
                        path={"user-who-canceled-meeting-letter-template"}
                        element={<UserWhoCanceledMeetingLetterTemplate />}
                      />
                      <Route
                        path={"about-canceling-meeting-letter-template"}
                        element={<AboutCancelingMeetingLetterTemplate />}
                      />
                      <Route
                        path={"confirming-meeting-letter-template"}
                        element={<ConfirmingMeetingLetterTemplate />}
                      />
                      <Route
                        path={"rejecting-meeting-letter-template"}
                        element={<RejectingMeetingLetterTemplate />}
                      />
                    </Route>
                    <Route path="edit-and-view-exhibition" element={<EditAndViewExhibition />} />
                    <Route path="add-new-exhibition" element={<AddNewExhibition />} />

                    <Route path="users-list" element={<UsersList />} />
                    <Route path="add-user" element={<AddUser />} />
                    <Route path="moderation-changes" element={<ModerationChanges />} />
                    <Route path="complaints" element={<UserComplaints />} />
                    <Route path="promocodes" element={<Promocodes />} />
                    <Route path="translation-list" element={<ListOfTranslationRequests />} />
                    <Route path="requests-list" element={<RequestsList />} />
                    <Route path="main-requests" element={<MainRequestList />} />
                    <Route path="inquiries" element={<Inquiries />} />
                    <Route path="meetings-list" element={<MeetingsList />} />
                    <Route
                      path="meetings-with-moderation-list"
                      element={<MeetingsWithModerationList />}
                    />
                    <Route path="tariffs" element={<Tariffs />} />
                    <Route path="employees-list" element={<EmployeesList />} />
                    <Route path="subscribed-accounts" element={<SubscribedAccounts />} />
                    <Route path="technical-problems" element={<TechnicalProblems />} />
                    <Route path="complaint-process/:id2" element={<ComplaintProcess />} />
                    <Route path="technical-problems-process/:id2" element={<TechProblemProcess />} />
                    <Route path="matchmaking-requests" element={<MatchMakingRequests />} />
                    <Route path={"promoted-goods"} element={<PromotedGoods />} />
                    <Route
                      path="search-requests-with-no-match"
                      element={<SearchRequestsWithNoMatch />}
                    />
                    <Route path="questions-and-suggestions" element={<QuestionsAndSuggestions />} />
                  </Route>
                </Route>
                <Route
                  path="/meeting-moderator-cabinet/:meetingId"
                  element={<MeetingModeratorCabinet />}
                />
                <Route
                  path="/meeting-moderator-cabinet/:meetingId"
                  element={<MeetingModeratorCabinet />}
                />
                <Route path="/searching/" element={<SearchProducts />}>
                  <Route path="type:serv_type/page:page/" element={<SearchProducts />} />
                  <Route path="type:serv_type/page:page/:q" element={<SearchProducts />} />
                </Route>
                <Route path="/privacy_policy" element={<Policy />} />
                <Route path="/cookies_policy" element={<CookiesPolicy />} />
                <Route path="/agreement" element={<Agreement />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/voluntary-verification" element={<VoluntaryVerification />} />
              </Routes>
            </div>
          </ConfigProvider>
        </Suspense>
      </ColorProvider>
    </BrowserRouter>
  );
};

export default App;
