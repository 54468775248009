import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Col, Form, Input, Modal, Row, Tabs } from "antd";
import { locales, mailTemplateAPI } from "Api/api";
import { getReschedulingMeetingLetterTemplate } from "Store/mailTemplatesSlice";
import { tabsChange, updateTemplate } from "Helpers/mailTemplatesHelpers";
import Loader from "../../components/Loader/Loader";
import React from "react";
import {
  RESCHEDULING_MEETING_LETTER_TEMPLATE,
  setMailTemplate,
  setMailTemplateSubject
} from "../../store/mailTemplatesSlice";
import { PRODUCTION_URL, TINY_MCE_API_KEY } from "../../api/api";

const { TabPane } = Tabs;

const ReschedulingMeetingLetterTemplate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["FDA1.1-ReschedulingMeetingLetterTemplate"]);

  const { setTitle } = useOutletContext();
  const { reschedulingMeetingLetterTemplate } = useSelector((state) => state.mailTemplates);
  const { isLoading } = useSelector((state) => state.auth);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    if (!reschedulingMeetingLetterTemplate[locales[0]]) {
      dispatch(getReschedulingMeetingLetterTemplate(locales[0]));
    }
  }, []);

  useEffect(() => {
    setTitle(t("title"));
  }, [t, setTitle]);

  return (
    <>
      {modalIsVisible ? (
        <Modal
          visible={modalIsVisible}
          onCancel={() => {
            setModalIsVisible(false);
          }}
          footer={[
            <Button
              key="ok"
              className="btn btn_v2"
              onClick={() => {
                setModalIsVisible(false);
              }}
            >
              ОК
            </Button>
          ]}
        >
          <p>{modalMessage}</p>
        </Modal>
      ) : null}
      <Row>
        <Col sm={{ span: 24 }} xxl={{ span: 20 }} className={`regular_block`}>
          {isLoading && <Loader />}
          <div>
            <h5>{t("availableVariables")}</h5>
            <p>#FIRSTNAME - {t("FIRSTNAME")}</p>
            <p>#LASTNAME - {t("LASTNAME")}</p>
            <p>#COMPANY_NAME - {t("COMPANY_NAME")}</p>
            <p>#INITIAL_TIME - {t("INITIAL_TIME")}</p>
            <p>#NEW_TIME - {t("NEW_TIME")}</p>
            <p>#TZ - {t("TZ")}</p>
            <p>{t("HYPERLINK_EXAMPLE")}</p>
          </div>
          <Tabs
            defaultActiveKey={0}
            onChange={(key) => {
              tabsChange(
                key,
                reschedulingMeetingLetterTemplate,
                dispatch,
                getReschedulingMeetingLetterTemplate
              );
            }}
          >
            {locales.map((el, i) => (
              <TabPane tab={el} key={i}>
                <Col span={12}>
                  <p className="mb-12">{t("mailSubject")}</p>
                  <Form>
                    <Form.Item>
                      <Input
                        value={reschedulingMeetingLetterTemplate[locales[i]]?.subject}
                        onChange={(e) => {
                          dispatch(
                            setMailTemplateSubject({
                              mailTemplate: RESCHEDULING_MEETING_LETTER_TEMPLATE,
                              lang: el,
                              subject: e.target.value
                            })
                          );
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Editor
                  inline_styles={true}
                  apiKey={TINY_MCE_API_KEY}
                  plugins={["code"]}
                  value={reschedulingMeetingLetterTemplate[locales[i]]?.template}
                  init={{
                    relative_urls: false,
                    remove_script_host: false,
                    document_base_url: PRODUCTION_URL
                  }}
                  onEditorChange={(content) => {
                    dispatch(
                      setMailTemplate({
                        mailTemplateName: RESCHEDULING_MEETING_LETTER_TEMPLATE,
                        mailTemplateContent: content,
                        lang: locales[i]
                      })
                    );
                  }}
                />
                <div>
                  <h5 className="mt-50 mb-24">{t("mailTemplate")}</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: reschedulingMeetingLetterTemplate[locales[i]]?.template
                        ?.replace("#FIRSTNAME", t("FIRSTNAMEex"))
                        ?.replace("#LASTNAME", t("LASTNAMEex"))
                        ?.replace("#COMPANY_NAME", t("COMPANY_NAMEex"))
                        ?.replace("#INITIAL_TIME", t("INITIAL_TIMEex"))
                        ?.replace("#NEW_TIME", t("NEW_TIMEex"))
                        ?.replace("#TZ", t("TZex"))
                        ?.replace(
                          "#HYPERLINK_START#",
                          "<a style='text-decoration: underline; width: max-content;' href='#LINK'>"
                        )
                        ?.replace("#HYPERLINK_END#", "</a>")
                    }}
                  ></div>
                </div>
                <Button
                  onClick={() =>
                    updateTemplate(
                      locales[i],
                      reschedulingMeetingLetterTemplate,
                      setModalMessage,
                      setModalIsVisible,
                      mailTemplateAPI.updateReschedulingMeetingLetterTemplate,
                      t,
                      dispatch
                    )
                  }
                  className="btn btn_v2 ml-auto mt-24"
                >
                  {t("buttons.save")}
                </Button>
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default ReschedulingMeetingLetterTemplate;
