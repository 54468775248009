/* eslint-disable react/prop-types */

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import s from "./Nav.module.less";
import { useTranslation } from "react-i18next";

const Nav = ({ style, url, preview, companyId }) => {
  const { t } = useTranslation("FK3-сompanyProfile");
  const { user, isAdmin } = useSelector((state) => state.auth);
  const [openIsModal, setOpenIsModal] = useState(null);
  let link = useLocation().pathname.indexOf("product");
  let link2 = useLocation().pathname.indexOf("/categories/");
  let link3 = useLocation().pathname.indexOf("service");
  let link4 = useLocation().pathname.indexOf("service-categories");
  const navigate = useNavigate();

  const nav = () => {
    if (openIsModal === 1) {
      return "online-meeting"
    } else if (openIsModal === 2) {
      return "online-meeting-moderation"
    } else if (openIsModal === 3) {
      return `/company-profile/${companyId.id}#form`
    }
  }
  const disabledNavLink = (e, num, link) => {
    if (!isAdmin && user?.company?.type === 2 && url !== user?.company?.id) {
      setOpenIsModal(num);
    } else if (!isAdmin && url === user?.company?.id) return false
    else navigate(user ? link : "/login", { state: { moveOn: `/company-profile/${companyId.id}/${link}` } })
  }

  return (
    <div className={s.wrapper} style={style}>
      <NavLink
        className={({ isActive }) =>
          isActive && link < 0 && link2 < 0 && link3 < 0 && link4 < 0 ? s.active : s.link
        }
        to=""
      >
        {t("link1")}
      </NavLink>
      <NavLink
        onClick={(e) => {
          if (preview) e.preventDefault();
        }}
        className={link + link2 > 0 ? s.active : s.link}
        to="product"
      >
        {t("link2")}
      </NavLink>
      <NavLink
        onClick={(e) => {
          if (preview) e.preventDefault();
        }}
        className={link3 + link4 > 0 ? s.active : s.link}
        to="service"
      >
        {t("link6")}
      </NavLink>
    </div>
  );
};

export default Nav;
