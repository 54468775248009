import { FileOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { mediaUrl } from '../../../api/api';
import SentMsg from '../../../assets/svg/SentMsg';
import s from './Message.module.less';

const Message = ({ item }) => {
  const { user, isAdmin } = useSelector((state) => state.auth);
  const formatTime = (value) => {
    const date = new Date(value);
    if (!isAdmin) date.setMinutes(date.getMinutes() + user?.tz?.timeZone.split("UTC")[1].split(":")[0] * 60 + Number(user?.tz?.timeZone.split(":")[1]) + date.getTimezoneOffset());
    return date.toLocaleTimeString();
  };
  const date = dayjs(item.createdAt).tz().format("DD.MM.YYYY");
  const time = formatTime(item.createdAt)
  const file = item.text.includes("EXTF:") && item.text.split(":");
  const fileNameSplit = file[1] && file[1].split("-");
  const fileExtention = fileNameSplit && fileNameSplit[fileNameSplit.length - 1].split(".")[1];

  const handleRedirect = () => {
    window.location.href = `${mediaUrl}${file[1]}`;
  };

  return (
    <div
      data-id={item.id}
      data-isread={item.isRead}
      data-my={item.username === user.email}
      className={`${s.msgBlock} ${item.username === user.email ? s.my : ""}`}
    >
      {!item?.text?.includes("EXTF") ? (
        <div className={`${s.message}  ${item.isRead ? s.read : ""}`}>
          {item.text}
          {item.username === user.email && <SentMsg />}
        </div>
      ) : file[file.length - 1] === "IMG" ? (
        <div
          onClick={() => handleRedirect()}
          className={`${s.message}  ${item.isRead ? s.read : ""}`}
        >
          <img className={s.image} src={`${mediaUrl}${file[1]}`} />
          {item.username === user.email && <SentMsg />}
        </div>
      ) : file[file.length - 1] === "VID" ? (
        <div
          onClick={() => handleRedirect()}
          className={`${s.message}  ${item.isRead ? s.read : ""} ${s.fileMessage}`}
        >
          <div className={`${s.fileIcon}`}>
            <video
              controls
              style={{ maxWidth: 500, maxHeight: 500, width: "100%" }}
              src={`${mediaUrl}${file[1]}`}
            />
          </div>
          {item.username === user.email && <SentMsg />}
        </div>
      ) : (
        <div
          onClick={() => handleRedirect()}
          className={`${s.message}  ${item.isRead ? s.read : ""} ${s.fileMessage}`}
        >
          <div className={`${s.fileIcon}`}>
            <FileOutlined />
            {fileNameSplit && fileNameSplit.slice(0, fileNameSplit.length - 1).join("-")}.
            {fileExtention}
          </div>
          {item.username === user.email && <SentMsg />}
        </div>
      )}
      <div className={s.dateTime}>
        <div>{date}</div>
        <div>{time}</div>
      </div>
    </div>
  );
};

Message.propTypes = {
  item: PropTypes.exact({
    username: PropTypes.string,
    text: PropTypes.string,
    id: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    isRead: PropTypes.bool,
    room: PropTypes.string,
    roomId: PropTypes.number
  })
};
export default Message;
