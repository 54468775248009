import { createSlice } from "@reduxjs/toolkit";
import { matchmakingAPI, meetingAPI } from "../api/api";
import { meetingApi } from "../api/meeting";
import dayjs from "dayjs";

const initialState = {
  allMeetings: [],
  timesRange: [dayjs().format("YYYY-MM-DD"), dayjs().add(6, "day").format("YYYY-MM-DD")],
  mode: "grid",
  partnerId: null,
  currentTransferMeeting: null,
  workExhibitionTime: null,
  currentCompanyFK: null,
  secondPartnerId: null,
  adminMeetings: [],
  currentExhibitionColor: null
};

const meetingsSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    setAllMeetings(state, action) {
      state.allMeetings = action.payload;
    },
    setCurrentExhibitionColor(state, action) {
      state.currentExhibitionColor = action.payload;
    },
    setAllAdminMeetings(state, action) {
      state.adminMeetings = action.payload;
    },
    setTimesRange(state, action) {
      state.timesRange = action.payload;
    },
    setMeetingTableMode(state, action) {
      state.mode = action.payload;
    },
    setPartnerId(state, action) {
      state.partnerId = action.payload;
    },
    setCurrentTransferMeeting(state, action) {
      state.currentTransferMeeting = action.payload;
    },
    setWorkExhibitionTime(state, action) {
      state.workExhibitionTime = action.payload;
    },
    setCurrentCompanyFK(state, action) {
      state.currentCompanyFK = action.payload;
    },
    setSecondPartnerId(state, action) {
      state.secondPartnerId = action.payload;
    }
  }
});

export const {
  setAllMeetings,
  setTimesRange,
  setMeetingTableMode,
  setPartnerId,
  setCurrentTransferMeeting,
  setWorkExhibitionTime,
  setCurrentCompanyFK,
  setSecondPartnerId,
  setAllAdminMeetings,
  setCurrentExhibitionColor
} = meetingsSlice.actions;

export const getClientMeetings =
  ({
    mode,
    params,
    timesRange,
    gridIsEmpty,
    filtering,
    setFiltering,
    fetchedRange,
    setFetchedRange,
    setTotal,
  }) =>
    (dispatch) => {
      if (mode === "grid") {
        params.pageSize = 100;
        let gridParams = { ...params }
        const modeChanged = !!params.currentPage
        delete gridParams.currentPage;
        const bufferStart = new Date(timesRange[0]);
        bufferStart.setDate(bufferStart.getDate() - 20);
        const bufferEnd = new Date(timesRange[1]);
        bufferEnd.setDate(bufferEnd.getDate() + 7);
        gridParams.timesRange = [
          bufferStart.toISOString().split("T")[0],
          bufferEnd.toISOString().split("T")[0]
        ];
        if (
          !fetchedRange ||
          gridIsEmpty ||
          filtering ||
          modeChanged ||
          new Date(timesRange[0]).getTime() <= fetchedRange.start.getTime() ||
          new Date(timesRange[1]).getTime() >= fetchedRange.end.getTime()
        ) {
          meetingApi.getAllMeetings(gridParams).then((res) => {
            if (res.data.items) {
              dispatch(setAllMeetings(res.data.items));
            }
            setFiltering(false);
            setFetchedRange({ start: bufferStart, end: bufferEnd });
            setTotal(res.data.total);
          });
        }
      } else {
        meetingApi.getAllMeetings(params).then((res) => {
          if (res.data.items) {
            console.log(res.data);
            dispatch(setAllMeetings(res.data.items));
          }
          setTotal(res.data.total);
        });
      }
    };

export default meetingsSlice.reducer;
