import { createSlice } from "@reduxjs/toolkit";
import { productsAPI } from "../api/api";
import { setIsLoading } from "./authSlice";

export const initialCurrentProduct = {
  advantage1: "",
  advantage2: "",
  advantage3: "",
  availableCertificates: "",
  companyId: undefined,
  createdAt: "",
  currency: {},
  currencyId: undefined,
  deliveryAddress: "",
  deliveryTime: {},
  deliveryTimeId: undefined,
  deliveryUnit: {},
  deliveryUnitId: undefined,
  description: "",
  fobUnitPrice: undefined,
  id: 0,
  image: "",
  incoterms: [],
  minimalAmount: undefined,
  paymentMethods: [],
  productKeywords: [],
  productName: "",
  productPhotos: [],
  productiveCapacity: "",
  promotedProduct: null,
  promotedProductId: null,
  requirementsPackingDetails: "",
  showcase: false,
  subcategories: [{ id: undefined }],
  unitPrice: undefined,
  updatedAt: "",
  video: "",
  visible: true,
  moderationStatus: {id: 3, name: "ACCEPTED"}
};
const initialState = {
  products: {
    items: [],
    total: 0
  },
  currentProduct: initialCurrentProduct
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    },
    setCurrentProduct(state, action) {
      state.currentProduct = action.payload;
    }
  }
});

export const { setProducts, setCurrentProduct } = productsSlice.actions;

export const getProducts =
  (limit, page, subcategoryId, sortColumn, sortDirection, companyId, withModerations = false) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    productsAPI
      .getProducts(
        limit,
        page,
        subcategoryId,
        sortColumn,
        sortDirection,
        companyId,
        withModerations
      )
      .then((res) => {
        dispatch(setProducts(res.data));
      })
      .catch((e) => {
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };
export const getProductById =
  (id, withModerations = false, withEdits = false) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    productsAPI
      .getProductById(id, withModerations, withEdits)
      .then((res) => {
        dispatch(setCurrentProduct(res.data));
      })
      .catch((e) => {
      })
      .finally(() => dispatch(setIsLoading(false)));
  };
export default productsSlice.reducer;
