/* eslint-disable react/prop-types */

import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, Typography } from "antd";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha
} from "react-simple-captcha";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { RetweetOutlined } from "@ant-design/icons";
import { companyAPI } from "Api/api";
import { fetchCountries } from "Store/changeCompanyProfileSlice";
import s from "Pages/FS5-registration/registration.module.less";
import style from "./OfferForm.module.less";
import useOnlyLatinLetterInputHandlerAndModal from "../../../hooks/useOnlyLatinLetterInputHandlerAndModal";
import { useTranslation } from "react-i18next";

const OfferForm = ({ id, setSuccessfully, form }) => {
  const { user } = useSelector((state) => state.auth);
  const { countries } = useSelector((state) => state.changeCompanyProfile.resources);

  const dispatch = useDispatch();
  const [modal, setModal] = useState({ visible: false, message: "" });
  const [phone, setPhone] = useState();
  const [disable, setDisable] = useState(false);

  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();

  const { t, i18n } = useTranslation([
    "FK3-сompanyProfile",
    "Countries",
    "FS5-Registration",
    "FS0-Home",
    "FS8-changeCompanyProfile",
    "FS7-userProfile"
  ]);

  useEffect(() => {
    loadCaptchaEnginge(5, "transparent", "#000", "lower");
    dispatch(fetchCountries());
  }, []);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);

    form.validateFields(errorFields);
  }, [i18n.language]);

  const openModal = (message) => {
    setModal({ visible: true, message });
  };

  const closeModal = () => {
    validateCaptcha(0);
    setModal({ visible: false, message: "" });
  };

  const handlePhoneInput = (val) => {
    const regex = /^(\+)?(\d+)?/g;
    const match = val.match(regex);
    setPhone(match[0]);
    form.setFields([{ name: "telephone", errors: [] }]);
    form.setFieldsValue({ telephone: match[0] });
  };
  const onFinish = ({ agreement, captcha, ...body }) => {
    setDisable(true);
    companyAPI
      .postOrderId(id, body)
      .then((res) => {
        setModal({
          visible: true,
          message: (
            <div>
              {t("form.success1")}{" "}
              <Link style={{ color: "#1890ff" }} to={"/account/outgoing-requests-buyer"}>
                {t("form.success2")}
              </Link>{" "}
              {t("form.success3")}
            </div>
          )
        });
        if (!user) setSuccessfully(true);
      })
      .catch((e) => {
        openModal(t("form.error"));
      })
      .finally(() => {
        setDisable(false);
        form.resetFields(["item", "itemAmount", "message", "captcha"]);
      });
  };

  const onFinishFailed = (v) => {
    openModal(t("form.error"));
    if (v.values.captcha) {
      form.resetFields(["captcha"]);
      if (v.errorFields.find((f) => f.name[0] === "captcha")) {
        form.setFields([
          {
            name: "captcha",
            errors: [t("error9")]
          }
        ]);
      }
    }
  };

  const initialValues = useMemo(
    () => ({
      agreement: false,
      workingEmail: user?.email || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      companyName: user?.company?.companyName || "",
      telephone: user?.company?.phone || "",
      countryId: user?.company?.countryId || ""
    }),
    [user]
  );

  const countriesOption = useMemo(
    () =>
      countries
        .map(({ id, name }) => ({ value: id, label: t(name, { ns: "Countries" }) }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t, countries]
  );

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") setModal({ visible: false, message: "" });
    });
  }, [document]);

  return (
    <>
      {OnlyLatinLettersModal}
      <Modal
        visible={modal.visible}
        onCancel={closeModal}
        footer={[
          <Button key="ok" className="btn btn_v2" onClick={closeModal}>
            {t("modal.button", { ns: "FS8-changeCompanyProfile" })}
          </Button>
        ]}
      >
        <p>{modal.message}</p>
      </Modal>
      <div id={"form"} style={{ scrollMarginTop: "140px" }}>
        <Row>
          <Col
            span={23}
            className={`${s.register_block} ${s.centered} regular_block`}
            style={{ marginBottom: "40px", paddingLeft: 0, paddingRight: 0 }}
          >
            <Form
              className={style.offerForm}
              colon={false}
              name="registration"
              labelCol={{ span: 22 }}
              wrapperCol={{ span: 24 }}
              form={form}
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              labelAlign="left"
              labelWrap
              requiredMark={false}
              validateTrigger="onSubmit"
            >
              <Row gutter={[32]}>
                <Col span={12}>
                  <Form.Item
                    name="item"
                    style={{ display: "flex", flexDirection: "column" }}
                    label={
                      <div style={{ fontWeight: "500" }} className={`${s.label} ${style.req}`}>
                        {t("form.formInputs.product", { ns: "FS0-Home" })}
                      </div>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("form.validation.Productrequired", { ns: "FS0-Home" })
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      onChange={(e) =>
                        OnlyLatinLettersTextInput(
                          e.target.value,
                          "item",
                          form,
                          true,
                          null,
                          null,
                          true
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="companyName"
                    style={{ display: "flex", flexDirection: "column" }}
                    label={
                      <span style={{ fontWeight: "500" }} className={`${s.label} ${style.req}`}>
                        {t("form.companyName")}
                      </span>
                    }
                    rules={[{ required: true, message: t("error2") }]}
                  >
                    <Input
                      disabled={user?.company.companyName}
                      size="large"
                      onChange={(e) =>
                        OnlyLatinLettersTextInput(
                          e.target.value,
                          "companyName",
                          form,
                          true,
                          null,
                          null,
                          true
                        )
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="countryId"
                    style={{ display: "flex", flexDirection: "column" }}
                    label={
                      <div style={{ fontWeight: "500" }} className={`${s.label} ${style.req}`}>
                        {t("form.country")}
                      </div>
                    }
                    rules={[{ required: true, message: t("error3") }]}
                  >
                    <Select
                      options={countriesOption}
                      size="large"
                      style={{ maxWidth: "600px", backgroundColor: "#fff !important" }}
                      className={style.countryId}
                      allowClear
                      showArrow={false}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        option.label.toLowerCase().startsWith(input.toLowerCase())
                      }
                      onDropdownVisibleChange={() =>
                        form.setFields([{ name: "countryId", errors: [] }])
                      }
                      disabled={user?.company?.countryId}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ display: "flex", flexDirection: "column" }}
                    label={
                      <span style={{ fontWeight: "500" }} className={`${s.label} ${style.req}`}>
                        {t("form.workMail")}
                      </span>
                    }
                    name="workingEmail"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: t("error4")
                      }
                    ]}
                  >
                    <Input
                      disabled={user?.email}
                      onChange={(e) =>
                        OnlyLatinLettersTextInput(
                          e.target.value,
                          "workingEmail",
                          form,
                          true,
                          null,
                          null,
                          true
                        )
                      }
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    style={{ display: "flex", flexDirection: "column" }}
                    label={
                      <span style={{ fontWeight: "500" }} className={`${s.label} ${style.req}`}>
                        {t("form.formInputs.firstName", { ns: "FS0-Home" })}
                      </span>
                    }
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: t("form.validation.firstNameRequired", { ns: "FS0-Home" })
                      }
                    ]}
                  >
                    <Input
                      disabled={user?.firstName}
                      onChange={(e) =>
                        OnlyLatinLettersTextInput(
                          e.target.value,
                          "firstName",
                          form,
                          false,
                          /[^a-z-\s]/gi,
                          t("customModalText", { ns: "FS7-userProfile" }),
                          true
                        )
                      }
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ display: "flex", flexDirection: "column" }}
                    label={
                      <span style={{ fontWeight: "500" }} className={`${s.label} ${style.req}`}>
                        {t("form.formInputs.lastName", { ns: "FS0-Home" })}
                      </span>
                    }
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: t("form.validation.lastNameRequired", { ns: "FS0-Home" })
                      }
                    ]}
                  >
                    <Input
                      disabled={user?.lastName}
                      onChange={(e) =>
                        OnlyLatinLettersTextInput(
                          e.target.value,
                          "lastName",
                          form,
                          false,
                          /[^a-z-\s]/gi,
                          t("customModalText", { ns: "FS7-userProfile" }),
                          true
                        )
                      }
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", flexDirection: "column" }}
                    label={
                      <span style={{ fontWeight: "500" }} className={`${s.label} ${style.req}`}>
                        {t("form.formInputs.productsQuantity", { ns: "FS0-Home" })}
                      </span>
                    }
                    name="itemAmount"
                    rules={[
                      {
                        required: true,
                        message: t("form.validation.productQuantityequired", { ns: "FS0-Home" })
                      },
                      {
                        validator: (_, value) => {
                          if (value && value <= 0) {
                            return Promise.reject(
                              t("form.validation.productQuantityequired", { ns: "FS0-Home" })
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                      }
                    ]}
                  >
                    <Input
                      size="large"
                      onChange={(e) =>
                        OnlyLatinLettersTextInput(
                          e.target.value,
                          "itemAmount",
                          form,
                          true,
                          null,
                          null,
                          true
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", flexDirection: "column" }}
                    label={
                      <span style={{ fontWeight: "500" }} className={`${s.label} ${style.req}`}>
                        {t("form.phoneNumber")}
                      </span>
                    }
                    name="telephone"
                    rules={[
                      {
                        required: true,
                        message: t("error7")
                      }
                    ]}
                  >
                    <Input
                      disabled={user?.company?.phone}
                      maxLength={20}
                      value={phone}
                      onChange={(e) => handlePhoneInput(e.target.value)}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Space align="start" size={24} style={{ marginBottom: "25px", display: "flex" }}>
                <Form.Item
                  name="message"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 40 }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "651px",
                    marginTop: "30px"
                  }}
                  label={
                    <span
                      style={{ fontWeight: "500", marginBottom: "20px" }}
                      className={`${s.label} ${style.req}`}
                    >
                      {t("form.message")}
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("error8")
                    },
                    {
                      min: 6,
                      message: t("error10")
                    }
                  ]}
                >
                  <Input.TextArea
                    onChange={(e) =>
                      OnlyLatinLettersTextInput(
                        e.target.value,
                        "message",
                        form,
                        true,
                        null,
                        null,
                        true
                      )
                    }
                    style={{
                      padding: "20px",
                      minHeight: "120px"
                    }}
                    rows={5}
                    className={style.textarea}
                    autoSize
                    allowClear
                    placeholder={t("form.placeholder")}
                  />
                </Form.Item>
              </Space>
              <Space align="start" size={18} style={{ marginBottom: "15px", display: "flex" }}>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    { required: true },
                    { validator: (_, value) => (value ? Promise.resolve() : Promise.reject("")) }
                  ]}
                  onChange={() => form.setFields([{ name: "agreement", errors: [] }])}
                >
                  <Checkbox style={{ borderColor: "red" }} />
                </Form.Item>
                <Typography.Paragraph style={{ width: "481px", fontWeight: 400 }}>
                  {t("form.checkbox1")} <br />
                  <Link to="/terms-and-conditions" className={s.link} target="_blank">
                    {t("form.checkbox2")}
                  </Link>{" "}
                  {t("form.checkbox3")}{" "}
                  <Link to="/privacy_policy" className={s.link} target="_blank">
                    {t("form.checkbox4")}
                  </Link>
                  *
                </Typography.Paragraph>
              </Space>
              <Space size="large" style={{ alignItems: "flex-start" }}>
                <Form.Item
                  name="captcha"
                  className={style.captchaWrap}
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: ""
                    },
                    {
                      message: t("error9"),
                      validator: (_, value) =>
                        validateCaptcha(value, false) ? Promise.resolve() : Promise.reject()
                    }
                  ]}
                >
                  <div className={style.captchaWrap__captcha}>
                    <Input
                      size="large"
                      onChange={() => form.setFields([{ name: "captcha", errors: [] }])}
                    />
                  </div>
                </Form.Item>
                <div className={style.captchaWrap__wrap}>
                  <LoadCanvasTemplateNoReload />
                </div>
                <RetweetOutlined
                  style={{ fontSize: 28, marginTop: "5px", cursor: "pointer" }}
                  onClick={() => {
                    validateCaptcha(0);
                  }}
                />

                <Form.Item noStyle>
                  <Button
                    className="btn btn_v2"
                    type="default"
                    htmlType="submit"
                    size="large"
                    disabled={disable}
                  >
                    {t("form.send")}
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OfferForm;
