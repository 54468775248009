import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Space } from "antd";
import PropTypes from "prop-types";

const useChangeFormAlert = () => {
  const [displayAlert, setDisplayAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(["EditFormAlert"]);
  const EditFormAlert = ({ saveAsDraft }) => {
    return (
      <>
        <Modal
          visible={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          width={600}
          footer={[
            <Space
              key={1}
              size={30}
              align="center"
              style={{
                marginBottom: "15px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                className={`btn btn_v2  ml-auto`}
                size="large"
                onClick={async () => {
                  await saveAsDraft();
                  setDisplayAlert(false);
                  setShowModal(false);
                }}
              >
                {t("edit")}
              </Button>
              <Button
                className={`btn btn_v8 ml-auto`}
                type="default"
                size="large"
                onClick={(e) => {
                  setShowModal(false);
                }}
              >
                {t("cancel")}
              </Button>
            </Space>
          ]}
        >
          <p>{t("h")}</p>
          <p>{t("p")}</p>
        </Modal>
        {displayAlert && (
          <div
            style={{
              position: "absolute",
              zIndex: 2,
              top: 30,
              left: 0,
              right: 0,
              bottom: 0
            }}
            onClick={() => setShowModal(true)}
          ></div>
        )}
      </>
    );
  };
  EditFormAlert.propTypes = {
    saveAsDraft: PropTypes.func
  };
  return [displayAlert, setDisplayAlert, EditFormAlert];
};

export default useChangeFormAlert;
