/* eslint-disable react/display-name */
import React, { memo, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
const OnlyLatinLettersModal1 = memo(({ modalVisible, modalText, closeModal }) => {
  const { t } = useTranslation("OnlyLatinLettersModal");

  return (
    <>
      {modalVisible && (
        <Modal
          visible={modalVisible}
          onCancel={closeModal}
          onOk={closeModal}
          footer={[
            <Button key="ok" className="btn btn_v2" onClick={closeModal}>
              OK
            </Button>
          ]}
        >
          {modalText === "defaultText" && (
            <p style={{ textAlign: "left", marginTop: 16, fontWeight: 400 }}>
              {t("text1")}
              <strong>
                {t("text2")}
                <span className="orange">{t("onlyEnglish")}</span>
                {t("text3")}
              </strong>
              <br />
              <p style={{ marginTop: "0px", height: 10 }}>&nbsp;</p>
              {t("text4")}
            </p>
          )}
          {modalText !== "defaultText" && (
            <p style={{ whiteSpace: "pre-line", fontWeight: 400 }}>{modalText}</p>
          )}
        </Modal>
      )}
    </>
  );
});

const useOnlyLatinLetterInputHandlerAndModal = () => {
  const { t } = useTranslation("OnlyLatinLettersModal");
  const [modalVisible, setModalVisible] = useState(false);
  const [currentFieldName, setCurrentFieldName] = useState("");
  const [currentField, setCurrentField] = useState(null);
  const [modalText, setModalText] = useState("defaultText");

  const closeModal = () => {
    setModalVisible(false);
    if (currentField) currentField.focus();
  };
  const callBack = (e) => {
    if (e.key === "Enter") closeModal();
  };
  useEffect(() => {
    document.addEventListener("keydown", callBack);
    return () => {
      document.removeEventListener("keydown", callBack);
    };
  }, [modalVisible]);

  const OnlyLatinLettersTextInput = (
    val,
    fieldName,
    form,
    allowPunctuationAndDigits = false,
    customRegExAntiPattern = null,
    customModalText = null,
    resetError = false,
    successCallback = null
  ) => {
    if (customModalText && customModalText !== modalText) setModalText(customModalText);
    else if (!customModalText && modalText !== "defaultText") setModalText("defaultText");
    if (resetError) {
      form.setFields([{ name: [fieldName], errors: [] }]);
    }
    let pattern =
      customRegExAntiPattern ||
      (allowPunctuationAndDigits
        ? /[^a-z\s\d-@,.?!_/:’©®™°…‾´<>±⁄≤≥≈€¢£$•&«»″*–—#%+=()'"^]/gi
        : /[^a-z\s]/gi);

    if (val.match(pattern)) {
      if (currentFieldName !== fieldName) {
        setCurrentFieldName(fieldName);
        setCurrentField(form.getFieldInstance(fieldName));
      }
      if (form.getFieldInstance(fieldName)) form.getFieldInstance(fieldName).blur();

      if (!modalVisible) {
        setModalVisible(true);
      }
      form.setFieldsValue({ [fieldName]: val.replace(pattern, "") });
      return false;
    }
    if (successCallback) successCallback();
    return true;
  };
  const OnlyLatinLettersModal = (
    <OnlyLatinLettersModal1
      closeModal={closeModal}
      modalText={modalText}
      modalVisible={modalVisible}
    />
  );
  return { OnlyLatinLettersTextInput, OnlyLatinLettersModal };
};

export default useOnlyLatinLetterInputHandlerAndModal;
