import React from "react";
import { useTranslation } from "react-i18next";
import s from "./BlockWithExhibitions.module.less";
import ExhibitionTable from "../../FK3-сompanyProfile/ExhibitionTable/ExhibitionTable";

const BlockWithExhibitions = ({ user, id }) => {
  const { t } = useTranslation("FK2-Meeting");
  return (
    <div>
      <ExhibitionTable
        user={user}
        companyId={id}
        fk2={t}
        slice
        link={`/company-profile/${id}/meeting-at-the-exhibition#Table`}
      >
        <p>{t("exhibition.1")}</p>
        <p>{t("exhibition.2")}</p>
        <div className={s.bigOrange}>{t("exhibition.3")}</div>
      </ExhibitionTable>
    </div>
  );
};

export default BlockWithExhibitions;
