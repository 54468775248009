/* eslint-disable react/prop-types */
import React from "react";
import { Input, Select, Form } from "antd";
import { useSelector } from "react-redux";
import styles from "./edit-or-view-cell.module.less";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import { useMemo } from "react";

const { TextArea } = Input;

const EditOrViewCell = ({
  exhibitionId,
  standId,
  editExhibitionId,
  editStandId,
  editingType,
  view,
  defaultValue,
  isStandEditable,
  formItemName,
  OnlyLatinLettersTextInput,
  form,
  isDisabled,
  placeholder,
  rules
}) => {
  const { isEditMode } = useSelector((state) => state.exhibitions);
  const { isAdmin } = useSelector((state) => state.auth);
  const [openState, setOpenState] = useState(false);
  const isExhibitionEditable = isEditMode && exhibitionId === editExhibitionId;
  const isStandCurrentlyEditable = isEditMode && standId === editStandId;
  const { countries, cities } = useSelector((state) => state.resources);
  const { t } = useTranslation(["Countries", "FS8-changeCompanyProfile"]);
  const { lang } = useSelector((state) => state.auth);

  const renderSelectOptions =
    countries.length &&
    countries.map((country) => (
      <Select.Option key={country.id} value={country.id}>
        {t(country.name, { ns: "Countries" })}
      </Select.Option>
    ));
  const renderCitySelectOptions =
    cities.length &&
    cities.map((cities) => (
      <Select.Option key={cities.id} value={cities.id}>
        {cities.localizedName[lang]}
      </Select.Option>
    ));

  const renderEditingComponent = useMemo(() => {
    switch (editingType) {
      case "text":
        return (
          <TextArea
            className={styles.textInput}
            defaultValue={defaultValue}
            disabled={isDisabled}
            placeholder={placeholder}
            autoSize={{ minRows: 4, maxRows: 6 }}
            onChange={(e) =>
              !isAdmin
                ? OnlyLatinLettersTextInput(
                    e.target.value,
                    formItemName,
                    form,
                    true,
                    null,
                    null,
                    true
                  )
                : e.target.value
            }
          />
        );
      default:
        return null;
    }
  }, [isDisabled]);

  return (
    <Form.Item
      name={formItemName}
      rules={[
        {
          required: true,
          message: ""
        },
        ...(rules || [])
      ]}
    >
      {(isExhibitionEditable || (isStandCurrentlyEditable && isStandEditable)) && isEditMode ? (
        editingType === "COUNTRY_SELECT" ? (
          <Select
            className={styles.countrySelect}
            defaultValue={defaultValue}
            style={{ width: "100%" }}
            disabled={isDisabled}
            onDropdownVisibleChange={(state) => {
              setOpenState(state);
            }}
            open={openState}
            size="small"
            suffixIcon={
              <DropdownIcon
                onClick={() => {
                  setOpenState((prevState) => !prevState);
                }}
              />
            }
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) => {
              return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase());
            }}
          >
            {renderSelectOptions}
          </Select>
        ) : editingType === "CITY_SELECT" ? (
          <Select
            className={styles.countrySelect}
            defaultValue={defaultValue}
            style={{ width: "100%" }}
            disabled={isDisabled}
            onDropdownVisibleChange={(state) => {
              setOpenState(state);
            }}
            open={openState}
            size="small"
            suffixIcon={
              <DropdownIcon
                onClick={() => {
                  setOpenState((prevState) => !prevState);
                }}
              />
            }
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) => {
              return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase());
            }}
          >
            {renderCitySelectOptions}
          </Select>
        ) : (
          renderEditingComponent
        )
      ) : (
        view
      )}
    </Form.Item>
  );
};

export default React.memo(EditOrViewCell);
