import React, { useEffect, useState } from "react";
import { productsAPI } from "../../../api/api";
import s from "../../FK2.1-productPage/ProductPage.module.less";
import Pagination from "../../../components/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import PreviewCategory from "./PreviewCategory";
import PreviewItem from "../PreviewItem/PreviewItem";
import PreviewDetailItemProduct from "../PreviewDetailItemProduct/PreviewDetailItemProduct";

const PreviewProduct = ({ profile, setTotalProduct }) => {
  const { t } = useTranslation(["FK2.1-productPage", "DeliveryTime", "FK3-сompanyProfile"]);
  const [productItems, setProductItems] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [total, setTotal] = useState();
  const [pages, setPages] = useState(1);
  const [idCategories, setIdCategories] = useState(null);
  const [idSubCategories, setIdSubCategories] = useState("");
  const [detailItem, setDetailItem] = useState(null);
  const [repeatSubCategory, setRepeatSubCategory] = useState(false);

  const goToTop = () => {
    window.scrollTo(0, 400);
  };

  useEffect(() => {
    productsAPI
      .getProducts(9, pages, idSubCategories, "", "", profile.profile.id, true)
      .then((res) => {
        setProductItems(res.data.items);
        setTotal(res.data.total);
        setTotalProduct(res.data.total);
        setDetailItem(null);
        if (repeatSubCategory) setRepeatSubCategory(false);
      });
    goToTop();
  }, [idSubCategories, pages, repeatSubCategory]);

  useEffect(() => {
    productsAPI.getCompanyCategories(profile.profile.id, "", true).then((res) => {
      setProductCategories(res.data);
    });
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div>
        <div className={s.title}>{t("categories")}</div>
        <div className={s.menu} style={{ paddingRight: "16px" }}>
          {productCategories.map((item, index) => (
            <PreviewCategory
              key={index}
              data={item}
              setIdCategories={setIdCategories}
              setIdSubCategories={setIdSubCategories}
              idCategories={idCategories}
              idSubCategories={idSubCategories}
              setPages={setPages}
              repeatSubCategory={repeatSubCategory}
              setRepeatSubCategory={setRepeatSubCategory}
              detailItem={detailItem}
            />
          ))}
        </div>
      </div>

      <div style={{ marginLeft: "10px" }}>
        {detailItem ? (
          <PreviewDetailItemProduct item={detailItem} />
        ) : (
          <div>
            <div className={s.title}>{t("products")}</div>
            <div
              style={{ display: "flex", flexWrap: "wrap", marginBottom: "30px", marginTop: "24px" }}
            >
              {productItems.length ? (
                productItems.map((item, index) => (
                  <PreviewItem
                    key={item.id}
                    item={Object.assign(item, item.moderations)}
                    t={t}
                    setDetailItem={setDetailItem}
                    type={"product"}
                  />
                ))
              ) : (
                <div style={{ margin: "20px 20px 20px 0" }}>{t("atTheMoment")}</div>
              )}
            </div>
            <Pagination
              style={{ justifyContent: "center", marginTop: "32px" }}
              current={pages}
              pageSize={9}
              total={total}
              setCurrent={setPages}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewProduct;
