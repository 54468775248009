import { Button, Checkbox, Col, Form, Input, Modal, Row, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Cookies from "js-cookie";
import { Footer } from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import { adminAuthAPI } from "../../api/api";
import { adminMe } from "../../store/authSlice";
import { handleRememberMe } from "../../helpers/handleRememberMe";
import { setDocumentTitle } from "../../helpers/setDocumentTitle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AdminLogin = () => {
  const { t } = useTranslation("AdminLogin");
  const { user, isAdmin } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showWrongLoginOrPass, setShowWrongLoginOrPass] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(Cookies.get("rememberMe") === "true");
  const [form] = Form.useForm();
  useEffect(() => {
    if (user && isAdmin) {
      navigate("/admin-panel");
    }
  }, [isAdmin, user]);
  const onFinish = (values) => {
    setShowWrongLoginOrPass(false);
    const { email, password } = values;
    adminAuthAPI
      .login(email, password)
      .then(() => {
        dispatch(adminMe());
      })
      .catch((e) => {
        if (e.response.status === 400 || e.response.status === 404) {
          setShowWrongLoginOrPass(true);
        } else {
          setModalMessage(t("modalMessages.error"));
          setModalIsVisible(true);
        }
      });
  };
  const onFinishFailed = (errorInfo) => {
  };
  useEffect(() => {
    form.setFieldsValue({
      rememberMe
    });
  }, [form, rememberMe]);
  useEffect(() => {
    setDocumentTitle(t("h1"));
  }, [t]);
  return (
    <>
      {modalIsVisible ? (
        <Modal
          visible={modalIsVisible}
          footer={[
            <Button key="ok" className="btn btn_v1" onClick={() => setModalIsVisible(false)}>
              OК
            </Button>
          ]}
        >
          <p>{modalMessage}</p>
        </Modal>
      ) : null}
      <Header h1={t("h1")} />
      <Row align="center">
        <Col
          md={{ span: 7 }}
          xl={{ span: 6 }}
          xxl={{ span: 5 }}
          className={`regular_block centered`}
        >
          <h2 className="mb-30">{t("h2")}</h2>
          <Form
            form={form}
            style={{ width: "100%" }}
            colon={false}
            name="login"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              email: "",
              password: "",
              rememberMe: true
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelWrap
            requiredMark={false}
            validateTrigger="onSubmit"
          >
            <Form.Item
              className="label-auto-height"
              label={<div className={`${`label`} ${`req`}`}>{t("fields.email.label")}</div>}
              name="email"
              rules={[
                { required: true, type: "email", message: t("fields.email.errors.required") }
              ]}

            >
              <Input size="large" onChange={() => form.setFields([{name:"email", errors: []}])}/>
            </Form.Item>
            <Form.Item
              className="label-auto-height"
              label={<div className={`${`label`} ${`req`}`}>{t("fields.password.label")}</div>}
              name="password"
              rules={[
                { required: true, message: t("fields.password.errors.required") },
                {
                  validator: (_, value) => {
                    if (value && value.trim().length === 0) {
                      return Promise.reject(t("fields.password.errors.required"));
                    } else {
                      return Promise.resolve();
                    }
                  }
                }
              ]}
            >
              <Input.Password size="large" style={{ backgroundColor: "#F5F7F8" }}  onChange={() => form.setFields([{name:"password", errors: []}])} />
            </Form.Item>
            <Form.Item name="rememberMe" valuePropName="checked">
              <Space>
                <Checkbox
                  className="small"
                  style={{ borderColor: "red" }}
                  checked={rememberMe}
                  onChange={() => handleRememberMe(rememberMe, setRememberMe)}
                />
                <span>{t("fields.rememberMe")}</span>
              </Space>
            </Form.Item>
            <Form.Item noStyle>
              <Button
                className={`submit w-100 mb-18`}
                type="default"
                htmlType="submit"
                size="large"
              >
                {t("buttons.login")}
              </Button>
            </Form.Item>
          </Form>

          {showWrongLoginOrPass ? (
            <div className="loginError w-100 mt-24">{t("wrongLoginOrEmail")}</div>
          ) : null}
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default AdminLogin;
