import React from "react";
import { useTranslation } from "react-i18next";
import s from "../../FS9-showcaseSelection/ShowcaseSelection.module.less";
import style from "../../FK0-mainCatalog/Categories/Categories.module.less";

const PreviewCategory = ({
  data,
  setIdCategories,
  setIdSubCategories,
  idCategories,
  idSubCategories,
  setPages,
  repeatSubCategory,
  setRepeatSubCategory,
  detailItem
}) => {
  const { t } = useTranslation(["ProductCategories", "ServiceCategories"]);

  return (
    <div>
      <div
        className={s.link}
        style={{ color: "#ea5b0c" }}
        onClick={() => {
          if (idCategories === data.id) {
            setIdCategories(null);
          } else {
            setIdCategories(data.id);
          }
        }}
      >
        {t(`productCategories.${data.type}.label`)} (
        {data?.subcategories?.map((f) => f.productsCount).reduce((p, c) => p + c)})
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{ width: "250px" }}
          className={idCategories === data.id ? `${s.active} ${s.accordion}` : s.accordion}
        >
          {data.subcategories.map((item, index) => (
            <div
              onClick={() => {
                if (idSubCategories === item.id && !repeatSubCategory && detailItem) {
                  setRepeatSubCategory(true);
                }
                setIdSubCategories(item.id);
                setPages(1);
              }}
              style={
                idSubCategories === item.id
                  ? {
                      margin: "4px 10px 4px 20px",
                      color: "#000"
                    }
                  : { margin: "4px 10px 4px 20px" }
              }
              className={style.link}
            >
              {t(`productCategories.${data.type}.${item.type}`)} ({item.productsCount})
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreviewCategory;
