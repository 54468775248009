import s from "./LanguageSwitcher.module.less";
import { useState } from "react";
import { langs, locales } from "../../api/api";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "../../store/authSlice";

export const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.auth);
  const [opened, setOpened] = useState(false);
  
  return (
    <div className={s.wrap} style={{ marginLeft: "auto" }}>
      <div className={s.current} onClick={() => setOpened(!opened)}>
        <span>{lang[0].toUpperCase() + lang.slice(1)}</span>{" "}
        <img className={`${s.chevron} ${opened ? s.opened : ""}`} src="/images/chevron-down.png" />
      </div>
      <div className={`${s.locales} ${opened ? s.opened : ""}`}>
        {locales.map((el) => (
          <div
            key={el}
            className={`${s.locale} ${lang === el ? s.active : ""}`}
            onClick={() => {
              dispatch(setLang(el));
              Cookies.set("lang", el, { expires: 365, path: "/" });
              setOpened(!opened);
            }}
          >
            {langs[el]}
          </div>
        ))}
      </div>
    </div>
  );
};
