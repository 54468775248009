import { Button, Form, Input, Select } from "antd";
import {
  adminAuthAPI,
  communicationHistoryAPI,
  companyAPI,
  mailAPI,
  profileAPI
} from "../../api/api";

import Close from "Assets/svg/Close.js";
import { Option } from "antd/lib/mentions";
import PropTypes from "prop-types";
import TextArea from "antd/lib/input/TextArea";
import styles from "./SendEmailToUser.module.less";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SendEmailToUser = ({
  handleCloseSendEmail,
  SendEmailVisible,
  setSuccessEmailSend,
  successEmailSend
}) => {
  const { t } = useTranslation("FA40-SendEmailToUser");
  const [emailTemplates, setEmailTemplates] = useState([
    { name: "Привет", template: "привет, можешь поменять здесь текст" },
    { name: "Заглушка", template: "здесь будет сообщение из шаблона" }
  ]);
  const [entity, setEntity] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    const getUserEmail = async () => {
      const userId = await companyAPI
        .getCompanyId(SendEmailVisible.companyId)
        .then((res) => res.data.userId);
      const userEmail = await profileAPI.getUserById(userId).then((res) => res.data.profile.email);
      form.setFieldsValue({ to: userEmail });
      setEntity((prev) => ({ id: userId, email: userEmail }));
    };
    getUserEmail();
    const getMyEmail = async () => {
      const myEmail = await adminAuthAPI.whoAmI().then((res) => res.data.admin.email);
      form.setFieldsValue({ from: myEmail });
    };
    getMyEmail();
  }, []);

  useEffect(() => {
    const errors = form.getFieldError();
  }, []);

  const onTemplateChange = (value) => {
    switch (value) {
      case "Привет": {
        const template = emailTemplates.find((emailTemplate) => emailTemplate.name === "Привет");
        form.setFieldsValue({
          html: template.template
        });
        return;
      }
      case "Заглушка": {
        const template = emailTemplates.find((emailTemplate) => emailTemplate.name === "Заглушка");
        form.setFieldsValue({
          html: template.template
        });
        return;
      }
    }
  };

  const handleOnFinishFailed = async () => {
  };

  const handleSubmit = async (value) => {
    setDisableSubmit(true);
    console.log(
      `${value.subject} | ${SendEmailVisible.type}-${SendEmailVisible.typeId}-${SendEmailVisible.entityType}`
    );
    const value1 = {
      ...value,
      subject:
        SendEmailVisible.type && SendEmailVisible.typeId
          ? `${value.subject} | №${SendEmailVisible.type}${SendEmailVisible.typeId}${SendEmailVisible.entityType}`
          : value.subject
    };

    try {
      if (setSuccessEmailSend) {
        mailAPI.sendEmail(value1).then(() => {
          setSuccessEmailSend(!successEmailSend);
          setDisableSubmit(false);
        });
      } else {
        mailAPI.sendEmail(value1).then(() => setDisableSubmit(false));
      }
    } catch (err) {
      setDisableSubmit(false);
    }
    handleCloseSendEmail();
  };

  const handleSubjectChange = () => {
    setDisableSubmit(!form?.getFieldsValue()?.subject?.length && true);
  };

  return (
    <div className={`${styles.emailModalStyle} modalStyle`} style={{ zIndex: 2000 }}>
      <div className={"formContainer"}>
        <div className={`${styles.wrapper} formWrapper`}>
          <div className={"formHeader"}>
            <h1 className={"formTitle"}>{t("title")}</h1>
            <Close className={"formClose"} onClick={() => handleCloseSendEmail()} />
          </div>
          <Form
            autoComplete="off"
            name="SendEmail"
            onFinishFailed={handleOnFinishFailed}
            onFinish={handleSubmit}
            form={form}
            className={styles.form}
          >
            <div className={styles.inputWrap}>
              <label className={styles.label}>{t("Fields.To")}</label>
              <Form.Item rules={[{ required: true, message: t("InputErrors.To") }]} name="to">
                <Input disabled size="large" />
              </Form.Item>
            </div>
            <div className={styles.inputWrap}>
              <label className={styles.label}>{t("Fields.Copy")}</label>
              <Form.Item name="cc">
                <Input size="large" />
              </Form.Item>
            </div>
            <div className={styles.inputWrap}>
              <label className={styles.label}>{t("Fields.Subject")}</label>
              <Form.Item
                onChange={() => handleSubjectChange()}
                name="subject"
                rules={[
                  {
                    required: true,
                    message: t("InputErrors.Subject")
                  }
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </div>
            <div className={styles.inputWrap}>
              <label className={styles.label}>{t("Fields.From")}</label>
              <Form.Item rules={[{ required: true, message: t("InputErrors.From") }]} name="from">
                <Input disabled size="large" />
              </Form.Item>
            </div>
            <div className={styles.inputWrap}>
              <label className={styles.label}>{t("Fields.Template")}</label>
              <Form.Item name="template">
                <Select
                  size="large"
                  placeholder={t("FieldPlaceHolder.Template")}
                  onChange={onTemplateChange}
                  allowClear
                >
                  {emailTemplates.map((template, index) => (
                    <Select.Option key={`${template.id}_${index}`} value={template.name}>
                      {template.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.inputWrap}>
              <label className={styles.label}>{t("Fields.Message")}*</label>
              <Form.Item
                rules={[{ required: true, message: t("InputErrors.Message") }]}
                name="html"
              >
                <TextArea rows={6} />
              </Form.Item>
            </div>

            <div className={"formButtonsContainer"}>
              <Button
                disabled={disableSubmit}
                type="primary"
                htmlType="submit"
                className={`btn btn_v2 formDisableSubmit`}
              >
                {t("Buttons.Submit")}
              </Button>
              <Button
                onClick={() => handleCloseSendEmail()}
                className={`btn btn_v8 fw-300 formCancel`}
              >
                {t("Buttons.Cancel")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
SendEmailToUser.propTypes = {
  handleCloseSendEmail: PropTypes.func,
  SendEmailVisible: PropTypes.object,
  successEmailSend: PropTypes.bool,
  setSuccessEmailSend: PropTypes.func
};

export default SendEmailToUser;
