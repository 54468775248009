import { companyAPI, exhibitionAPI } from "../api/api";
import { createSlice } from "@reduxjs/toolkit";

const companyId = {
  annualTurnover: { id: "", name: "" },
  businessCompanyName: "",
  city: "",
  companyBusinessTypes: [],
  companyKeywords: [],
  companyName: "",
  companyType: "",
  country: "",
  description: "",
  facebook: null,
  houseNumber: "",
  id: "",
  index: "",
  isVerified: null,
  keywords: "",
  linkedin: null,
  logo: "",
  mainImage: "",
  mainMarkets: [],
  membershipAssociations: "",
  moderationCredits: null,
  numberEmployees: {},
  percentageExportsTurnover: {},
  percentageImportsTurnover: {},
  phone: "",
  phone2: null,
  products: [],
  region: "",
  registrationNumber: "",
  status: "",
  street: "",
  tariffId: 1,
  twitter: null,
  type: 1,
  updatedAt: "",
  userId: 1,
  video: "",
  website: "",
  yearFoundation: "",
  youtube: null,
  companyPhotos: [],
  companyFiles: [],
  services: [],
  user: { selfBlockedAt: null, adminBlockedAt: null }
};

const exhibition = [{}];
const initialState = {
  companyId,
  exhibition,
  save: false,
  totalExhibition: 0
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyId(state, action) {
      state.companyId = action.payload;
    },
    setExhibition(state, action) {
      state.exhibition = action.payload;
    },
    setTotalExhibition(state, action) {
      state.totalExhibition = action.payload;
    },
    setSave(state, action) {
      state.save = action.payload;
    },
    setCompanyInit(state) {
      state.companyId = companyId;
    }
  }
});

export const { setCompanyId, setSave, setCompanyInit, setTotalExhibition, setExhibition } =
  companySlice.actions;

export default companySlice.reducer;

export const fetchCompanyId = (id, moderations) => (dispatch) => {
  companyAPI
    .getCompanyId(id)
    .then((res) => {
      if (res.data.moderations && moderations) {
        dispatch(setCompanyId(Object.assign(res.data, res.data.moderations)));
      } else {
        dispatch(setCompanyId(res.data));
      }
    })
    .catch(() => {
    });
};
export const fetchCompanyExhibitionStand = (id, limit, page, isAdmin) => (dispatch) => {
  exhibitionAPI
    .getCompanyExhibitionStand(id, limit, page, isAdmin)
    .then((res) => {
      dispatch(setExhibition(res.data.items));
      dispatch(setTotalExhibition(res.data.total));
    })
    .catch(() => {
    });
};
