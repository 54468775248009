import PropTypes from 'prop-types';

const Trash2 = ({ onClick, style }) => {
    return (
        <svg onClick={() => onClick()} style={style} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6009_15383)">
                <path d="M6.97521 1.58594L4.10938 4.45969M12.026 1.58594L14.8919 4.45969" stroke="#96A6B0" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.58203 6.21615C1.58203 4.75156 2.36578 4.63281 3.33953 4.63281H15.6579C16.6316 4.63281 17.4154 4.75156 17.4154 6.21615C17.4154 7.91823 16.6316 7.79948 15.6579 7.79948H3.33953C2.36578 7.79948 1.58203 7.91823 1.58203 6.21615Z" stroke="#96A6B0" strokeWidth="1.5" />
                <path d="M7.72536 11.0807V13.8911M11.367 11.0807V13.8911M2.76953 7.91406L3.88578 14.7541C4.13911 16.2899 4.7487 17.4141 7.01286 17.4141H11.7866C14.2487 17.4141 14.6129 16.3374 14.8979 14.8491L16.2279 7.91406" stroke="#96A6B0" strokeWidth="1.5" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_6009_15383">
                    <rect width="19" height="19" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

Trash2.propTypes = {
    onClick: PropTypes.func,
    style: PropTypes.object
};

export default Trash2;
