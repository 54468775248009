import {productsAPI, profileAPI, resourcesAPI, serviceAPI} from '../api/api';

import {createSlice} from '@reduxjs/toolkit';

let profile = {
  address2: '',
  address3: '',
  annualTurnover: {id: ''},
  annualTurnoverId: '',
  businessCompanyName: '',
  city: '',
  companyName: '',
  companyType: '',
  country: {id: 183, name: 'RU'},
  countryId: null,
  description: '',
  facebook: '',
  houseNumber: '',
  id: null,
  index: '',
  isVerified: true,
  linkedin: '',
  logo: '',
  membershipAssociations: '',
  numberEmployees: {id: ''},
  percentageExportsTurnover: {id: ''},
  percentageImportsTurnover: {id: ''},
  phone: '',
  phone2: '',
  region: '',
  registrationNumber: '',
  status: '',
  street: '',
  twitter: '',
  userId: null,
  video: '',
  website: '',
  yearFoundation: null,
  youtube: '',
  instagram: '',
  companyFiles: [],
  companyKeywords: [{keyword: ''}],
  companyPhotos: [],
  products: [],
  mainMarkets: [{id: '', name: '', key: 0}],
  businessTypes: [],
  services: [],
  moderationStatus: null,
};
const customProfile = {
  files: [{filename: '', description: '', id: ''}],
  filesName: [{name: '', id: '', loader: ''}],
  images: [''],
  imagesName: [{fileName: '', loader: ''}],
  showcase: [],
  savedVideo: '',
  videoYT: ''
};
const logoSet = {fileName: '', loader: ''};

const initialState = {
  profile,
  customProfile,
  logoSet,
  products: [],
  disable: false,
  resources: {
    annualTurnoverTypes: [],
    numberEmployeesTypes: [],
    percentageTurnoverTypes: [],
    countries: [],
  },
  product: [],
  productTotal: '',
  pageCount: 1,
  subcategoryId: '',
  companyId: '',
  loader: true,
};

const changeCompanyProfile = createSlice({
  name: 'changeCompanyProfile',
  initialState,
  reducers: {
    setMembershipAssociations(state, action) {
      state.profile.membershipAssociations = action.payload;
    },
    setInitialCustomProfile(state, action) {
      state.customProfile = customProfile;
    },
    setLogo(state, action) {
      state.profile.logo = action.payload;
    },
    trashLogo(state, action) {
      state.profile.logo = action.payload;
    },
    setLogoLoader(state, action) {
      state.logoSet = action.payload;
    },
    setImages(state, action) {
      state.customProfile.images = action.payload;
    },
    setImagesName(state, action) {
      state.customProfile.imagesName = action.payload;
    },
    setFile(state, action) {
      state.customProfile.files = action.payload;
    },
    setFileName(state, action) {
      state.customProfile.filesName = action.payload;
    },
    setCompanyName(state, action) {
      state.profile.companyName = action.payload;
    },
    setSubCompanyName(state, action) {
      state.profile.subCompanyName = action.payload;
    },
    setRegistrationNumber(state, action) {
      state.profile.registrationNumber = action.payload;
    },
    setBusinessCompanyName(state, action) {
      state.profile.businessCompanyName = action.payload;
    },
    setCompanyType(state, action) {
      state.profile.companyType = action.payload;
    },
    setWebsite(state, action) {
      state.profile.website = action.payload;
    },
    setDescription(state, action) {
      state.profile.description = action.payload;
    },
    setKeywords(state, action) {
      state.profile.companyKeywords = action.payload;
    },
    setStreet(state, action) {
      state.profile.street = action.payload;
    },
    setHouseNumber(state, action) {
      state.profile.houseNumber = action.payload;
    },
    setAddress2(state, action) {
      state.profile.address2 = action.payload;
    },
    setAddress3(state, action) {
      state.profile.address3 = action.payload;
    },
    setPhone(state, action) {
      state.profile.phone = action.payload;
    },
    setPhone2(state, action) {
      state.profile.phone2 = action.payload;
    },
    setCountry(state, action) {
      state.profile.country = action.payload;
    },
    setRegion(state, action) {
      state.profile.region = action.payload;
    },
    setCity(state, action) {
      state.profile.city = action.payload;
    },
    setIndex(state, action) {
      state.profile.index = action.payload;
    },
    setFaceBook(state, action) {
      state.profile.facebook = action.payload;
    },
    setModerationStatus(state, action) {
      state.profile.moderationStatus = action.payload;
    },
    setYoutube(state, action) {
      state.profile.youtube = action.payload;
    },
    setTwitter(state, action) {
      state.profile.twitter = action.payload;
    },
    setLinkedIn(state, action) {
      state.profile.linkedin = action.payload;
    },
    setAnnualTurnover(state, action) {
      state.profile.annualTurnover = action.payload;
    },
    setNumberEmployees(state, action) {
      state.profile.numberEmployees = action.payload;
    },
    setPercentageImportsTurnover(state, action) {
      state.profile.percentageImportsTurnover = action.payload;
    },
    setPercentageExportsTurnover(state, action) {
      state.profile.percentageExportsTurnover = action.payload;
    },
    setYearFoundation(state, action) {
      state.profile.yearFoundation = action.payload;
    },
    setBusinessTypes(state, action) {
      state.profile.businessTypes = action.payload;
    },
    setVideo(state, action) {
      state.profile.video = action.payload;
    },
    setVideoYT(state, action) {
      state.customProfile.videoYT = action.payload;
    },
    setSavedVideo(state, action) {
      state.customProfile.savedVideo = action.payload;
    },
    setVideoUrl(state, action) {
      state.profile.videoURl = action.payload;
    },
    setVideoLoader(state, action) {
      state.profile.videoLoader = action.payload;
    },
    setVideoLink(state, action) {
      state.profile.videoLink = action.payload;
    },
    setMainMarkets(state, action) {
      state.profile.mainMarkets = action.payload;
    },
    setShowcase(state, action) {
      state.customProfile.showcase = action.payload;
    },
    setAnnualTurnoverTypes(state, action) {
      state.resources.annualTurnoverTypes = action.payload;
    },
    setNumberEmployeesTypes(state, action) {
      state.resources.numberEmployeesTypes = action.payload;
    },
    setPercentageTurnoverTypes(state, action) {
      state.resources.percentageTurnoverTypes = action.payload;
    },
    setCountries(state, action) {
      state.resources.countries = action.payload;
    },
    setCategoriesCP(state, action) {
      state.product = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
    setMyCompany(state, action) {
      state.profile = action.payload;
    },
    setProductsTotal(state, action) {
      state.productTotal = action.payload;
    },
    setProductsPageCount(state, action) {
      state.pageCount = action.payload;
    },
    setSubcategoryId(state, action) {
      state.subcategoryId = action.payload;
    },
    setCompanyId(state, action) {
      state.companyId = action.payload;
    },
    setDisable(state, action) {
      state.disable = action.payload;
    },
    setInstagram(state, action) {
      state.profile.instagram = action.payload;
    },
    setInitialProfile(state, action) {
      state.profile = profile;
    },
    setInitialLogoSet(state, action) {
      state.logoSet = logoSet;
    },
    setLoader(state, action) {
      state.loader = action.payload;
    },
  },
});

export const {
  trashLogo,
  setCompanyName,
  setDescription,
  setIndex,
  setAddress2,
  setAddress3,
  setBusinessCompanyName,
  setAnnualTurnover,
  setCity,
  setCountry,
  setFaceBook,
  setHouseNumber,
  setPercentageExportsTurnover,
  setPercentageImportsTurnover,
  setKeywords,
  setLinkedIn,
  setLogo,
  setNumberEmployees,
  setPhone,
  setPhone2,
  setRegion,
  setRegistrationNumber,
  setStreet,
  setTwitter,
  setWebsite,
  setYearFoundation,
  setLogoLoader,
  setYoutube,
  setBusinessTypes,
  setAnnualTurnoverTypes,
  setNumberEmployeesTypes,
  setPercentageTurnoverTypes,
  setCountries,
  setMainMarkets,
  setVideo,
  setVideoYT,
  setSavedVideo,
  setVideoLoader,
  setFile,
  setFileName,
  setCategoriesCP,
  setProducts,
  setShowcase,
  setImages,
  setImagesName,
  setMyCompany,
  setMembershipAssociations,
  setProductsTotal,
  setProductsPageCount,
  setSubcategoryId,
  setCompanyId,
  setDisable,
  setInitialCustomProfile,
  setInstagram,
  setInitialProfile,
  setInitialLogoSet,
  setLoader,
  setModerationStatus,
} = changeCompanyProfile.actions;

export const fetchAnnualTurnoverTypes = () => (dispatch) => {
  resourcesAPI
    .getAnnualTurnoverTypes()
    .then((res) => {
      dispatch(setAnnualTurnoverTypes(res.data));
    })
    .catch((e) => {
    });
};
export const fetchNumberEmployeesTypes = () => (dispatch) => {
  resourcesAPI
    .getNumberEmployeesTypes()
    .then((res) => {
      dispatch(setNumberEmployeesTypes(res.data));
    })
    .catch((e) => {
    });
};
export const fetchPercentageTurnoverTypes = () => (dispatch) => {
  resourcesAPI
    .getPercentageTurnoverTypes()
    .then((res) => {
      dispatch(setPercentageTurnoverTypes(res.data));
    })
    .catch((e) => {
    });
};
export const fetchCountries = () => (dispatch) => {
  resourcesAPI
    .getCountries()
    .then((res) => {
      dispatch(setCountries(res.data));
    })
    .catch((e) => {
    });
};
export const fetchCategoriesCP = () => async (dispatch) => {
  await productsAPI
    .getProductsCategories()
    .then((res) => {
      dispatch(setCategoriesCP(res.data));
    })
    .catch((e) => {
    });
};

export const fetchSubcategoryId = (subcategoryId, companyId, limit, page) => (dispatch) => {
  productsAPI
    .getSubcategoryId(subcategoryId, companyId, limit, page)
    .then((res) => {
      dispatch(setProducts(res.data.items));
      dispatch(setProductsTotal(res.data.total));
      dispatch(setProductsPageCount(page));
      dispatch(setSubcategoryId(subcategoryId));
      dispatch(setCompanyId(companyId));
    })
    .catch((e) => {
    });
};
export const fetchServiceSubcategoryId = (subcategoryId, companyId, limit, page) => (dispatch) => {
  serviceAPI
    .getServicesById(subcategoryId, companyId, limit, page)
    .then((res) => {
      dispatch(setProducts(res.data.items));
      dispatch(setProductsTotal(res.data.total));
      dispatch(setProductsPageCount(page));
      dispatch(setSubcategoryId(subcategoryId));
      dispatch(setCompanyId(companyId));
    })
    .catch((e) => {
    });
};
export const closeWindowShowSelection = () => (dispatch) => {
  dispatch(setProducts([]));
  dispatch(setProductsTotal(''));
  dispatch(setProductsPageCount(null));
  dispatch(setSubcategoryId(null));
  dispatch(setCompanyId({}));
};


export const fetchMyCompany =
  (withModerations = false) =>
    (dispatch) => {
      dispatch(setLoader(true));
      profileAPI
        .getCompany(withModerations)
        .then((res) => {
          if (res.data.company.moderations !== null) {
            dispatch(setMyCompany(Object.assign(res.data.company, res.data.company.moderations)));
          } else {
            dispatch(setMyCompany(res.data.company));
          }
        })
        .catch((e) => {
        }).finally(() => dispatch(setLoader(false)));
    };

export const fetchStatusModeration =
  (withModerations = false) =>
    (dispatch) => {
      profileAPI
        .getCompany(withModerations)
        .then((res) => {
          dispatch(setModerationStatus(res.data.company.moderationStatus));
        })
        .catch((e) => {
        });
    };


export const clearAdditionalState = () => (dispatch) => {
  dispatch(setDisable(false));
  dispatch(setInitialCustomProfile());
  dispatch(setInitialProfile());
  dispatch(setInitialLogoSet());
  dispatch(setInitialLogo());
};

export default changeCompanyProfile.reducer;
