/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/prop-types */

import { DatePicker, Form, Space, Tooltip } from "antd";
import { TimePicker } from "antd-v5";

import EditOrViewCell from "../EditOrViewCell/EditOrViewCell";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import Sorter from "../Sorter";
import dayjs from "dayjs";
import s from "./exhibition-table-row.module.less";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useOnlyLatinLetterInputHandlerAndModal from "../../../hooks/useOnlyLatinLetterInputHandlerAndModal";
import SecondaryTableRow from "../SecondaryTableRow/SecondaryTableRow";
import Item from "antd/lib/list/Item";
import { convertDate, convertTime } from "../../../helpers/timeFormat";

const dateFormat = "YYYY-MM-DD";
const statusColor = {
  1: "#f32d2d",
  2: "black",
  3: "#249f5d",
  4: "#f32d2d",
  5: "#f32d2d"
};

const ExhibitionTableRow = ({
  item,
  handleOpenModal,
  sorting,
  handleSort,
  setStandInfo,
  isFirstElement,
  form,
  setPassageDiagram,
  clearImage,
  passageDiagram,
  isAdmin,
  setExhibitionForDelete,
  isArchive,
  setIsImageDeleted,
  errors,
  setErrors,
  isImageDeleted,
  handleEdit,
  isUserExhibition
}) => {
  const { cities } = useSelector((state) => state.resources);
  const { lang } = useSelector((state) => state.auth);
  const { editExhibitionId, editStandId, exhibitionPanel, editOnlyStand } = useSelector(
    (state) => state.exhibitions
  );
  const { t } = useTranslation([
    "BA1-EditAndViewExhibition",
    "CreateExhibitionPanel",
    "FS8-changeCompanyProfile",
    "FS100-MyExhibitions"
  ]);
  const { isEditMode } = useSelector((state) => state.exhibitions);
  const isExhibitionEditable = editExhibitionId === item.id;
  const { pathname } = useLocation();

  const [times, setTimes] = useState({
    endDate: "",
    startDate: "",
    times: []
  });

  const moderationStatusObject = {
    1: t("platformEdited", { ns: "FS100-MyExhibitions" }),
    2: t("edited", { ns: "FS100-MyExhibitions" }),
    3: t("moderated", { ns: "FS100-MyExhibitions" }),
    4: t("moderationRejected", { ns: "FS100-MyExhibitions" }),
    5: t("platformEdited", { ns: "FS100-MyExhibitions" })
  };

  useEffect(() => {
    if (!isArchive && item.startDate && item.endDate) {
      setTimes((prev) => ({
        ...prev,
        startDate: dayjs(dayjs(item.startDate).format(dateFormat)),
        endDate: dayjs(dayjs(item.endDate).format(dateFormat)),
        times: calcDaysWorkingTimeArr(item.startDate, item.endDate, item.times)
      }));
      item.times.forEach((_, i) => {
        form.setFieldsValue({
          [`rangePicker_${i}`]: [
            dayjs(dayjs(times.startDate || item.startDate?.$d).format("YYYY-MM-DD 10:00")),
            dayjs(dayjs(times.endDate || item.endDate?.$d).format("YYYY-MM-DD 18:00"))
          ]
        });
      });
    }

    if (isArchive) {
      setTimes((prev) => ({
        ...prev,
        startDate: dayjs(dayjs(item.startDate).format(dateFormat)),
        endDate: dayjs(dayjs(item.endDate).format(dateFormat)),
        times: calcDaysWorkingTimeArr(item.startDate, item.endDate, item.times)
      }));
    }
  }, [item, isEditMode]);

  const onWorkingTimeChange = (val, index) => {
    setTimes((prev) => {
      return {
        ...prev,
        times: prev.times?.map((el, i) => {
          if (i === index) {
            return {
              ...el,
              startDate: prev.startDate,
              endDate: prev.endDate,
              startTime: val ? val[0].format("HH:mm") : undefined,
              endTime: val ? val[1].format("HH:mm") : undefined,
              timeRange: val
            };
          } else {
            return el;
          }
        })
      };
    });
  };

  const calcDaysWorkingTimeArr = (startDate, endDate, curTimesArr) => {
    if (!startDate || !endDate) return [];

    const date1 = dayjs(startDate);
    const date2 = dayjs(endDate);
    const daysCount = date2.diff(date1, "day");
    const defaultTimeObj = {
      startTime: undefined,
      endTime: undefined,
      date: undefined,
      timeRange: [undefined, undefined]
    };
    const newTimesArr = Array.from({ length: daysCount + 1 }, (_, i) => {
      return {
        ...defaultTimeObj,
        date: date1.add(i, "day").format("DD.MM"),
        timeRange: [
          dayjs(dayjs().format("YYYY-MM-DD 10:00")),
          dayjs(dayjs().format("YYYY-MM-DD 18:00"))
        ]
      };
    });
    if (!isArchive) {
      newTimesArr.forEach((_, i) => {
        form.setFieldsValue({
          [`rangePicker_${i}`]: [
            dayjs(dayjs().format("YYYY-MM-DD 10:00")),
            dayjs(dayjs().format("YYYY-MM-DD 18:00"))
          ]
        });
      });
    }
    curTimesArr.forEach((el, i) => {
      if (i < newTimesArr.length) {
        newTimesArr[i] = {
          ...newTimesArr[i],
          startTime: el.startTime || "10:00",
          endTime: el.endTime || "18:00",
          timeRange: [
            dayjs(dayjs().format("YYYY-MM-DD 10:00")),
            dayjs(dayjs().format("YYYY-MM-DD 18:00"))
          ]
        };
      }
    });
    return newTimesArr;
  };

  const onEndDateChange = (val) => {
    const date = val ? dayjs(val.$d).format(dateFormat) : undefined;
    setTimes((prev) => ({
      ...prev,
      endDate: date,
      times: calcDaysWorkingTimeArr(prev.startDate, date, prev.times)
    }));
  };

  const onStartDateChange = (val) => {
    const date = val ? dayjs(val.$d).format(dateFormat) : undefined;
    setTimes((prev) => {
      const prevDate =
        prev.endDate && dayjs(prev.endDate).isBefore(date)
          ? dayjs(date).format(dateFormat)
          : prev.endDate;
      return {
        ...prev,
        startDate: date,
        endDate: prevDate,
        times: calcDaysWorkingTimeArr(date, prevDate, prev.times)
      };
    });
  };

  const handleDelete = (stand, exhibition) => {
    if (isAdmin) {
      handleOpenModal("DELETE_MODE");
      const previewExhibition = { ...exhibition, stand: stand };

      setExhibitionForDelete(previewExhibition);
      setStandInfo({
        id: stand.id,
        statsCountCompany: item.statsCountCompany,
        statsConfirmedMeetings: item.statsConfirmedMeetings,
        statsUnconfirmedMeetings: item.statsUnconfirmedMeetings
      });
    } else if (!isAdmin && !isArchive) {
      const isThereUnCorfirmedMeetings = stand.statsUnconfirmedMeetings > 0;
      const isThereCorfirmedMeetings = stand.statsConfirmedMeetings > 0;
      const previewExhibition = { ...exhibition, stand: stand };

      setExhibitionForDelete(previewExhibition);
      if (!isThereUnCorfirmedMeetings && !isThereCorfirmedMeetings) {
        handleOpenModal("DELETE_MODE_V2");
      } else if (isThereUnCorfirmedMeetings || isThereCorfirmedMeetings) {
        handleOpenModal("DELETE_MODE_V1");
      }
    } else if (isArchive) {
      handleOpenModal(`DELETE_MODE_${exhibition.exhibitionName}`);
      const previewExhibition = { ...exhibition, stand: stand };

      setExhibitionForDelete(previewExhibition);
      setStandInfo(stand.id);
    }
  };

  const getSummaryStatus = (isTooltip = false) => {
    const exhibitionStatus = item.moderationStatusId;
    if (exhibitionStatus === 4 || pathname.includes("/admin-panel/edit-and-view-exhibition")) {
      if (isTooltip) {
        return moderationStatusObject[item.moderationStatusId];
      } else {
        return item.moderationStatus?.localizedName?.[lang];
      }
    } else {
      if (isTooltip) {
        return moderationStatusObject[item.stands[0].moderationStatusId];
      } else {
        return item.stands[0].moderationStatus?.localizedName?.[lang];
      }
    }
  };

  const getStatusColor = (statusId) => {
    const exhibitionStatus = item.moderationStatusId;
    const isEditing = isEditMode && editExhibitionId === item.id && item.moderationStatusId !== 5;

    if (exhibitionStatus === 4) {
      return statusColor[4];
    } else if (
      isEditing ||
      (!pathname.includes("edit-and-view-exhibition") && isEditMode && isExhibitionEditable)
    ) {
      return statusColor[2];
    } else {
      return statusColor[statusId];
    }
  };

  const { OnlyLatinLettersTextInput, OnlyLatinLettersModal } =
    useOnlyLatinLetterInputHandlerAndModal();

  return (
    <>
      {OnlyLatinLettersModal}
      <div className={s.tableRow}>
        <div className={s.head}>
          {isAdmin ? (
            <div className={`${s.cell} ${s.big}`}>
              {t("table.creator")}
              {isFirstElement && (
                <Sorter handleSort={handleSort} name={"creator"} id={item.id} sorting={sorting} />
              )}
            </div>
          ) : (
            <div className={`${s.cell} ${s.num} ${s.small}`}>{item?.num}</div>
          )}
          <div className={`${s.cell} ${s.big}`}>
            {t("table.exhibitionName")}
            {isAdmin && isFirstElement && (
              <Sorter handleSort={handleSort} name={"exhibitionName"} sorting={sorting} />
            )}
          </div>
          <div className={`${s.cell} ${s.big}`}>{t("table.website")}</div>
          <div className={`${s.cell} ${s.big}`}>
            {t("table.country")}
            {isAdmin && isFirstElement && (
              <Sorter handleSort={handleSort} name={"country"} sorting={sorting} />
            )}
          </div>
          <div className={`${s.cell} ${s.big}`}>{t("table.city")}</div>
          <div className={`${s.cell} ${s.big}`}>{t("table.areaName")}</div>
          <div className={`${s.cell} ${s.mid}`} style={{ width: 160 }}>
            {t("table.startDate")}
            {isAdmin && isFirstElement && (
              <Sorter handleSort={handleSort} name={"startDate"} sorting={sorting} />
            )}
          </div>
          <div className={`${s.cell} ${s.mid}`} style={{ width: 180 }}>
            {t("table.endDate")}
            {isAdmin && isFirstElement && (
              <Sorter handleSort={handleSort} name={"endDate"} sorting={sorting} />
            )}
          </div>
          <div
            className={`${s.cell} ${s.xl}`}
            style={{ width: isEditMode || exhibitionPanel ? 300 : 151 }}
          >
            {t("table.times")}
          </div>
          <div className={`${s.cell} ${s.big} ${s.companies}`} style={{ width: 225 }}>
            <p>
              {isAdmin ? t("table.companies1") : t("table.meetings1")}
              {isAdmin && isFirstElement && (
                <Sorter handleSort={handleSort} name={"statsCountCompany"} sorting={sorting} />
              )}
            </p>
            <p>
              {isAdmin ? t("table.companies2") : t("table.meetings2")}
              {isAdmin && isFirstElement && (
                <Sorter
                  handleSort={handleSort}
                  name={"statsUnconfirmedMeetings"}
                  sorting={sorting}
                />
              )}
            </p>
            {isAdmin && (
              <p>
                {t("table.companies3")}
                {isAdmin && isFirstElement && (
                  <Sorter
                    handleSort={handleSort}
                    name={"statsConfirmedMeetings"}
                    sorting={sorting}
                  />
                )}
              </p>
            )}
          </div>
          <div className={`${s.cell} ${s.big}`} style={{ width: 225 }}>
            {t("table.moderationStatus")}
          </div>
          {isAdmin && (
            <div className={`${s.cell} ${s.big}`} style={{ width: 249 }}>
              {t("table.updatedAt")}
              {isFirstElement && (
                <Sorter handleSort={handleSort} name={"updatedAt"} sorting={sorting} />
              )}
            </div>
          )}
        </div>
        <div className={s.tableBody}>
          {!isAdmin && <div className={`${s.cell2} ${s.small}`}></div>}
          {isAdmin && (
            <div className={`${s.cell2} ${s.big}`}>
              <Link
                to={`/company-profile/${item.creatorId}`}
                className={s.link}
                style={{
                  pointerEvents: item.creatorType === "company" ? "all" : "none",
                  color: item.creatorType === "admin" && "black"
                }}
              >
                {item.creatorType === "company"
                  ? item?.creator.companyName
                  : `${item?.creator.firstName} ${item?.creator.lastName}`}
              </Link>
            </div>
          )}
          <div className={`${s.cell2} ${s.big}`}>
            <EditOrViewCell
              editExhibitionId={editExhibitionId}
              editStandId={editStandId}
              isDisabled={(!isAdmin && !isUserExhibition) || editOnlyStand}
              exhibitionId={item.id}
              defaultValue={item?.exhibitionName}
              form={form}
              standId={"head"}
              placeholder={t("placeholder", { ns: "CreateExhibitionPanel" })}
              view={
                <Link
                  className={s.link}
                  to={`/company-profile/${item.creatorId}/exhibition/${item.id}`}
                  target="_blank"
                  style={{
                    wordBreak: "break-all"
                  }}
                >
                  {item?.exhibitionName}
                </Link>
              }
              editingType={"text"}
              formItemName={"exhibitionName"}
              OnlyLatinLettersTextInput={OnlyLatinLettersTextInput}
            />
          </div>
          <div className={`${s.cell2} ${s.big}`} style={{ wordBreak: "break-word" }}>
            <EditOrViewCell
              editExhibitionId={editExhibitionId}
              isDisabled={(!isAdmin && !isUserExhibition) || editOnlyStand}
              editStandId={editStandId}
              defaultValue={item?.website}
              exhibitionId={item.id}
              form={form}
              rules={[
                {
                  required: true,
                  message: t("websiteFillingError", { ns: "FS8-changeCompanyProfile" })
                },
                {
                  validator: (_, value) => {
                    const httpPattern = /^https?:\/\//;
                    const urlPattern =
                      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
                    if (!value || !httpPattern.test(value)) {
                      return Promise.reject(t("websiteError", { ns: "FS8-changeCompanyProfile" }));
                    }
                    if (!value || !urlPattern.test(value)) {
                      return Promise.reject(
                        t("webSiteUnCorrected", { ns: "FS8-changeCompanyProfile" })
                      );
                    } else {
                      return Promise.resolve();
                    }
                  }
                }
              ]}
              standId={"head"}
              placeholder={t("addExhibitionLink", { ns: "CreateExhibitionPanel" })}
              view={
                <a className={s.link} href={`${item?.website}`} target="_blank" rel="noreferrer">
                  {item?.website}
                </a>
              }
              editingType={"text"}
              formItemName={"website"}
              OnlyLatinLettersTextInput={OnlyLatinLettersTextInput}
            />
          </div>
          <div className={`${s.cell2} ${s.big}`}>
            <EditOrViewCell
              editExhibitionId={editExhibitionId}
              isDisabled={(!isAdmin && !isUserExhibition) || editOnlyStand}
              editStandId={editStandId}
              form={form}
              exhibitionId={item.id}
              defaultValue={item?.countryId}
              standId={"head"}
              view={<p>{item?.country?.localizedName[lang]}</p>}
              editingType={"COUNTRY_SELECT"}
              formItemName={["exhibition", "countryId"]}
              placeholder={t("selectCountry", { ns: "CreateExhibitionPanel" })}
              OnlyLatinLettersTextInput={OnlyLatinLettersTextInput}
            />
          </div>
          <div className={`${s.cell2} ${s.big}`}>
            <EditOrViewCell
              editExhibitionId={editExhibitionId}
              isDisabled={(!isAdmin && !isUserExhibition) || editOnlyStand}
              editStandId={editStandId}
              exhibitionId={item.id}
              defaultValue={item?.cityId}
              standId={"head"}
              form={form}
              view={
                <p style={{ wordBreak: "break-all" }}>
                  {item?.city?.localizedName?.[lang] || item?.city}
                </p>
              }
              editingType={"CITY_SELECT"}
              formItemName={"cityId"}
              placeholder={t("entercity", { ns: "CreateExhibitionPanel" })}
              OnlyLatinLettersTextInput={OnlyLatinLettersTextInput}
            />
          </div>
          <div className={`${s.cell2} ${s.big}`}>
            <EditOrViewCell
              editExhibitionId={editExhibitionId}
              isDisabled={(!isAdmin && !isUserExhibition) || editOnlyStand}
              editStandId={editStandId}
              exhibitionId={item.id}
              standId={"head"}
              form={form}
              defaultValue={item?.areaName}
              view={<p style={{ wordBreak: "break-all" }}>{item?.areaName}</p>}
              editingType={"text"}
              formItemName={"areaName"}
              placeholder={t("enterAreaName", { ns: "CreateExhibitionPanel" })}
              OnlyLatinLettersTextInput={OnlyLatinLettersTextInput}
            />
          </div>
          <div className={`${s.cell2} ${s.mid}`} style={{ width: 160 }}>
            {isExhibitionEditable && isEditMode ? (
              <Form.Item
                name={["exhibition", "startDate"]}
                rules={[
                  {
                    required: true,
                    message: ""
                  }
                ]}
              >
                <DatePicker
                  className={s.datePicker}
                  disabled={(!isAdmin && !isUserExhibition) || editOnlyStand}
                  onChange={onStartDateChange}
                  format={"DD.MM.YYYY"}
                  placement="bottomRight"
                  showToday={false}
                  disabledDate={(current) =>
                    (times.endDate && current > dayjs(times.endDate).endOf("day")) ||
                    (current && current < dayjs().startOf("day"))
                  }
                />
              </Form.Item>
            ) : (
              <p className={s.cellContent}>{dayjs(item?.startDate).format("DD.MM.YYYY")}</p>
            )}
          </div>
          <div className={`${s.cell2} ${s.mid}`} style={{ width: 180 }}>
            {isExhibitionEditable && isEditMode ? (
              <Form.Item
                name={["exhibition", "endDate"]}
                rules={[
                  {
                    required: true,
                    message: ""
                  }
                ]}
              >
                <DatePicker
                  className={s.datePicker}
                  onChange={onEndDateChange}
                  disabled={(!isAdmin && !isUserExhibition) || editOnlyStand}
                  format={"DD.MM.YYYY"}
                  placement="bottomRight"
                  showToday={false}
                  disabledDate={(current) => {
                    return (
                      (current &&
                        times.startDate &&
                        current < dayjs(times.startDate).startOf("day")) ||
                      (current && current < dayjs().startOf("day"))
                    );
                  }}
                />
              </Form.Item>
            ) : (
              <p className={s.cellContent}>{dayjs(item?.endDate).format("DD.MM.YYYY")}</p>
            )}
          </div>
          <div
            className={`${s.cell2} ${s.xl}`}
            style={{ width: isEditMode || exhibitionPanel ? 300 : 151 }}
          >
            {times.times
              .sort((a, b) => a.id - b.id)
              ?.map((el, i) => {
                if (!isArchive) {
                  form.setFieldsValue({
                    [`rangePicker_${i}`]: [
                      dayjs(
                        dayjs(el.startDate?.$d || el.startDate).format(
                          `YYYY-MM-DD ${el.startTime || "10:00"}`
                        )
                      ),
                      dayjs(
                        dayjs(el.endDate?.$d || el.endDate).format(
                          `YYYY-MM-DD ${el.endTime || "18:00"}`
                        )
                      )
                    ]
                  });
                }
                return isEditMode && isExhibitionEditable ? (
                  <Form.Item
                    name={`rangePicker_${i}`}
                    key={`${i}_`}
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <div className="d-flex align-items-center" style={{ width: 270 }}>
                      {el.date}:
                      <TimePicker.RangePicker
                        className={s.datePicker}
                        format={"HH:mm"}
                        placeholder={[t("startTime"), t("endTime")]}
                        style={{ marginLeft: "5px" }}
                        disabled={(!isAdmin && !isUserExhibition) || editOnlyStand}
                        minuteStep={5}
                        defaultValue={[
                          dayjs(
                            dayjs(el.startDate?.$d || el.startDate).format(
                              `YYYY-MM-DD ${el.startTime || "10:00"}`
                            )
                          ),
                          dayjs(
                            dayjs(el.endDate?.$d || el.endDate).format(
                              `YYYY-MM-DD ${el.endTime || "18:00"}`
                            )
                          )
                        ]}
                        onChange={(val) => {
                          onWorkingTimeChange(val, i);
                        }}
                      />
                    </div>
                  </Form.Item>
                ) : (
                  <p key={el.id}>
                    {el.date}-{el.startTime}:{el.endTime}
                  </p>
                );
              })}
          </div>
          <div className={`${s.cell2} ${s.big}`} style={{ width: 225 }}>
            {!isAdmin ? (
              <p className={s.cellContent}>
                {item.stands[0].statsUnconfirmedMeetings}/{item.stands[0].statsConfirmedMeetings}
              </p>
            ) : (
              <p className={s.cellContent}>
                {item?.statsCountCompany}/{item?.statsUnconfirmedMeetings}/
                {item?.statsConfirmedMeetings}
              </p>
            )}
          </div>
          <div
            className={`${s.cell2} ${s.big}`}
            style={{ paddingLeft: !isAdmin && lang === "en" ? 10 : 16, width: 225 }}
          >
            <Space>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <p
                  style={
                    !isArchive
                      ? {
                          color:
                            !isAdmin &&
                            isEditMode &&
                            editExhibitionId === item.id &&
                            item.moderationStatusId !== 5
                              ? "black"
                              : getStatusColor(
                                  pathname.includes("/admin-panel/edit-and-view-exhibition")
                                    ? item.moderationStatusId
                                    : item?.stands?.[0]?.moderationStatusId
                                ),
                          width: "100%"
                        }
                      : {}
                  }
                >
                  {isEditMode && editExhibitionId === item.id && item.moderationStatusId !== 5
                    ? t("edititingProccessStatus")
                    : getSummaryStatus()}
                  <Tooltip
                    placement="bottomLeft"
                    title={
                      isEditMode && editExhibitionId === item.id && item.moderationStatusId !== 5
                        ? moderationStatusObject[2]
                        : getSummaryStatus(true)
                    }
                  >
                    <QuestionCircleOutlined
                      style={{
                        position: "relative",
                        cursor: "help",
                        alignSelf: "end",
                        marginBottom: 5,
                        marginLeft: 5,
                        color: "#474747"
                      }}
                    />
                  </Tooltip>
                </p>
              </div>
            </Space>
          </div>
          {isAdmin && (
            <div className={`${s.cell2} ${s.big}`} style={{ width: 256, paddingLeft: 16 }}>
              {convertDate(item.updatedAt)} {convertTime(item.updatedAt)}
              {item?.edit && <p>{item.edit?.email}</p>}
            </div>
          )}
        </div>
        {!isAdmin || pathname.includes("/admin-panel/users-list") ? (
          item.stands?.map((stand) => (
            <SecondaryTableRow
              stand={stand}
              isOnlyExhibition={false}
              key={Item.id}
              OnlyLatinLettersTextInput={OnlyLatinLettersTextInput}
              item={item}
              t={t}
              form={form}
              errors={errors}
              setErrors={setErrors}
              setIsImageDeleted={setIsImageDeleted}
              isImageDeleted={isImageDeleted}
              passageDiagram={passageDiagram}
              handleDelete={handleDelete}
              setPassageDiagram={setPassageDiagram}
              handleOpenModal={handleOpenModal}
              clearImage={clearImage}
              isStandEditable={editStandId === stand.id}
              handleEdit={handleEdit}
              isUserExhibition={isUserExhibition}
              isArchive={isArchive}
            />
          ))
        ) : (
          <SecondaryTableRow
            isOnlyExhibition={isAdmin}
            key={Item.id}
            OnlyLatinLettersTextInput={OnlyLatinLettersTextInput}
            item={item}
            setErrors={setErrors}
            errors={errors}
            t={t}
            form={form}
            handleDelete={handleDelete}
            handleOpenModal={handleOpenModal}
            handleEdit={handleEdit}
            isUserExhibition={isUserExhibition}
            isArchive={isArchive}
          />
        )}
      </div>
    </>
  );
};

export default ExhibitionTableRow;
