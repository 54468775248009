/* eslint-disable react/prop-types */

import { Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState, useRef } from "react";

import Img from "Assets/svg/Img";
import axios from "axios";
import { backendUrl } from "Api/api";
import { mediaUrl } from "../../api/api";
import s from "./AddUser.module.less";
import { setPhoto } from "Store/addUserSlice";
import style from "../../pages/FS8-changeCompanyProfile/ChangeCompanyProfile.module.less";
import { useTranslation } from "react-i18next";
import Trash from "../../assets/svg/trash";
import DropdownIcon from "Components/DropdownIcon/DropdownIcon";
import AddAndCropLogo from "../AddAndCropLogo/AddAndCropLogo";
import { useSelectCropImage } from "../../hooks/useSelectCropImage";

const selectStyles = { width: 290, fontSize: 14 };
const inputStyles = { width: 290, backgroundColor: "#F5F7F8" };

const UserInfo = ({ form }) => {
  const { t } = useTranslation(["FA2.1-AddUser", "FS8-changeCompanyProfile"]);
  const { photo } = useSelector((state) => state.addUser);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [loadingProfileImage, setLoadingProfileImage] = useState(false);
  const refContainer = useRef(null);
  const imageRef = useRef(null);

  const { img, setImg, setVisibleImage, visibleImage, onSelectFile } = useSelectCropImage();

  const onAddImage = (file) => {
    setLoadingProfileImage(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("originalname", file.name);
    axios({
      method: "post",
      url: `${backendUrl}/upload/image`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setPhoto(res.data?.filename));
          form.setFieldsValue({ photo: res.data?.filename });
        }
      })
      .finally(() => {
        setLoadingProfileImage(false);
      });
  };

  const typeOptions = useMemo(
    () =>
      ["both", "buyer", "seller"].map((type, idx) => ({
        label: t(type),
        value: idx
      })),
    [t]
  );

  const kindOptions = useMemo(
    () =>
      ["mr", "mrs"].map((type, idx) => ({
        label: t(type),
        value: idx
      })),
    [t]
  );

  const setPasswordFC = (num, value) => {
    const reg = /[а-я,А-Я]/g;
    if (num === 1) {
      setPassword(value.replace(reg, ""));
      form.setFieldsValue({ password: value.replace(reg, "") });
    }
    if (num === 2) {
      setConfirmPassword(value.replace(reg, ""));
      form.setFieldsValue({ confirmPassword: value.replace(reg, "") });
    }
  };

  const setEmailFC = (value) => {
    const reg = /[а-я,А-Я]/g;
    form.setFieldsValue({ email: value.replace(reg, "") });
  };

  return (
    <section ref={refContainer}>
      <Form.Item
        name={["company", "type"]}
        rules={[{ required: true, message: t("errors.userType") }]}
        style={{ marginBottom: 32 }}
        label={<span className={s.label_userType}>{t("userType")}*</span>}
      >
        <Select
          suffixIcon={<DropdownIcon className={s.icon_pointer} />}
          getPopupContainer={() => refContainer.current}
          options={typeOptions}
          size="large"
          style={selectStyles}
          placeholder={t("both")}
        />
      </Form.Item>

      <h2 className={s.title}>{t("generalInfo")}</h2>

      <div className={s.wrapper}>
        <div>
          <Form.Item
            name={["user", "gender"]}
            label={<span className={s.label_1}>{t("kind")}</span>}
          >
            <Select
              getPopupContainer={() => refContainer.current}
              options={kindOptions}
              size="large"
              style={selectStyles}
              placeholder={t("treatment")}
              suffixIcon={<DropdownIcon className={s.icon_pointer} />}
            />
          </Form.Item>

          <Form.Item
            name={["user", "firstName"]}
            rules={[
              { required: true, message: t("errors.name") },
              { pattern: new RegExp(/[a-zA-Zа-яА-Я]+$/), message: t("errors.correctFirstName") }
            ]}
            label={<span className={s.label_1}>{t("name")}*</span>}
          >
            <Input style={inputStyles} size="large" />
          </Form.Item>

          <Form.Item
            name={["user", "lastName"]}
            rules={[
              { required: true, message: t("errors.surname") },
              { pattern: new RegExp(/[a-zA-Zа-яА-Я]+$/), message: t("errors.correctLastName") }
            ]}
            label={<span className={s.label_1}>{t("surname")}*</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["user", "email"]}
            rules={[
              { required: true, message: t("errors.email") },
              { type: "email", message: t("errors.validEmail") }
            ]}
            value
            label={<span className={s.label_1}>{t("email")}*</span>}
          >
            <Input
              style={{ background: "#f5f7f8", width: "290px" }}
              type="email"
              size="large"
              onChange={(e) => setEmailFC(e.target.value, "email", form, true)}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name={["user", "spareEmail"]}
            rules={[{ type: "email", message: t("errors.validEmail") }]}
            label={<span className={s.label_2}>{t("fallbackEmail")}</span>}
          >
            <Input type="email" size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["user", "jobTitle"]}
            rules={[
              { pattern: new RegExp(/[a-zA-Zа-яА-я]+$/), message: t("errors.correctJobTitle") }
            ]}
            label={<span className={s.label_2}>{t("position")}</span>}
          >
            <Input size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={["user", "password"]}
            rules={[
              { required: true, message: t("password") },
              { pattern: new RegExp(/[^а-я,А-Я]/), message: t("password") }
            ]}
            label={<span className={s.label_2}>{t("password")}*</span>}
            className={s.passwordInput}
          >
            <Input.Password size="large" style={inputStyles} />
          </Form.Item>

          <Form.Item
            name={"confirmPassword"}
            dependencies={["user", "confirmPassword"]}
            rules={[
              { required: true, message: t("confirmPassword") },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(["user", "password"]) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t("errors.passwordNotEqual"));
                }
              })
            ]}
            className={s.passwordInput}
            label={<span className={s.label_2}>{t("confirmPassword")}*</span>}
          >
            <Input.Password
              value={confirmPassword}
              onChange={(e) => setPasswordFC(2, e.target.value)}
              size="large"
              style={inputStyles}
            />
          </Form.Item>
        </div>
      </div>
      <div className={`${s.imgContainer} ${photo ? s.imgContainerWithUserPhoto : ""}`}>
        {photo ? (
          <img
            style={{ height: "80px", width: "80px", objectFit: "contain" }}
            className={s.uploads}
            src={`${mediaUrl}${photo}`}
            alt="photo"
          />
        ) : (
          <Img />
        )}
        <div style={{ paddingRight: 25 }}>
          <label className={s.uploader}>
            <div className={style.wrapperPhoto}>
              <div style={{ marginLeft: "0" }} className={style.logoTitle}>
                {t("profilePicture")}
              </div>
              <div style={{ color: "#478ECC", textDecoration: "underline" }}>{t("addImage")}</div>
              <div style={{ marginBottom: "0" }} className={style.keywordsDescription}>
                {t("format", { ns: "FS8-changeCompanyProfile" })}
              </div>
            </div>
            <input
              style={{ display: "none" }}
              type="file"
              ref={imageRef}
              accept=".jpg,.jpeg,.png"
              onChange={onSelectFile}
            />
          </label>
        </div>
        {loadingProfileImage ? (
          <p className={s.loading}>{t("loading", { ns: "FS8-changeCompanyProfile" })}</p>
        ) : (
          ""
        )}
        {photo && !loadingProfileImage ? (
          <div>
            <p className={`${s.loading} ${s.loadingSuccess}`}>
              {t("loaded", { ns: "FS8-changeCompanyProfile" })}
            </p>
            <button
              onClick={() => {
                imageRef.current.value = "";
                dispatch(setPhoto(""));
              }}
              style={{
                border: "none",
                backgroundColor: "inherit",
                position: "absolute",
                top: "10px",
                right: "20px"
              }}
            >
              <Trash />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <AddAndCropLogo
        visible={visibleImage}
        setVisible={setVisibleImage}
        saveCropLogo={onAddImage}
        img={img}
        setImg={setImg}
      />
    </section>
  );
};

export default UserInfo;
