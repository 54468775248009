import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../components/Pagination/Pagination";
import ProductItem from "../FK3-сompanyProfile/About/ProductItem/ProductItem";
import ShowcaseSelectionItem from "../FS9-showcaseSelection/ShowcaseSelectionItem";
import { productsAPI } from "../../api/api";
import s from "../FK2.1-productPage/ProductPage.module.less";
import { useTranslation } from "react-i18next";

const CompanyProduct = () => {
  const { companyCategories } = useSelector((state) => state.catalog);
  const [idCategories, setIdCategories] = useState(null);
  const { companyId } = useSelector((state) => state.company);
  const { t } = useTranslation(["FK2.1-productPage", "DeliveryTime", "FK3-сompanyProfile"]);
  const dispatch = useDispatch();
  const [pages, setPages] = useState(1);

  const pageSize = 20;
  const [data, setData] = useState([{ incoterms: [{ name: "" }] }]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (companyId.id){
      productsAPI
        .getProductArr(pageSize, pages, companyId.id, null)
        .then((res) => {
          setData(res.data.items);
          setTotal(res.data.total);
        })
        .catch((e) => {
        });
    }
  }, [companyId.id, dispatch, pages]);

  useEffect(() => {
    if (pages !== 1) window.scrollTo(0, 500);
  }, [pages])

  return (
    <div>
      <div style={{ display: "flex", minHeight: '400px' }}>
        <div>
          <div className={s.title}>{t("categories")}</div>
          <div className={s.menu}>
            {companyCategories.length ? companyCategories.map((item, index) => (
              <ShowcaseSelectionItem
                orange={true}
                key={index}
                company={companyId}
                item={item}
                setIdCategories={setIdCategories}
                idCategories={idCategories}
              />
            )) : <div style={{ margin: '20px 20px 20px 0' }}>{t("atTheMoment")}</div>}
          </div>
        </div>
        <div>
          <div className={s.title}>{t("products")}</div>
          <div
            style={{ display: "flex", flexWrap: "wrap", marginBottom: "30px", marginTop: "24px" }}
          >
            {data.map((item, index) => (
              <ProductItem
                item={item}
                key={index}
                title={item.productName}
                img={item.image}
                price={item.unitPrice}
                currency={item.currency && item.currency.name}
                wrapStyle={{ margin: "0" }}
                navLink={`/company-profile/${item.companyId}/product/${item.id}`}
              />
            ))}
          </div>
          <Pagination
            style={{ justifyContent: "center", marginTop: "32px" }}
            current={pages}
            pageSize={pageSize}
            total={total}
            setCurrent={setPages}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyProduct;
