import { authAPI } from "Api/api";
import { createSlice } from "@reduxjs/toolkit";

const normalizeData = (data) => ({
  tariffId: data?.company?.tariffId,
  basicCredits: data?.company?.basicCredits,
  moderationCredits: data?.company?.moderationCredits
});

const initialState = {
  tariffId: null,
  basicCredits: null,
  moderationCredits: null
};

const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    setData(state, action) {
      return normalizeData(action.payload);
    }
  }
});

export const { setData } = balanceSlice.actions;

export const getBalance = () => (dispatch) => {
  authAPI
    .whoAmI()
    .then((res) => {
      dispatch(setData(res.data.user));
    })
    .catch(console.log);
};

export default balanceSlice.reducer;
