import axios from "axios";
import { backendUrl } from "./api";

import dayjs from "dayjs";
import { addDay } from "../helpers/addDay";
import { removeDay } from "../helpers/removeDay";
import { convertQueryParameters } from "../helpers/convertQueryParameters";

const instance = axios.create({
  baseURL: `${backendUrl}/meeting/`
});

const GET = {
  getAllMeetingsBuyer: (onlineMeetingModerator, initiator) => (config) =>
    instance.get(
      `all-meetings-buyer?onlineMeetingModerator=${onlineMeetingModerator}&initiator=${initiator}`,
      config
    ),

  getAllMeetingsSeller: (onlineMeetingModerator, initiator) => (config) =>
    instance.get(
      `all-meetings-seller?onlineMeetingModerator=${onlineMeetingModerator}&initiator=${initiator}`,
      config
    ),

  searchMeetings: (name) => (value) => instance.get(`search-all?${name}=${value}`),

  searchMeetingsSeller: (name, onlineMeetingModerator, initiator) => (value) =>
    instance.get(`search?${name}=${value}`, {
      params: { onlineMeetingModerator, initiator, isSeller: true }
    }),

  searchMeetingsBuyer: (name, onlineMeetingModerator, initiator) => (value) =>
    instance.get(`search?${name}=${value}`, {
      params: { onlineMeetingModerator, initiator, isSeller: false }
    }),

  getAllMeetings: (params) => {
    const {
      timesRange,
      myId,
      partnerId,
      isArchive,
      sortColumn,
      sortDirection,
      filters,
      currentPage,
      pageSize
    } = params;
    const formatedStartDate = removeDay(timesRange[0], 1);
    const formatedEndDate = addDay(timesRange[1], 1);

    const isPartnerId = myId ? `&myId=${myId}` : "";
    const isDateRange =
      timesRange[0] && timesRange[1]
        ? `dateFrom=${formatedStartDate}&dateTo=${formatedEndDate}`
        : "";
    const isUserId = partnerId ? `&userId=${partnerId}` : "";
    const isArchiveQuery = isArchive ? `&isArchive=true` : "";
    const filterParams = convertQueryParameters(filters);
    const limit = pageSize ? `&limit=${pageSize}` : "";
    const page = currentPage ? `&page=${currentPage}` : "";
    const sortCol = sortColumn ? `&sortColumn=${sortColumn}` : "";
    const sortDirec = sortDirection ? `&sortDirection=${sortDirection}` : "";

    return instance.get(
      `all-meetings-user?${isDateRange}${isPartnerId}${isUserId}${filterParams}${page}${limit}${isArchiveQuery}${sortCol}${sortDirec}`
    );
  },

  transfferMeetings: (startDate, endDate, id) => {
    const data = {
      dateFrom: startDate,
      dateTo: endDate
    };
    return instance.patch(`time-change/${id}`, data);
  },

  changeDescribeStatus: (meetingId, statusId) => {
    return instance.patch(`describe-status/${meetingId}`, { statusId: statusId });
  }
};

export const meetingApi = { ...GET };
