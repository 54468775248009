import { Badge, Button, DatePicker, Form } from 'antd';
import { DebounceSearchSelect, dateStyles, styles } from 'Components/Search';

import PropTypes from 'prop-types';
import { dateFormat } from 'Helpers/timeDate';
import { dialogueApi } from 'Api';
import s from 'Components/Search/Search.module.less';
import style from './DialogsSearch.module.less';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DialogsSearch = ({
  onFiltersChange,
  onFiltersSubmit,
  onFiltersEmpty,
  onShowNew,
  unreadMessagesTotal
}) => {
  const { t } = useTranslation("FS19-Dialogs");
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [form] = Form.useForm();

  const saveCompanies = (data) => {
    setCompaniesOptions(
      data.map(({ id, companyName }) => ({
        label: companyName,
        value: id
      }))
    );
  };

  const onReset = () => {
    onFiltersEmpty();
    form.resetFields();
  };

  return (
    <section className={s.section}>
      <Form
        form={form}
        labelWrap
        autoComplete="off"
        colon={false}
        labelAlign="left"
        layout="inline"
        onValuesChange={onFiltersChange}
        onFinish={onFiltersSubmit}
        style={{ flexDirection: "column" }}
      >
        <div className={s.scrollable} style={{ marginBottom: 32 }}>
          <div className={s.filters} style={{ minWidth: 1010 }}>
            <Form.Item name="companiesIds" label={t("company")} style={{ ...styles, maxWidth: 280 }}>
              <DebounceSearchSelect
                mode="multiple"
                search={dialogueApi.searchCompanies}
                store={saveCompanies}
                options={companiesOptions}
              />
            </Form.Item>
            <Form.Item name={["date", "dateFrom"]} label={t("dateFrom")} style={dateStyles}>
              <DatePicker
                style={{ display: "flex", minWidth: 170 }}
                format={dateFormat}
                placeholder={t("all")}
                placement="bottomRight"
                showToday={false}
              />
            </Form.Item>
            <Form.Item name={["date", "dateTo"]} label={t("dateTo")} style={dateStyles}>
              <DatePicker
                style={{ display: "flex", minWidth: 170 }}
                format={dateFormat}
                placeholder={t("all")}
                placement="bottomRight"
                showToday={false}
              />
            </Form.Item>
          </div>
        </div>

        <div className={`s.scrollable mt-12`}>
          <div className={s.controls}>
            <Button className={`btn btn_v3 ${s.showNew}`} onClick={onShowNew}>
              {t("showNew")}
              <Badge
                count={unreadMessagesTotal}
                className={style.badge}
              />
            </Button>
            <Button className={`btn btn_v8 ${s.reset}`} onClick={onReset}>
              {t("reset")}
            </Button>
            <Button className={s.show} htmlType="submit">
              {t("display")}
            </Button>
          </div>
        </div>
      </Form>
    </section>
  );
};

DialogsSearch.propTypes = {
  onFiltersChange: PropTypes.func,
  onFiltersSubmit: PropTypes.func,
  onFiltersEmpty: PropTypes.func,
  onShowNew: PropTypes.func,
  onShowTechSupport: PropTypes.func,
  unreadMessagesTotal: PropTypes.number
};

export default DialogsSearch;
