import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataExhibitionTable: {
    isVisible: false,
    targetId: ""
  }
};

const exhibitionTableSlice = createSlice({
  name: "exhibitionTable",
  initialState,
  reducers: {
    setDataexhibitionTable(state, action) {
      state.dataExhibitionTable = action.payload;
    }
  }
});

export const { setDataexhibitionTable } = exhibitionTableSlice.actions;

export const exhibitionTableSliceReducer = exhibitionTableSlice.reducer;
