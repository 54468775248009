import { dialogueApi } from 'Api';
import DialogsSearch from 'Components/Dialogs/DialogsSearch';
import DialogsTable from 'Components/Dialogs/DialogsTable';
import PageSizePicker from 'Components/PageSizePicker/PageSizePicker';
import Paginator from 'Components/Paginator/Paginator';
import useSearch from 'Hooks/useSearch';
import { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useOutletContext } from 'react-router-dom';
import { setData } from 'Store/dialogsSlice';

import s from './Dialogs.module.less';

const Dialogs = () => {
  const { t } = useTranslation("FS19-Dialogs");
  const { setTitle } = useOutletContext();
  const dispatch = useDispatch();
  const { data, total } = useSelector((state) => state.dialogs);
  const { user } = useSelector((state) => state.auth);
  const { unreadMessagesTotal } = useSelector((state) => state.notifications);
  const { state } = useLocation();

  useLayoutEffect(() => {
    setTitle(t("title"));
  }, [t, setTitle]);

  useEffect(() => {
    if (!state?.showNew || (state?.showNew && unreadMessagesTotal === 0)) {
      onChangeApi(dialogueApi.getAllDialogues);
    } else {
      showNew();
    }
  }, [state, unreadMessagesTotal]);

  const showNew = () => {
    onChangeApi(dialogueApi.getNewDialogues, false);
    tmpFunc();
  };

  const showTechSupport = () => {
    dialogueApi.getTechSupport().then((res) => {
      setDialogues(res.data);
    });
    tmpFunc();
  };

  const setDialogues = (data) => {
    dispatch(setData({ ...data, userId: user?.company?.id }));
  };

  const [
    { pagination, loading },
    {
      fetchData,
      onChangeApi,
      onFiltersChange,
      onFiltersSubmit,
      onFiltersEmpty,
      setPageSize,
      setCurrentPage,
      setSorting,
      tmpFunc
    }
  ] = useSearch(dialogueApi.getAllDialogues, setDialogues, "submit", true, null, {
    sortColumn: "lastMessage",
    sortDirection: "DESC"
  });

  return (
    <section className={s.section}>
      <DialogsSearch
        onFiltersChange={onFiltersChange}
        onFiltersSubmit={onFiltersSubmit}
        onFiltersEmpty={onFiltersEmpty}
        onShowNew={showNew}
        onShowTechSupport={showTechSupport}
        unreadMessagesTotal={unreadMessagesTotal}
      />
      <PageSizePicker setPageSize={setPageSize} style={{ marginTop: 30, marginBottom: 50 }} />
      <div className={s.scrollable}>
        <DialogsTable
          pagination={pagination}
          loading={loading}
          dataSource={data}
          setSorting={setSorting}
          fetchData={fetchData}
        />
      </div>
      <Paginator
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        total={total}
        style={{ margin: "40px auto 0" }}
      />
    </section>
  );
};

export default Dialogs;
