import React, { useEffect, useState } from "react";
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";
import { authAPI } from "../../api/api";
import { setDocumentTitle } from "../../helpers/setDocumentTitle";

const ForgotPassword = () => {
  const { t } = useTranslation("FS30-forgotPassword");
  const [successfully, setSuccessfully] = useState(false);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  useEffect(() => {
    setDocumentTitle(t("title"));
  }, [t]);
  const onFinish = ({ email }) => {
    authAPI
      .resetPassword(email)
      .then((res) => {
        setSuccessfully(true);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setError(t("errors400"));
        } else if (e.response.status === 404) {
          setError(t("errors404"));
        }
      });
  };

  return (
    <>
      <Header h1={t("title")} />
      <Row align="center">
        <Col
          md={{ span: 10 }}
          xl={{ span: 9 }}
          xxl={{ span: 7 }}
          className={`regular_block centered`}
        >
          {successfully ? (
            <div
              style={{
                color: "#474747",
                fontWeight: "500",
                fontSize: "18px",
                padding: "18px 0px 24px",
                textAlign: "center"
              }}
            >
              {t("txt")}
            </div>
          ) : (
            <Form
              style={{ width: "100%" }}
              colon={false}
              name="login"
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              autoComplete="off"
              labelAlign="left"
              labelWrap
              requiredMark={false}
              validateTrigger="onSubmit"
            >
              <Form.Item
                style={{}}
                className="label-auto-height"
                label={
                  <div style={{ marginTop: "12px" }} className={`${`label`} ${`req`}`}>
                    {t("inputTitle")}
                  </div>
                }
                name="email"
                onChange={() => setError("")}
                rules={[{ required: true, type: "email", message: t("errors") }]}
              >
                <Input
                  size="large"
                  style={{ border: "none" }}
                  onChange={() => form.setFields([{ name: "email", errors: [] }])}
                />
              </Form.Item>

              <div style={{ color: "#474747", fontWeight: "300", padding: "18px 0px 24px" }}>
                {t("description")}
              </div>

              <Form.Item noStyle>
                <Button
                  className={`submit`}
                  type="default"
                  htmlType="submit"
                  size="large"
                  style={{ float: "right", padding: "12px 54px" }}
                >
                  {t("btn")}
                </Button>
              </Form.Item>
            </Form>
          )}
          {error ? <div className="loginError w-100 mt-24">{t(error)}</div> : null}
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default ForgotPassword;
