import React, { useState } from "react";

import Paginator from "../../components/Paginator/Paginator";
import RecordsOfDeletionsSearch from "Components/RecordsOfDeletions/RecordsOfDeletionsSearch";
import RecordsOfDeletionsTable from "../../components/RecordsOfDeletions/RecordsOfDeletionsTable";
import { recordsAPI } from "../../api/api";
import s from "../FA35-Promocodes/Promocodes.module.less";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import useSearch from "Hooks/useSearch";
import { useTranslation } from "react-i18next";

export const RecordsOfDeletions = () => {
  const { setTitle } = useOutletContext();
  const { t } = useTranslation(["FA100-RecordsOfDeletions", "FA9-RequestsList"]);
  const [records, setRecords] = useState({
    total: 0,
    items: []
  });

  useEffect(() => {
    setTitle(t("title"));
  }, [t]);

  const [
    { pagination, loading, error, sorting },
    { onFiltersChange, onFiltersSubmit, onFiltersEmpty, fetchData, setCurrentPage, setSorting }
  ] = useSearch(recordsAPI.getDeletionHistory, setRecords, "submit", true);

  return (
    <div className={`regular_block`}>
      <h3 style={{ marginBottom: 24 }}>
        {t("filter_table_search_title", { ns: "FA9-RequestsList" })}
      </h3>
      <RecordsOfDeletionsSearch
        onFiltersChange={onFiltersChange}
        onFiltersSubmit={onFiltersSubmit}
        onFiltersEmpty={onFiltersEmpty}
      />
      <div className={s.scrollable}>
        <RecordsOfDeletionsTable
          pagination={pagination}
          dataSource={records.items}
          loading={loading}
          setSorting={setSorting}
          fetchData={fetchData}
          sorting={sorting}
        />
      </div>
      <Paginator
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        total={records.total}
        style={{ margin: "24px auto 0" }}
      />
    </div>
  );
};
