import { createSlice } from "@reduxjs/toolkit";
import { notificationsAPI } from "Api/api";

const normalizeData = ({
  orders,
  meetings,
  moderatedMeetings,
  moderations,
  messages,
  complaints,
  problems,
  all,
  productsServices,
  company,
  exhibitions,
  inquiries
}) => ({
  unprocessedOrdersSeller: orders?.seller?.incoming?.unprocessed ?? 0,
  unprocessedIncomingMeetingsSeller: meetings?.seller?.incoming?.unprocessed ?? 0,
  unprocessedIncomingModeratedMeetingsSeller: moderatedMeetings?.seller?.incoming?.unprocessed ?? 0,
  unprocessedIncomingMeetingsBuyer: meetings?.buyer?.incoming?.unprocessed ?? 0,
  unprocessedIncomingModeratedMeetingsBuyer: moderatedMeetings?.buyer?.incoming?.unprocessed ?? 0,
  unreadMessagesTotal: messages?.incoming?.unread ?? 0,
  unreadAdminComplaints: complaints?.unprocessed ?? 0,
  unreadAdminTechIssues: problems?.unprocessed ?? 0,

  allBuyer: all?.buyer ?? 0,
  allSeller: all?.seller ?? 0,
  productsServices: productsServices?.seller?.incoming?.unprocessed ?? 0,
  company: company?.incoming?.unread ?? 0,
  exhibitions: exhibitions?.incoming?.unread ?? 0,

  unprocessedAdminMeetings: meetings?.unprocessed ?? 0,
  unprocessedAdminModeratedMeetings: moderatedMeetings?.unprocessed ?? 0,

  unprocessedModerationChanges: moderations?.unprocessed ?? 0,
  unprocessedInquiries: inquiries?.unprocessed ?? 0
});

const initialState = {
  notifications: {
    unprocessedOrdersSeller: 0,
    unprocessedIncomingMeetingsSeller: 0,
    unprocessedIncomingModeratedMeetingsSeller: 0,
    unprocessedIncomingMeetingsBuyer: 0,
    unprocessedIncomingModeratedMeetingsBuyer: 0,
    unreadMessagesTotal: 0,
    company: 0,
    allSeller: 0,
    exhibitions: 0,
    allBuyer: 0,
    productsServices: 0
  }
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setData(state, action) {
      return normalizeData(action.payload);
    }
  }
});

export const { setData } = notificationsSlice.actions;

export const getNotifications = () => (dispatch) => {
  notificationsAPI
    .getNotifications()
    .then((res) => {
      dispatch(setData(res.data));
    })
    .catch(console.log);
};

export default notificationsSlice.reducer;
