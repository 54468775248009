import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { mediaUrl } from "../../../api/api";
import { Modal } from "antd";
import s from "../../FK2.1-productPage/ProductPage.module.less";
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { convertStringToLinks } from "../../../helpers/convertStringToLinks";
import YouTube from "react-youtube";

const PreviewDetailItemService = ({ item }) => {
  const { t } = useTranslation([
    "FK2.8-servicePage",
    "ServiceRegions",
    "FK3-сompanyProfile",
    "FK2.1-productPage"
  ]);

  const [mainPhoto, setMainPhoto] = useState("");
  const [photoArr, setPhotoArr] = useState([]);

  const [openSlider, setOpenSlider] = useState(false);
  const [sliderPhoto, setSliderPhoto] = useState(null);
  const [sliderArr, setSliderArr] = useState([]);

  const small = useRef();

  const handlerScrollRight = () => {
    small.current.scrollBy(130, 0);
  };
  const handlerScrollLeft = () => {
    small.current.scrollBy(-130, 0);
  };

  useEffect(() => {
    setMainPhoto(item.image);
    setSliderArr([...item.servicePhotos.map((m) => m.photo), item.image]);
    setPhotoArr(item.servicePhotos);
  }, [item]);

  useEffect(() => {
    if (item && mainPhoto && photoArr) {
      if (
        mainPhoto !== (item && item.image) &&
        !photoArr.filter((f) => f.photo === item.image).length
      ) {
        setPhotoArr([...photoArr, { photo: item.image }]);
      }
    }
  }, [item, mainPhoto]);

  const photoHandler = (num) => {
    if (num === 1) {
      if (sliderPhoto > 0) {
        setSliderPhoto(sliderPhoto - 1);
      } else {
        setSliderPhoto(sliderArr.length - 1);
      }
    } else if (num === 2) {
      if (sliderPhoto === sliderArr.length - 1) {
        setSliderPhoto(0);
      } else {
        setSliderPhoto(sliderPhoto + 1);
      }
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Modal
        className={s.modal}
        visible={openSlider}
        onOk={() => setOpenSlider(false)}
        onCancel={() => setOpenSlider(false)}
        footer={null}
        closeIcon={<CloseOutlined style={{ fontSize: "30px", color: "#fff" }} />}
      >
        <div
          style={sliderArr.length > 1 ? {} : { visibility: "hidden" }}
          className={s.prev}
          onClick={() => photoHandler(1)}
        >
          <LeftOutlined className={s.arrow} />
        </div>
        <img className={s.imgModal} src={item && `${mediaUrl}${sliderArr[sliderPhoto]}`} alt="" />
        <div
          style={sliderArr.length > 1 ? {} : { visibility: "hidden" }}
          className={s.next}
          onClick={() => photoHandler(2)}
        >
          <RightOutlined className={s.arrow} />
        </div>
      </Modal>

      <div className={s.main}>
        <div style={{ display: "flex" }}>
          <div>
            <div className={s.imgWrapper}>
              <img
                onClick={() => {
                  setOpenSlider(true);
                  setSliderPhoto(sliderArr.indexOf(mainPhoto));
                }}
                className={s.image}
                src={item && `${mediaUrl}${mainPhoto}`}
                alt=""
              />
            </div>
            <div style={{ position: "relative" }}>
              <div className={s.smallImgWrapper} ref={small}>
                {photoArr &&
                  photoArr.map((item, index) => (
                    <img
                      key={index}
                      className={s.smallImage}
                      src={item && `${mediaUrl}${item.photo}`}
                      alt="photo"
                      onClick={() => setMainPhoto(item.photo)}
                    />
                  ))}
              </div>
              {photoArr.length > 3 && (
                <div className={s.arrowLeft} onClick={handlerScrollLeft}>
                  <LeftOutlined />
                </div>
              )}
              {photoArr.length > 3 && (
                <div className={s.arrowRight} onClick={handlerScrollRight}>
                  <RightOutlined />
                </div>
              )}
            </div>
          </div>
          <div style={{ margin: "0 32px 32px 32px" }}>
            <div className={s.title}>{item && item.serviceName}</div>
            <div className={s.subTitle}>{t("certificate")}</div>
            <div className={s.subValue} style={{ textDecoration: "underline", color: "#EA5B0C" }}>
              {item && convertStringToLinks(item.availableCertificates)}
            </div>
            <div className={s.subTitle}>{t("region")}</div>
            <div className={s.subValue}>
              {item && t(item.serviceRange.range, { ns: "ServiceRegions" })}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px" }}>
          <div className={s.title}>{t("description")}</div>
          <div className={s.text}>{item && item.description}</div>
          {item?.advantage1 && item?.advantage2 && item?.advantage3 ? (
            <>
              <div className={s.title} style={{ marginTop: "32px" }}>
                {t("advantages")}
              </div>
              <ul>
                {item?.advantage1 && <li className={s.advantage}>{item && item.advantage1}</li>}
                {item?.advantage2 && <li className={s.advantage}>{item && item.advantage2}</li>}
                {item?.advantage3 && <li className={s.advantage}>{item && item.advantage3}</li>}
              </ul>
            </>
          ) : null}
        </div>
        <div style={{ marginTop: "32px", width: "500px" }}>
          {item?.video !== "" && item?.video !== null ? (
            <div style={{ marginTop: "50px" }}>
              <div className={s.title}>{t("video")}</div>
              {(() => {
                let regExp =
                  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                let match = item && item.video.match(regExp);
                let videoId = match && match[7].length == 11 ? match[7] : false;

                if (item && item.video.indexOf("youtube") > 0) {
                  return <YouTube videoId={videoId} style={{ width: "390px", height: "260px" }} />;
                } else if (item && item.video.indexOf("youtu.be") > 0) {
                  return <YouTube videoId={videoId} style={{ width: "390px", height: "260px" }} />;
                } else {
                  return (
                    item && (
                      <video
                        src={`${mediaUrl}${item.video}`}
                        title="video"
                        controls="controls"
                        style={{
                          width: "max-content",
                          height: "max-content",
                          maxHeight: "260px",
                          maxWidth: "390px"
                        }}
                      />
                    )
                  );
                }
              })()}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PreviewDetailItemService;
